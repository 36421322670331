import styled from '@emotion/styled';
import { Box, Button, TextField } from '@mui/material';

export const PhotoContainer = styled.div`
  padding: 12px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #191b18;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #191b18;
  }
`;

export const TextInput = styled(TextField)`
  border-radius: 8px;
  background: #fff;
  width: 100%;
  color: #595d62;
  font-size: 14px;
  font-weight: 400;
  ${'' /* margin-left: 13px; */}
`;

export const TextBoxButton = styled(Button)`
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 0px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #009569;
  background: #009569;
  color: #fff;
  width: 100%;
  justify-content: center;

  &:hover {
    background-color: #009569;
  }

  & .MuiButton-label {
    text-align: center;
    width: 100%;
  }
`;

export const BoxContainer = styled(Box)`
  width: 100%;
  height: 140px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #D9D9D9; 
`;

export const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  width: 100%;
  margin-top: 20px;
`;

export const PhotoItem = styled.div`
  padding: 8px;
  border-radius: 8px;
  margin: 20px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: auto;
  border: 1px solid rgba(255, 255, 255, 0.24);
  color: ${({ theme }) => theme.palette?.text?.grey5};
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
`;

export const PhotoItemWrapper = styled.div``;

export const CustomButton = styled.button`
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,      /* Fully transparent */
    rgba(0, 0, 0, 0.1) 100%   /* Dark at the right side */
  );
  color: ${({ theme }) => theme.palette?.text?.grey5};
  border:none;
  cursor: pointer;
  transition: all 0.3s ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1; 
  padding: 10px 0;
  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  
  ${({ right }) => right && `
    right: -10px; 
  `}
  
  ${({ left }) => left && `
    left: -10px;
  `}
`;

export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 50px;
`;
