import {
  Box,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import styled from '@emotion/styled';
import theme, { palette } from 'config/theme';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useDispatch, useSelector } from 'react-redux';
import { handleRedo, handleUndo, logoutUser, ProjectUpdate } from 'redux-layout/actions';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { ChildMenuItem, FlexMenuItem, ShortcutText, TextInput } from './styles';
import {
  EditTitleIcon,
  EyeIcon,
  FileIcon,
  ModalPublishIcon,
  OpenFileIcon,
  PublishIcon,
  PublishUploadIcon,
  RedoIcon,
  SavedCloudIcon,
  UndoIcon,
  UserIcon,
} from 'config/svgs';
import { Modal, Spacer } from 'components/shared';
import { createData, updateData } from 'helpers/api.services';
import appConfig from 'config';
import Open from './Component/Open';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Publish from './Component/Publish';
import CreateLesson from './Component/CreateLesson';
import RegenerateLesson from './Component/RegenerateLesson';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PublishToLifeWise from './Component/PublishToLifeWise';
import ConfirmDialog from 'components/App/ConfirmDialog';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.palette.background.bg1};
  height: 56px;
  padding: 0 16px;
`;

const LibraryBody = styled.div`
  overflow: hidden;
  height: 100vh;
  width: 100vw;
`;

const StudioLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.Auth);
  const { project, title, projectId, lessonId, isPublished, publishedId } = useSelector(
    (state) => state.Canvas.present
  );
  const { loading } = useSelector((state) => state.Studio);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fileEl, setFileEl] = useState(null);
  const [isProjectEdit, setIsProjectEdit] = useState(false);
  const [publishEl, setPublishEl] = useState(null);
  const [projectName, setProjectName] = useState('');
  const [projectFileName, setProjectFileName] = useState('');
  const [alert, setAlert] = useState({ open: false, severity: 'success', message: '' });
  const [modalState, setModalState] = useState({ open: false, type: '' });
  const [lessonEditDetailEl, setLessonEditDetailEl] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (title) {
      setProjectName(title);
      setProjectFileName(title);
    }
  }, [title]);

  const open = Boolean(anchorEl);
  const fileOpen = Boolean(fileEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFileClick = (event) => {
    event.stopPropagation();
    setFileEl(event.currentTarget);
  };

  const handleFileClose = () => {
    setFileEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logoutUser(null));
    setAnchorEl(null);
    navigate('/account/logout');
  };

  const handleProjectEdit = () => {
    setIsProjectEdit(true);
  };

  const saveProjectName = () => {
    dispatch(ProjectUpdate(projectName));
    setIsProjectEdit(false);
  };

  const saveProjectFileName = () => {
    dispatch(ProjectUpdate(projectFileName));
  };

  const handleCreateNewFile = async () => {
    navigate('/create');
    // let payload = {
    //   title: 'No template project',
    //   description: '',
    //   slides: [],
    //   template: {},
    //   project: appConfig.initailProject,
    //   username: user.user.username,
    //   theme: undefined,
    // };
    // let response = await createData('projects', payload);
    // setFileEl(null);
    // if (response?.data?.id) {
    //   window.open(`/studio/${response.data.id}`, '_blank');
    // }
  };

  const handlePublishClose = () => {
    // handlePublish();
    setPublishEl(null);
  };

  const handleModalOpen = (type) => {
    setModalState({ open: true, type });
  };

  const handleModalClose = () => {
    setFileEl(null);
    setPublishEl(null);
    setLessonEditDetailEl(null);
    setModalState({ open: false, type: '' });
  };

  const modalView = useMemo(() => {
    switch (modalState.type) {
      case 'Library':
        return <Open onFileModelClose={handleModalClose} setFileEl={setFileEl} />;
      case 'Publish':
        return <Publish onPublishModelClose={handleModalClose} />;
      case 'Edit Lesson Details':
        return <Publish onPublishModelClose={handleModalClose} />;
      case 'Create-Lesson':
        return <CreateLesson handleCreateLessonClose={handleModalClose} handleRegenerateLesson={handleModalOpen} />;
      case 'Regenerate-lesson-using-AI':
        return <RegenerateLesson handleRegenerateLessonClose={handleModalClose} />;
      case 'Publish-to-LifeWise':
        return <PublishToLifeWise onPublishModelClose={handleModalClose} />;
      default:
        return null;
    }
  }, [modalState]);

  const formatModalTitle = (type) => {
    return type.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handlePreview = () => {
    let link = `https://stplr.superteach.org/?prj=${projectId}`;
    if (isPublished) {
      link = `https://stplr.superteach.org/?prj=${projectId}&preview=${true}`;
    }
    window.open(link, '_blank');
  };
  const handleLifeWifeLessonPreview = () => {
    window.open(`https://beta.lifewise.co.uk/lessons/${lessonId}`, '_blank');
  };

  const handleEditLessonDetails = (event) => setLessonEditDetailEl(event.currentTarget);
  const handleEditLessonDetailsClose = () => setLessonEditDetailEl(null);

  const handlePublishChanges = () => {
    let payload = {
      project: project,
    };

    updateData(`/publisheds/${publishedId}`, payload)
      .then((response) => {
        setAlert({ open: true, severity: 'success', message: 'Changes published successfully' });
        setIsEdit(false);
      })
      .catch((error) => {
        setAlert({ open: true, severity: 'error', message: 'Failed to publish changes' });
        setIsEdit(false);
      });
  };

  return (
    <LibraryBody>
      <Container>
        <Box sx={{ width: '100%', display: 'flex' }}>
          <Button
            sx={{ fontWeight: '400', fontFamily: 'Filson Soft thin' }}
            variant="backBtn"
            onClick={() => navigate('/library')}>
            Home
          </Button>
          <Button
            sx={{ fontWeight: '400', fontFamily: 'Filson Soft thin' }}
            variant="backBtn"
            onClick={handleFileClick}>
            File
          </Button>
          <Menu
            id="basic-menu"
            sx={{ borderRadius: '12px' }}
            anchorEl={fileEl}
            open={fileOpen}
            onClose={handleFileClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            PaperProps={{
              sx: {
                width: '350px',
                borderRadius: '12px',
              },
            }}>
            <MenuItem>
              <Typography variant="body4" sx={{ color: palette?.text?.black }}>
                Project Name
              </Typography>
            </MenuItem>
            <MenuItem>
              <TextInput
                value={projectFileName}
                onChange={(e) => setProjectFileName(e.target.value)}
                onBlur={saveProjectFileName}
                placeholder={title}
                variant="outlined"
                fullWidth
              />
            </MenuItem>
            <Divider sx={{ backgroundColor: palette?.text?.grey4 }} />
            <FlexMenuItem onClick={handleCreateNewFile}>
              <ChildMenuItem>
                <ListItemIcon>
                  <FileIcon />
                </ListItemIcon>
                <Typography variant="body4" sx={{ color: palette?.text?.black }}>
                  New File
                </Typography>
              </ChildMenuItem>

              <ShortcutText variant="body4">⇧⌘N</ShortcutText>
            </FlexMenuItem>
            <FlexMenuItem onClick={() => handleModalOpen('Library')}>
              <ChildMenuItem>
                <ListItemIcon>
                  <OpenFileIcon />
                </ListItemIcon>
                <Typography variant="body4" sx={{ color: palette?.text?.black }}>
                  Open
                </Typography>
              </ChildMenuItem>
              <ShortcutText variant="body4">⇧⌘O</ShortcutText>
            </FlexMenuItem>

            {/* <FlexMenuItem onClick={() => handleModalOpen('Create-Lesson')}>
              <ChildMenuItem>
                <ListItemIcon>
                  <RegenerateLessonIcon />
                </ListItemIcon>
                <Typography variant="body4" sx={{ color: palette?.text?.black }}>
                  Regenerate Lesson
                </Typography>
              </ChildMenuItem>
            </FlexMenuItem> */}
            <FlexMenuItem onClick={() => handleModalOpen('Publish')}>
              <ChildMenuItem>
                <ListItemIcon>
                  <PublishIcon />
                </ListItemIcon>
                <Typography variant="body4" sx={{ color: palette?.text?.black }}>
                  Publish
                </Typography>
              </ChildMenuItem>
              {/* <ChevronRightOutlinedIcon sx={{ color: palette?.text?.black }} /> */}
            </FlexMenuItem>
            <Popover
              anchorEl={publishEl}
              open={Boolean(publishEl)}
              onClose={handlePublishClose}
              PaperProps={{
                sx: {
                  padding: '5px',
                  marginLeft: '10px',
                  borderRadius: '10px',
                },
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              disableRestoreFocus>
              <MenuItem onClick={() => handleModalOpen('Publish')}>
                <Typography variant="body3" sx={{ color: palette?.text?.black }}>
                  Publish to School
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleModalOpen('Publish')}>
                <Typography variant="body3" sx={{ color: palette?.text?.black }}>
                  Publish to LifeWise
                </Typography>
              </MenuItem>
            </Popover>
          </Menu>

          {isProjectEdit ? (
            <TextInput
              variant="outlined"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              onBlur={saveProjectName}
              placeholder={title}
              sx={{ width: '30%', border: 'none' }}
            />
          ) : (
            <Button
              sx={{ fontWeight: '400', fontFamily: 'Filson Soft thin' }}
              variant="backBtn"
              endIcon={<EditTitleIcon />}
              onClick={() => handleProjectEdit()}>
              {title}
            </Button>
          )}
          <Spacer x={1} y={1} />
          <Button sx={{ minWidth: '32px', padding: '4px' }} variant="backBtn" onClick={() => dispatch(handleUndo())}>
            <UndoIcon />
          </Button>
          <Button sx={{ minWidth: '32px', padding: '4px' }} variant="backBtn" onClick={() => dispatch(handleRedo())}>
            <RedoIcon />
          </Button>
          <Spacer x={1} y={1} />
          <Button
            sx={{ fontWeight: '400', fontFamily: 'Filson Soft thin' }}
            variant="backBtn"
            startIcon={<SavedCloudIcon />}>
            {loading ? 'Saving...' : 'Saved'}
          </Button>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="end" gap="1rem" sx={{ width: '100%' }}>
          {lessonId && (
            <Button variant="preview" startIcon={<EyeIcon />} onClick={handleLifeWifeLessonPreview}>
              View LifeWise Lesson
            </Button>
          )}

          <Button variant="preview" startIcon={<EyeIcon />} onClick={handlePreview}>
            Preview
          </Button>
          {lessonId ? (
            <>
              <Button
                variant="preview"
                startIcon={<PublishUploadIcon />}
                onClick={() => setIsEdit(true)}
                endIcon={
                  <Stack direction="row" onMouseEnter={handleEditLessonDetails}>
                    <Divider orientation="vertical" flexItem sx={{ mx: 1, height: '24px', background: '#E2E2E2' }} />
                    <KeyboardArrowDownIcon />
                  </Stack>
                }>
                <Box display="flex" alignItems="center">
                  Publish Changes
                </Box>
              </Button>
              <Popover
                anchorEl={lessonEditDetailEl}
                open={Boolean(lessonEditDetailEl)}
                onClose={handleEditLessonDetailsClose}
                PaperProps={{
                  sx: {
                    // padding: '5px',
                    // marginLeft: '10px',
                    borderRadius: '10px',
                    marginTop: '40px',
                    background: '#009569',
                  },
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                disableRestoreFocus>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ height: '50px' }}
                  startIcon={<ModalPublishIcon />}
                  onClick={() => handleModalOpen('Edit Lesson Details')}>
                  Edit Lesson Details
                </Button>
              </Popover>
            </>
          ) : (
            <Button variant="preview" startIcon={<FileUploadOutlinedIcon />} onClick={() => handleModalOpen('Publish')}>
              <Box display="flex" alignItems="center">
                Publish
              </Box>
            </Button>
          )}

          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}>
            <UserIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                {/* <UserIcon stroke={'rgba(0, 0, 0, 0.6)'} /> */}
                <PersonIcon sx={{ color: 'background.paper' }} />
              </ListItemIcon>
              <ListItemText>profile</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Container>
      <Outlet />
      <Snackbar
        open={alert.open}
        autoHideDuration={3000}
        onClose={() => setAlert({ ...alert, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
      <Modal open={modalState.open} onClose={handleModalClose} title={formatModalTitle(modalState.type)} size="md">
        {modalView}
      </Modal>
      <ConfirmDialog
        open={isEdit}
        onClose={() => setIsEdit(false)}
        onConfirm={handlePublishChanges}
        title={'Edit'}
        buttonTitle={'Confirm'}
        showCancelButton={true}
        showConfirmButton={true}>
        <Typography variant="body2">
          Are you sure you want to make changes to the lesson LifeWise? You will not be able to revert to the original
          lesson.
        </Typography>
      </ConfirmDialog>
    </LibraryBody>
  );
};

export default StudioLayout;
