import { Button, Stack, Typography } from '@mui/material';
import { NotFoundIcon } from 'config/svgs';
import { palette } from 'config/theme';
import React from 'react';

const NotFound = ({ title, clearSearch }) => {
  return (
    <Stack direction="column" alignItems="center" justifyContent="center" mt={3} sx={{width: '100%'}}>
      <NotFoundIcon />
      <Typography variant="body4" sx={{ color: palette?.text.white, textAlign: 'center', mt: 1 }}>
       {`No results found for "${title}"`}
      </Typography>
      <Button
        color="primary"
        sx={{
          fontSize: '14px',
          fontWeight: 425,
          color: palette?.text.white,
          textDecoration: 'underline',
          mt: 1,
          height: '10px',
        }}
        onClick={clearSearch}
        >
        Clear search
      </Button>
    </Stack>
  );
};

export default NotFound;
