import React from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { BoxContainer, ErrorMessage, ScrollContainer } from './styles';
import { baseUrl } from 'helpers/apiCalls';
import { Button, IconButton, Stack } from '@mui/material';
import Loader from 'pages/studio/Components/Loader';
import appConfig from 'config';
import { handleAddElement } from 'redux-layout/actions';
import { useDispatch } from 'react-redux';
import { newID } from 'helpers/studioApi';
import NotFound from '../NotFound';

const GIFElement = ({
  data,
  elementLoadMore,
  elementLoading,
  elementError,
  elementCount,
  dataFound,
  buttonSearch,
  clearSearch,
}) => {
  const dispatch = useDispatch();

  const handleGIFsAdd = (item) => {
    const { width, height, url: imageUrl } = item || {};
    const defaultImg = { ...appConfig.elemImg };
    const element = {
      ...defaultImg,
      id: newID('element'),
      content: {
        src: `${baseUrl}${imageUrl}`,
      },
      position: {
        ...defaultImg.position,
        height: (height / width) * 20,
        width: 20,
      },
    };
    dispatch(handleAddElement(element));
  };
  return (
    <ScrollContainer>
      <Grid container spacing={2} sx={{ p: 2 }}>
        {elementError ? (
          <Grid item xs={12}>
            <ErrorMessage>Failed to load elements. Please try again.</ErrorMessage>
          </Grid>
        ) : (
          <React.Fragment>
            {dataFound?.length ? (
              <Grid container spacing={2}>
                {data.map((item, index) => {
                  return (
                    <Grid item key={index} xs={6}>
                      <BoxContainer>
                        <IconButton onClick={() => handleGIFsAdd(item)}>
                          <img className="img-contain" src={`${baseUrl}${item?.url}`} alt={`${baseUrl}${item?.url}`} />
                        </IconButton>
                      </BoxContainer>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <>{buttonSearch && <NotFound title={buttonSearch} clearSearch={clearSearch} />}</>
            )}
            {elementLoading ? (
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 1, width: '100%' }}>
                <Loader />
              </Stack>
            ) : (
              <>
                {data?.length === elementCount ? null : (
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ mt: 1, width: '100%' }}>
                    <Button variant="backBtn" onClick={elementLoadMore}>
                      {'Load More'}
                    </Button>
                  </Stack>
                )}
              </>
            )}
          </React.Fragment>
        )}
      </Grid>
    </ScrollContainer>
  );
};

export default GIFElement;
