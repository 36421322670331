import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { MultilineEdit } from '../Editor/MultiLineEdit';
import { ELEMENT_Type } from 'config/constants';
import { FRAME } from 'pages/studio/Canvas/config';
import images from 'config/images';
import Image from './Image';

const { DIV, IMG, SVG, TEXT, INDIV, ALL, POS, RND } = ELEMENT_Type;

const SlidePreview = ({ slide = {}, handleSubmit = () => { }, isSlideShow }) => {
  const rootRef = useRef(null);
  const [width, setWidth] = useState();

  useEffect(() => {
    if (rootRef?.current) {
      setWidth(rootRef?.current?.clientWidth);
    }
  }, [slide]);

  const getRNDPOS = (el, rootRef) => {
    // console.log('postionLeft', el, el.type, el.position?.left, el.position?.top);
    if (rootRef?.current?.clientWidth) {
      let width = rootRef.current.clientWidth;
      let height = rootRef.current.clientHeight;
      let x = (el.position?.left * width || 10) / 100;
      let y = (el.position?.top * height || 10) / 100;
      return { x: x, y: y };
    } else {
      return { x: 0, y: 0 };
    }
  };

  const getElemStyle = (elem, index, type = 'all', isRotate = true) => {
    const el = _.cloneDeep(elem);
    if (el.style['transformSkew'] === undefined) {
      el.style['transformSkew'] = '';
    }
    if (!el.style['transformRotate']) {
      el.style['transformRotate'] = '';
    }
    let style = getRecaliberatedStyleObject({ el, index, width: rootRef?.current?.clientWidth || 0 });
    if (el.type === SVG) {
      style = {
        ...style,
        stroke: el.style['stroke'] ? el.style['stroke'] : '#000',
        fill: el.style['fill'] ? el.style['fill'] : '#000',
        strokeWidth: el.style['strokeWidth'] ? el.style['strokeWidth'] : 0.1,
      };
    }
    if (el.type === TEXT) {
      style = {
        ...style,
        padding: '4px 8px',
        overflowWrap: 'break-word',
        overflow: 'hidden',
      };
    }

    return getStyleAccordingToType({ el, index, style, type });
  };

  const createElement = (el, index) => {

    const styleProps = {
      position: 'absolute',
      transform: `translate(${getRNDPOS(el, rootRef).x}px, ${getRNDPOS(el, rootRef).y}px)`,
      width: (parseFloat(el.position?.width) || 10) + '%',
      height: (parseFloat(el.position?.height) || 10) + '%',
      pointerEvents: 'none',
    };
    switch (el.type) {
      case TEXT:
        return (
          <div style={{ ...styleProps }} key={index}>
            {/* {slidePreview ? (
                            <div style={{ ...getElemStyle(el, index, 'indiv', false), pointerEvents: 'none', resize: 'none' }}>
                                <MultilineEdit
                                    rows={1}
                                    displayToolbar={false}
                                    placeholder={el?.content?.placeholder || ''}
                                    value={el?.content?.text || ''}
                                    key={JSON.stringify(el.style)} // Force re-render when style changes
                                />
                            </div>
                        ) : (
                            
                        )} */}

            <div
              style={{ ...getElemStyle(el, index, 'indiv', false), pointerEvents: 'none', height: '100%' }}
            >
              <div dangerouslySetInnerHTML={{ __html: el.content.text || el.content.placeholder || '' }}></div>
            </div>
          </div>
        );
      case SVG:
        return (
          <div style={{ ...styleProps }} key={index}>
            <svg
              id={el.id}
              onClick={() => dispatch(elementChanged(el))}
              style={{ ...getElemStyle(el, index, 'indiv', false), pointerEvents: 'none' }}
              {...el['attributes']}>
              {Array.isArray(el.childrens) && el.childrens.map((item, index) => {
                const fillRule = item['fill-rule'];
                delete item['fill-rule'];
                return <path key={index} fillRule={fillRule} {...item}></path>;
              })}
            </svg>
          </div>

        );
      case FRAME:
        return (
          <div style={{ ...styleProps }} key={index}>
            <svg
              id={el.id}
              onClick={() => dispatch(elementChanged(el))}
              style={{ ...getElemStyle(el, index, 'indiv', false), pointerEvents: 'none', fill: `url(#frame${el.id})` }}
              {...el['attributes']}>
              {el.childrens.map((item, index) => {
                const fillRule = item['fill-rule'];
                delete item['fill-rule'];
                return <path key={index} fillRule={fillRule} {...item} fill={`url(#frame${el.id})`} stroke={el?.content?.src ? 'none' : '#000'}></path>;
              })}
              {el?.content?.src && (
                <defs>
                  <pattern id={`frame${el.id}`} patternUnits="userSpaceOnUse" width={getElemStyle(el, index, 'indiv', false).width} height={getElemStyle(el, index, 'indiv', false).height}>
                    <image style={{ objectFit: 'cover' }} href={el.content.src} x={styleProps.x} y={styleProps.y} width={getElemStyle(el, index, 'indiv', false).width} height={getElemStyle(el, index, 'indiv', false).height} />
                  </pattern>
                </defs>
              )}
            </svg>
          </div >
        );
      case IMG:
        const imgSrc = el?.aiKey && el?.aiKey === 'image' ? images.studio.ai_image_loader.image : el?.content?.src || el?.content?.placeholder || '';
        return (
          <div style={{ ...styleProps }} key={index}>
            <Image el={el} isSlideShow={isSlideShow} getElemStyle={getElemStyle} index={index} slideIndex={slide?.id} />
          </div>
        );

      case DIV:
        //this is for embeds iframe
        const getStyles = {
          ...el.attributes,
          style: { border: 0 }
        };
        return (
          <div style={{ ...styleProps }} className="iframe-wrapper" id={el.id} key={index}>
            <iframe
              title="iframe"
              {...getStyles}
            />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div
      ref={rootRef}
      style={{
        display: 'flex',
        aspectRatio: '16/9',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        borderRadius: 6,
        backgroundColor: slide?.style?.['background-color'] || '#fff',
        backgroundImage: slide?.style?.['background-image'],
        backgroundSize: 'cover',
        cursor: 'pointer',
        objectFit: 'cover',
        position: 'relative'
      }}
      className="slide-preview"
      onClick={() => {
        handleSubmit(slide);
      }}
    >
      {slide?.elements?.length > 0 && slide.elements.filter((item) => !!item).map(createElement)}
    </div>
  );
};

export default SlidePreview;

function calcFontSize(el, width) {
  let size = 0;
  let constant = 600;
  if (el.textstyle) {
    size = el.textstyle['font-size'];
  } else {
    size = el.style['font-size'];
  }

  if (!size) {
    return false;
  }

  let fs;
  let sum;
  let con;
  sum = width;
  let per = (sum / constant) * 100;
  fs = (per / 100) * size;
  if (per < 100) {
    fs -= parseInt(100 - per + 1) * 0.015;
  }
  return fs;
}


function getRecaliberatedStyleObject({ el, index, width }) {
  return {
    position: 'absolute',
    opacity: el.style['opacity'] ? el.style['opacity'] + '0' : '0.1',
    pointerEvents: el.style['pointer-events'] ? el.style['pointer-events'] : 'stroke',
    backgroundColor: el.style['backgroundColor'] ? el.style['backgroundColor'] : 'rgba(255,255,255,0)',
    borderWidth: el.style['border-width'] ? el.style['border-width'] + 'px' : '0',
    borderStyle: el.style['border-style'] ? el.style['border-style'] : 'solid',
    display: el.style['display'] ? el.style['display'] : 'block',
    borderColor: el.style['border-color'] ? el.style['border-color'] : '#fff',
    borderRadius: el.style['border-radius'] ? el.style['border-radius'] + 'px' : '0',
    color: el.style['color'] ? el.style['color'] : '#000',
    fontFamily: el.style['font-family'] ? el.style['font-family'] : '',
    fontSize: el.style['font-size'] ? calcFontSize(el, width) + 'px' : '55px',
    fontWeight: el.style['font-weight'] ? el.style['font-weight'] : '',
    textAlign: el.style['text-align'] ? el.style['text-align'] : 'center',
    zIndex: el.style['z-index'] ? el.style['z-index'] : index,
    fontStyle: el.style['font-style'] ? el.style['font-style'] : 'normal',
    textDecoration: el.style['text-decoration'] ? el.style['text-decoration'] : '',
    transform: el.style['transformRotate'] + ' ' + el.style['transformSkew'],
    textTransform: el.style['text-transform'] ? el.style['text-transform'] : '',
    verticalAlign: el.style['vertical1'] ? el.style['vertical1'] : '',
    lineHeight: el.style['line-height'] ? el.style['line-height'] : '1.5',
  };
}

function getStyleAccordingToType({ el, index, style, type = ALL }) {
  switch (type) {
    case ALL:
      return {
        ...style,
        top: el.position.top + '%',
        left: el.position.left + '%',
        width: el.position.width + '%',
        height: el.position.height + '%',
      };
    case INDIV:
      return {
        ...style,
        top: '0%',
        left: '0%',
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
      };

    case POS:
      return {
        ...styleRnd,
        transform: el.style['transformRotate'] + el.style['transformSkew'],
        zIndex: el.style['z-index'] ? el.style['z-index'] : index,
      };
    case RND:
      return {
        ...styleRnd,
        transform: el.style['transformRotate'] + el.style['transformSkew'],
        zIndex: el.style['z-index'] ? el.style['z-index'] : index,
        x: el.position.x,
        y: el.position.y,
      };
    case TEXT:
      return {
        ...style,
        pointerEvents: null,
      };
  }
}

