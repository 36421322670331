import React, { useContext } from 'react';
import { BoxContainer, PhotoContainer } from './style';
import { Button, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { ErrorMessage } from 'formik';
import { baseUrl } from 'helpers/apiCalls';
import Loader from 'pages/studio/Components/Loader';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import LeftNavHeader from 'pages/studio/Components/LeftNavHeader';
import { useDispatch, useSelector } from 'react-redux';
import { newID } from 'helpers/studioApi';
import appConfig from 'config';
import { elementUpdated, handleAddElement } from 'redux-layout/actions';
import { StudioContext } from 'pages/studio';

const AllUploadsAssets = ({ elementType, setElementType, email }) => {
  const dispatch = useDispatch();
  const { imageType, setImageType } = useContext(StudioContext);
  const { selectedElement } = useSelector((state) => state.Canvas.present);

  const elementConfigs = {
    YOURUPLOADS: { api: 'assets', params: {} },
  };
  const { api, params: apiParams } = elementConfigs[elementType] || { api: '', params: {} };
  const { data, loading, error, loadMore, searchItems, count } = usePaginatedApi(api, 16, {
    ...apiParams,
    username: { $eq: email },
  });
  const handleBack = () => {
    setElementType('PHOTOS');
  };

  const handleUploadImgAdd = (item) => {
    const { width, height, url: imageUrl } = item?.attributes?.asset?.data[0]?.attributes || {};
    if (imageType === 'frame' && selectedElement?.type === 'frame') {
      setImageType('');
      setElementType('');
      dispatch(elementUpdated(selectedElement, `${baseUrl}${imageUrl}`, 'zindex-src'));
    } else if (imageType === 'replace_image' && selectedElement?.type === 'img') {
      setImageType('');
      setElementType('');
      dispatch(elementUpdated(selectedElement, `${baseUrl}${imageUrl}`, 'replace-image'));
    } else {
      const defaultImg = { ...appConfig.elemImg };
      const element = {
        ...defaultImg,
        id: newID('element'),
        content: {
          src: `${baseUrl}${imageUrl}`,
        },
        position: {
          ...defaultImg.position,
          height: (height / width) * 20,
          width: 20,
        },
      };
      dispatch(handleAddElement(element));
    }
  };
  return (
    <PhotoContainer>
      <LeftNavHeader elementType={elementType} setElementType={setElementType} handleBack={handleBack} />
      <Grid container spacing={2} sx={{ p: 2 }}>
        {error ? (
          <Grid item xs={12}>
            <ErrorMessage>Failed to load elements. Please try again.</ErrorMessage>
          </Grid>
        ) : (
          <React.Fragment>
            <Grid container spacing={2}>
              {data.map((item, index) => {
                return (
                  <Grid item key={index} xs={6}>
                    <BoxContainer>
                      <IconButton onClick={() => handleUploadImgAdd(item)}>
                        <img
                          className="img-contain"
                          src={`${baseUrl}${item?.attributes?.asset?.data[0]?.attributes?.url}`}
                          alt={`${baseUrl}${item?.attributes?.asset?.data[0]?.attributes?.url}`}
                        />
                      </IconButton>
                    </BoxContainer>
                  </Grid>
                );
              })}
            </Grid>
            {loading ? (
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 1, width: '100%' }}>
                <Loader />
              </Stack>
            ) : (
              <>
                {data?.length === count ? null : (
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ mt: 1, width: '100%' }}>
                    <Button variant="backBtn" onClick={loadMore}>
                      {'Load More'}
                    </Button>
                  </Stack>
                )}
              </>
            )}
          </React.Fragment>
        )}
      </Grid>
    </PhotoContainer>
  );
};

export default AllUploadsAssets;
