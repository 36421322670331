import axios from 'axios';
import appConfig from 'config';
import { cookieUtils } from './utils';

const http = axios.create({
    baseURL: appConfig.collection,
    timeout: 60 * 1000,
});

http.interceptors.request.use(
    (config) => {
        const user = cookieUtils.get('user');
        const userObj = user ? (typeof user === 'object' ? user : JSON.parse(user)) : null;
        if (userObj?.jwt) {
            config.headers['Authorization'] = 'Bearer ' + userObj.jwt;

        }
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    (error) => Promise.reject(error)
);

http.interceptors.response.use(
    async (response) => {
        return response.data;
    },
    async (errorObj) => {
        const errorData = errorObj.response && errorObj.response.data;
        console.log('errorData', errorData);
        try {
            let error = errorData;
            if (error?.error) {
                console.debug(error?.error);
                // message.error(error.message);
            }
            if (error?.errors) {
                error = error?.errors[0];
                console.debug('Multiple errors:');
                error?.error.forEach((err) => console.debug(err));
                // message.error(error.message);
            }
        } catch (error) {
            console.debug('error in http service', error);
        }
        // message.error(error.message);
        return Promise.reject(error);
    }
);

export default http;
