import styled from '@emotion/styled';

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 426px;
  background-color: white;
  box-shadow: 24;
  border-radius: 16px;
  `;

export const Img = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 16px 16px 0 0;
    `;

export const TextBox = styled.div`
    padding: 24px;
    text-align: center;
    `;
