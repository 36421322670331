import React, { useState } from 'react';
import { ManualContainer } from './styles';
import { Box, Button, Grid, Stack, Typography, CircularProgress } from '@mui/material';
import { Spacer } from 'components/shared';
import { template } from './config';
import TemplateCard from './Card';
import { createData } from 'helpers/api.services';
import { useSelector } from 'react-redux';
import appConfig from 'config';
import { useNavigate } from 'react-router-dom';
import usePaginatedApi from 'components/hooks/usePaginationApi';

const CreateManual = () => {
    const navigate = useNavigate();
    const [selected, setSelected] = useState(template[0]);
    const { user } = useSelector(state => state.Auth);
    const { data, count, loading, error, loadMore, searchItems, refreshData } = usePaginatedApi('manual-templates', 15, {
        type: 'manual'
    }, 'manual');

    const handleCreateManual = async () => {
        let payload = {
            title: selected.name,
            description: '',
            slides: [],
            template: {},
            project: {
                ...appConfig.initailProject,
                slides: selected.slides,
            },
            username: user.user.username,
            theme: undefined,
            lwuser: user.user.lwId,
            lwSchoolId: user.user.lwSchoolId,
            isLifewise: false
        };
        let response = await createData('projects', payload);
        navigate(`/studio/${response?.data?.id}`);
    };

    return (
        <>
            <ManualContainer>
                <Typography variant="body1">Templates</Typography>
                <Spacer x={1} y={1} />
                <Grid container spacing={2}>
                    {template.map((item, index) => (
                        <TemplateCard key={index} item={item} index={index} selected={selected} setSelected={setSelected} />
                    ))}
                    {data.map((item, index) => (
                        <TemplateCard key={index} item={{ ...item.attributes, id: item.id }} index={index} selected={selected} setSelected={setSelected} />
                    ))}
                    {loading ? (
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <CircularProgress color="secondary" />
                        </Box>
                    ) : null}
                </Grid>
            </ManualContainer>
            <Stack direction="row" sx={{ position: 'absolute', right: 0, bottom: 0 }} spacing={2} padding={'1rem'}>
                <Button variant="text">Cancel</Button>
                <Button variant="contained" color="secondary" onClick={handleCreateManual}>
                    Create
                </Button>
            </Stack>
        </>
    );
};

export default CreateManual;
