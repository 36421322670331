import React, { useState, useMemo } from 'react';
import { CircularProgress, Grid, InputAdornment, Typography } from '@mui/material';
import { CustomButton, StyledIconButton, StyledTextField } from './styles';
import { FriendlyIcon, ImproveIcon, SendIcon, ShortenIcon, SimplifyIcon } from 'config/svgs';
import { palette } from 'config/theme';

const ButtonGroup = React.memo(
  ({
    emit,
    text,
    loading,
    setLoading,
    customLoading,
    setCustomLoading,
    selectedButton,
    setSelectedButton,
    customizeText,
    setCustomizeText,
    setCustomizedButtonText,
  }) => {
    const [loadingIndex, setLoadingIndex] = useState(null);

    const buttonsData = useMemo(
      () => [
        { name: 'Improve it', icon: <ImproveIcon /> },
        { name: 'Simplify it', icon: <SimplifyIcon /> },
        { name: 'Shorten it', icon: <ShortenIcon /> },
        { name: 'Friendly', icon: <FriendlyIcon /> },
      ],
      []
    );

    const handleTextGenerate = (button, index) => {
      setSelectedButton(button?.name.toLowerCase());
      setLoading(true);
      setLoadingIndex(index);

      try {
        emit(
          'pipeline:execute',
          JSON.stringify({
            slug: 'improve-text',
            payload: {
              purpose: button?.name.toLowerCase(),
              text: text,
            },
          })
        );
      } catch (e) {
        setLoading(false);
        setLoadingIndex(null);
        console.log('regeneration text ERROR::', e);
      }
    };

    const handleCustomizeText = () => {
      setCustomizedButtonText('customize it');
      setCustomLoading(true);
      try {
        emit(
          'pipeline:execute',
          JSON.stringify({
            slug: 'improve-text',
            payload: {
              purpose: selectedButton,
              text: text,
              instructions: customizeText,
            },
          })
        );
      } catch (e) {
        setCustomLoading(false);
        setLoadingIndex(null);
        console.log('regeneration text ERROR::', e);
      }
    };

    return (
      <>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Typography variant="body2" sx={{ color: palette.text.grey3, mt: '10px', ml: 2 }}>
            What changes would you like to make?
          </Typography>
          {buttonsData &&
            buttonsData?.map((button, index) => (
              <Grid item xs={6} key={index}>
                <CustomButton startIcon={button.icon} onClick={() => handleTextGenerate(button, index)}>
                  {loading && loadingIndex === index ? 'Loading...' : button.name}
                </CustomButton>
              </Grid>
            ))}
        </Grid>
        <StyledTextField
          value={customizeText}
          onChange={(e) => setCustomizeText(e.target.value)}
          multiline
          placeholder="example: make it funnier"
          variant="outlined"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <StyledIconButton onClick={handleCustomizeText}>
                  {customLoading ? <CircularProgress color="inherit" size={20} /> : <SendIcon />}
                </StyledIconButton>
              </InputAdornment>
            ),
          }}
        />
      </>
    );
  }
);
ButtonGroup.displayName = 'ButtonGroup';
export default ButtonGroup;
