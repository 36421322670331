import React, { useContext } from 'react';
import { BoxContainer } from './style';
import { Box, Button, CircularProgress, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { palette } from 'config/theme';
import { ErrorMessage } from 'formik';
import { baseUrl } from 'helpers/apiCalls';
import Loader from 'pages/studio/Components/Loader';
import appConfig from 'config';
import { elementUpdated, handleAddElement } from 'redux-layout/actions';
import { useDispatch, useSelector } from 'react-redux';
import { newID } from 'helpers/studioApi';
import { StudioContext } from 'pages/studio';

const LifewiseLibrary = ({
  noTitle = false,
  title,
  subTitle,
  PhotosData,
  elementLoading,
  elementError,
  elementLoadMore,
  elementCount,
}) => {
  const dispatch = useDispatch();
  const { selectedElement } = useSelector((state) => state.Canvas.present);
  const { imageType, setImageType, setElementType } = useContext(StudioContext);

  const handleLifeWiseImgAdd = (item) => {
    const { width, height, url: imageUrl } = item || {};
    if (imageType === 'frame' && selectedElement?.type === 'frame') {
      setImageType('');
      setElementType('');
      dispatch(elementUpdated(selectedElement, `${baseUrl}${imageUrl}`, 'zindex-src'));
    } else if (imageType === 'replace_image' && selectedElement?.type === 'img') {
      setImageType('');
      setElementType('');
      dispatch(elementUpdated(selectedElement, `${baseUrl}${imageUrl}`, 'replace-image'));
    } else {
      const defaultImg = { ...appConfig.elemImg };
      const element = {
        ...defaultImg,
        id: newID('element'),
        content: {
          src: `${baseUrl}${imageUrl}`,
        },
        position: {
          ...defaultImg.position,
          height: (height / width) * 20,
          width: 20,
        },
      };
      dispatch(handleAddElement(element));
    }
  };
  return (
    <React.Fragment>
      {noTitle ? null : (
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
          <Typography variant="body2" sx={{ color: palette?.background?.default, textAlign: 'left' }}>
            {title}
          </Typography>
          {subTitle ? <Button variant="seeAll">{subTitle}</Button> : null}
        </Stack>
      )}
      <Grid container spacing={2} sx={{ p: 2 }}>
        {elementError ? (
          <Grid item xs={12}>
            <ErrorMessage>Failed to load elements. Please try again.</ErrorMessage>
          </Grid>
        ) : (
          <React.Fragment>
            <Grid container spacing={2}>
              {PhotosData.map((item, index) => {
                return (
                  <Grid item key={index} xs={6}>
                    <BoxContainer>
                      <IconButton onClick={() => handleLifeWiseImgAdd(item)}>
                        <img className="img-contain" src={`${baseUrl}${item?.url}`} alt={`${baseUrl}${item?.url}`} />
                      </IconButton>
                    </BoxContainer>
                  </Grid>
                );
              })}
            </Grid>
            {elementLoading ? (
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 1, width: '100%' }}>
                <Loader />
              </Stack>
            ) : (
              <>
                {elementCount === 0 ? (
                  <Box>
                    <Typography variant='body2' color={palette.text.white}>
                      No elements found
                    </Typography>
                  </Box>
                ) : PhotosData?.length === elementCount ? null : (
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ mt: 1, width: '100%' }}>
                    <Button variant="backBtn" onClick={elementLoadMore}>
                      {'Load More'}
                    </Button>
                  </Stack>
                )}
              </>
            )}
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default LifewiseLibrary;
