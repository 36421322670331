import React from 'react';
import { Stack, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { palette } from 'config/theme';

const Header = ({ handleClose }) => {
  return (
    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
      <Typography variant="semi_large" sx={{ color: palette?.background?.dark }}>
        Rewrite with AI
      </Typography>
      <IconButton onClick={handleClose}>
        <CloseIcon sx={{ color: palette?.background?.dark, fontSize: '24px' }} />
      </IconButton>
    </Stack>
  );
};

export default Header;
