import { Box, MenuItem } from '@mui/material';
import { FlipHorizontalIcon, FlipVerticalIcon } from 'config/svgs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { elementUpdated } from 'redux-layout/actions';

const FlipItem = ({ handleClose = () => { } }) => {
    const dispatch = useDispatch();
    const { selectedElement } = useSelector((state) => state.Canvas.present);

    const handleFlip = (e) => {
        let element = selectedElement;
        let type = e.target.getAttribute('data-target');
        console.log('FlipItem -> handleFlip -> e', type);
        if (type === 'horizontal') {
            if (element.style.transformSkew === 'scaleX(-1)') {
                dispatch(elementUpdated(element, '', 'transformSkew'));
            } else if (element.style.transformSkew === 'scaleY(-1)') {
                dispatch(elementUpdated(element, 'scale(-1)', 'transformSkew'));
            } else if (element.style.transformSkew === 'scale(-1)') {
                dispatch(elementUpdated(element, 'scaleY(-1)', 'transformSkew'));
            } else {
                dispatch(elementUpdated(element, 'scaleX(-1)', 'transformSkew'));
            }
        } else if (type === 'vertical') {
            if (element.style.transformSkew === 'scaleY(-1)') {
                dispatch(elementUpdated(element, '', 'transformSkew'));
            } else if (element.style.transformSkew === 'scaleX(-1)') {
                dispatch(elementUpdated(element, 'scale(-1)', 'transformSkew'));
            } else if (element.style.transformSkew === 'scale(-1)') {
                dispatch(elementUpdated(element, 'scaleX(-1)', 'transformSkew'));
            } else {
                dispatch(elementUpdated(element, 'scaleY(-1)', 'transformSkew'));
            }
        }
        handleClose();
    };

    return (
        <Box sx={{ width: '208px' }}>
            <MenuItem data-target="horizontal" onClick={handleFlip}>
                <FlipHorizontalIcon />
                Flip horizontal
            </MenuItem>
            <MenuItem data-target="vertical" onClick={handleFlip}>
                <FlipVerticalIcon />
                Flip vertical
            </MenuItem>
        </Box>
    );
};

export default FlipItem;
