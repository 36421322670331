import React, { useEffect, forwardRef, useContext, useRef, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { StudioContext } from 'pages/studio';
import { useDispatch, useSelector } from 'react-redux';
import { elementUpdated } from 'redux-layout/actions';

const SimpleWysiwyg = forwardRef(({ value: propValue, updateElement, placeholder, elementObj, lcanvasRef, ...props }, ref) => {
  const { formatting, setFormatting } = useContext(StudioContext);
  const previousFormatting = useRef({
    bold: false,
    italic: false,
    underline: false,
    list: ''
  });
  const { selectedElement } = useSelector((state) => state.Canvas.present);
  const dispatch = useDispatch();

  const getAncestor = useCallback((node, tag) => {
    while (node) {
      if (node.tagName && node.tagName.toLowerCase() === tag) {
        return node;
      }
      node = node.parentNode;
    }
    return null;
  }, []);

  const removeList = (oppositeListAncestor, tag) => {
    const newList = document.createElement(tag);
    while (oppositeListAncestor.firstChild) {
      const listItem = oppositeListAncestor.firstChild;
      const newListItem = document.createElement('li');
      while (listItem.firstChild) {
        newListItem.appendChild(listItem.firstChild);
      }
      newList.appendChild(newListItem);
      oppositeListAncestor.removeChild(listItem);
    }
    oppositeListAncestor.replaceWith(newList);
  };

  const createList = (range, tag) => {
    const currentList = getAncestor(range.startContainer, tag);
    if (currentList) {
      const fragment = document.createDocumentFragment();
      while (currentList.firstChild) {
        const listItem = currentList.firstChild;
        while (listItem.firstChild) {
          fragment.appendChild(listItem.firstChild);
        }
        currentList.removeChild(listItem);
      }
      currentList.replaceWith(fragment);
    } else {
      const list = document.createElement(tag);
      const listItem = document.createElement('li');
      const clonedRange = range.cloneRange();
      const extractedContent = clonedRange.extractContents();
      listItem.appendChild(extractedContent);
      list.appendChild(listItem);
      range.deleteContents();
      range.insertNode(list);
    }
  };

  const handleListFormatting = useCallback((range, tag) => {
    const oppositeTag = tag === 'ol' ? 'ul' : 'ol';
    const oppositeListAncestor = getAncestor(range.startContainer, oppositeTag);

    if (oppositeListAncestor) {
      removeList(oppositeListAncestor, tag);
    } else {
      createList(range, tag);
    }
  }, [getAncestor]);

  const checkFormattingAtCursor = useCallback(() => {
    const selection = window.getSelection();
    if (!selection.rangeCount) { return {}; }
    const range = selection.getRangeAt(0);
    let node = range.startContainer;

    if (node.nodeType === Node.TEXT_NODE) {
      node = node.parentNode;
    }

    const bold = isTagPresent(node, 'b') || isTagPresent(node, 'strong');
    const italic = isTagPresent(node, 'i') || isTagPresent(node, 'em');
    const underline = isTagPresent(node, 'u');
    return { bold, italic, underline };
  }, []);

  const isTagPresent = useCallback((node, tag) => {
    while (node) {
      if (node.tagName && node.tagName.toLowerCase() === tag) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }, []);

  const removeTag = useCallback((node, tag) => {
    const tagToRemove = tag.toLowerCase();
    if (node.nodeType === Node.TEXT_NODE) {
      return node;
    }
    if (node.tagName && node.tagName.toLowerCase() === tagToRemove) {
      const fragment = document.createDocumentFragment();
      while (node.firstChild) {
        fragment.appendChild(node.firstChild);
      }
      return fragment;
    } else {
      const clone = node.cloneNode(false);
      while (node.firstChild) {
        clone.appendChild(removeTag(node.firstChild, tag));
      }
      return clone;
    }
  }, []);

  const applyInlineStyle = (range, tag) => {
    const span = document.createElement(tag);
    span.appendChild(range.extractContents());
    range.insertNode(span);
  };

  const removeInlineStyle = (range, tag) => {
    const ancestor = getAncestor(range.startContainer, tag);
    if (ancestor) {
      const fragment = removeTag(ancestor, tag);
      ancestor.replaceWith(fragment);
    }
  };


  function removeTagForAll(range, tag) {
    const selectedText = document.querySelector('.selected-rsw-ce');
    const fragment = range.cloneContents();  // Clone the contents
    const span = document.createElement(tag);
    span.appendChild(fragment);  // Put the fragment in a temporary div


    const tagRegex = tag === 'b' ? /<\/?b>/g : tag === 'i' ? /<\/?i>/g : /<\/?u>/g;

    // Remove <b> tags by replacing them with their child content
    span.innerHTML = span.innerHTML.replace(tagRegex, '');

    // Replace the original div content with non-bolded text
    selectedText.innerHTML = span.innerHTML;
  }

  // Helper function to add <b> tags to the entire content
  function addTagForAll(range, tag) {
    const selectedText = document.querySelector('.selected-rsw-ce');
    const fragment = range.cloneContents();  // Clone the contents
    const span = document.createElement(tag);
    span.appendChild(fragment);  // Put the fragment in a temporary div
    // Wrap the whole content in <b> tags
    selectedText.innerHTML = `<${tag}>${span.innerHTML}</${tag}>`;
  }

  function removeListForAll(range, tag) {
    const selectedText = document.querySelector('.selected-rsw-ce');
    const fragment = range.cloneContents();  // Clone the contents
    const span = document.createElement(tag);
    span.appendChild(fragment);
    span.innerHTML = span.innerHTML.replace(/<\/?(ul|ol|li)>/gi, '');
    selectedText.innerHTML = span.innerHTML;
  }

  function addListForAll(range, tag) {
    const selectedText = document.querySelector('.selected-rsw-ce');
    const fragment = range.cloneContents();  // Clone the contents
    const span = document.createElement(tag);
    const list = document.createElement('li');
    list.appendChild(fragment);
    list.innerHTML = list.innerHTML.replace(/<\/?(ul|ol|li)>/gi, '');
    span.appendChild(list);
    selectedText.innerHTML = `<${tag}>${span.innerHTML}</${tag}>`;
  }

  const applyStyleNonSelection = (tag) => {
    console.log('tagUl', tag, propValue);
    if (tag === 'ol' || tag === 'ul') {
      const selectedText = document.querySelector('.selected-rsw-ce');
      const range = document.createRange();
      range.selectNodeContents(selectedText);
      const fragment = range.cloneContents();

      let allTextIsBold = true;

      // Check if all nodes inside the fragment are bold
      fragment.childNodes.forEach(node => {

        if (node.nodeType === Node.TEXT_NODE || (node.nodeType === Node.ELEMENT_NODE && node.tagName !== tag.toUpperCase())) {
          allTextIsBold = false;  // If there's any text node or non-<b> element
        }
      });
      if (allTextIsBold) {
        // If all text is bold, remove <b> tags
        removeListForAll(range, tag);
      } else {
        // If not all text is bold, wrap all text in <b> tags
        addListForAll(range, tag);
      }


    } else {
      const selectedText = document.querySelector('.selected-rsw-ce');
      const range = document.createRange();
      range.selectNodeContents(selectedText);
      const fragment = range.cloneContents();

      let allTextIsBold = true;

      // Check if all nodes inside the fragment are bold
      fragment.childNodes.forEach(node => {

        if (node.nodeType === Node.TEXT_NODE || (node.nodeType === Node.ELEMENT_NODE && node.tagName !== tag.toUpperCase())) {
          allTextIsBold = false;  // If there's any text node or non-<b> element
        }
      });
      if (allTextIsBold) {
        // If all text is bold, remove <b> tags
        removeTagForAll(range, tag);
      } else {
        // If not all text is bold, wrap all text in <b> tags
        addTagForAll(range, tag);
      }
    }
  };

  const toggleInlineStyle = useCallback((tag) => {
    try {
      const selection = window.getSelection();
      const selectedText = selection.toString();
      console.log('selectedText', selectedText);
      if (!selectedText) {
        applyStyleNonSelection(tag);
        updateElement();
        return;
      }
      const range = selection.getRangeAt(0);
      if (tag === 'ol' || tag === 'ul') {
        handleListFormatting(range, tag);
      } else {
        const isTagApplied = isTagPresent(range.startContainer, tag);
        if (isTagApplied) {
          removeInlineStyle(range, tag);
        } else {
          applyInlineStyle(range, tag);
        }
      }
      selection.removeAllRanges();
      updateElement();
    } catch (error) {
      console.log('error', error);
    }

  }, [isTagPresent, getAncestor, removeTag, handleListFormatting, updateElement]);

  useEffect(() => {
    if (formatting.isFormattingChanged && selectedElement.id === elementObj.id) {
      requestAnimationFrame(() => {
        if (previousFormatting.current.bold !== formatting.bold) {
          previousFormatting.current.bold = formatting.bold;
          toggleInlineStyle('b');
        }
        if (previousFormatting.current.italic !== formatting.italic) {
          previousFormatting.current.italic = formatting.italic;
          toggleInlineStyle('i');
        }
        if (previousFormatting.current.underline !== formatting.underline) {
          previousFormatting.current.underline = formatting.underline;
          toggleInlineStyle('u');
        }
        if (previousFormatting.current.list !== formatting.list) {
          previousFormatting.current.list = formatting.list;
          if (formatting.list === 'ol' || formatting.list === 'ul') {
            toggleInlineStyle(formatting.list);
          } else {
            toggleInlineStyle('ol');
          }
        }
      });
    }
  }, [formatting, toggleInlineStyle]);

  // const debouncedUpdateElement = useMemo(() => _.debounce(updateElement, 500), [updateElement]);

  const handleContentChange = useCallback((e) => {
    updateElement();
  }, [elementObj, updateElement]);

  const handleSelectionChange = useCallback(() => {
    requestAnimationFrame(() => {
      const formattingAtCursor = checkFormattingAtCursor();
      setFormatting((prevFormatting) => ({
        ...prevFormatting,
        ...formattingAtCursor,
        isFormattingChanged: false,
      }));
    });
  }, [checkFormattingAtCursor, setFormatting]);

  useEffect(() => {
    const debouncedHandleSelectionChange = _.debounce(handleSelectionChange, 100);

    document.addEventListener('selectionchange', debouncedHandleSelectionChange);
    document.addEventListener('keyup', debouncedHandleSelectionChange);

    return () => {
      document.removeEventListener('selectionchange', debouncedHandleSelectionChange);
      document.removeEventListener('keyup', debouncedHandleSelectionChange);
    };
  }, [handleSelectionChange]);

  const handlePaste = useCallback((e) => {
    // Prevent the default paste behavior
    e.preventDefault();

    // Get the pasted text from the clipboard
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain');

    // Create a temporary container to parse the HTML and remove <span> tags
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = pastedData;

    // Remove all span tags and only keep their inner content
    const removeSpanTags = (node) => {
      const spans = node.getElementsByTagName('span');
      while (spans.length > 0) {
        const span = spans[0];
        while (span.firstChild) {
          span.parentNode.insertBefore(span.firstChild, span);
        }
        span.parentNode.removeChild(span);
      }
    };

    removeSpanTags(tempDiv);

    // Insert the cleaned HTML into the editor
    const cleanedData = tempDiv.innerHTML;
    document.execCommand('insertHTML', false, cleanedData);
  }, []);

  const handleOnBlur = useCallback((e) => {


  }, [updateElement]);

  return (
    <div className='rsw-editor' style={{ padding: '10px', cursor: 'move', textAlign: props?.style?.textAlign, zIndex: 1 }}>
      <div
        className={`rsw-ce ${props.className}`}
        contentEditable
        role="textbox"
        aria-multiline="true"
        aria-label="Rich Text Editor"
        ref={ref}
        placeholder={placeholder}
        onInput={handleContentChange}
        onPaste={handlePaste}
        onBlur={handleOnBlur}
        dangerouslySetInnerHTML={{ __html: propValue || placeholder }}
        style={{
          ...props.style,
          height: '100%',
          width: '100%',
          minHeight: '35px',
        }}
        suppressContentEditableWarning={true}
      />
    </div>
  );
});
SimpleWysiwyg.displayName = 'SimpleWysiwyg';

export default SimpleWysiwyg;
