import { Box, Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import SearchSection from 'components/App/SearchSection';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import { Spacer } from 'components/shared';
import { palette } from 'config/theme';
import CardContainer from 'layouts/CardContainer';
import { ModalContainer } from 'layouts/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Open = ({ onFileModelClose, setFileEl }) => {
  const { user } = useSelector((state) => state.Auth);
  const [selectedCard, setSelectedCard] = useState(null);

  const navigate = useNavigate();
  const {
    data: projects,
    count,
    loading,
    error,
    loadMore,
    searchItems,
    refreshData,
  } = usePaginatedApi('projects', 6, {
    username: {
      $eq: user?.user?.username,
    },
  });
  const handleLibraryOpen = () => {
    navigate(`/studio/${selectedCard}`);
    setFileEl(null);
    setSelectedCard(null);
    onFileModelClose();
  };
  return (
    <React.Fragment>
      <Stack sx={{ mt: 2 }}>
        <SearchSection searchItems={searchItems} searchKey={'title'} padding={'0 1rem'} />
      </Stack>
      <ModalContainer>
        <Box sx={{ overflow: 'auto', mt: 1 }}>
          {error ? (
            <Typography variant={'h2'} color="secondary">
              Error 404!
            </Typography>
          ) : (
            <React.Fragment>
              <Grid container spacing={2}>
                {projects.map((item, index) => (
                  <Grid item xs={4} key={index} md={4} sx={{ pl: 0, pr: 1 }}>
                    {console.log('timeValue', item.attributes)}
                    <CardContainer
                      id={item.id}
                      attributes={item.attributes}
                      refreshData={refreshData}
                      selectedCard={selectedCard}
                      onSelectCard={setSelectedCard}
                      onFileModelClose={onFileModelClose}
                      key={item.id}
                    />
                  </Grid>
                ))}
              </Grid>
              {loading ? (
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mt={'20px'}>
                  <CircularProgress color="secondary" />
                </Box>
              ) : projects.length === 0 ? (
                <Box display={'grid'} sx={{ placeItems: 'center' }}>
                  <Spacer x={15} y={15} />
                  <Typography variant={'h2'} align={'center'}>
                    Create your first project
                  </Typography>
                  <Spacer x={2} y={2} />
                  <Button variant="contained" color="secondary" onClick={() => navigate('/create')}>
                    Create New
                  </Button>
                </Box>
              ) : count === projects.length ? null : (
                <Box display="flex" justifyContent={'center'} alignItems={'center'}>
                  <Button variant={'loadMore'} onClick={loadMore}>
                    Load More
                  </Button>
                </Box>
              )}
            </React.Fragment>
          )}
        </Box>
      </ModalContainer>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{
          // position: 'absolute',
          // right: 0,
          // bottom: 0,
          background: palette.background.paper,
          justifyContent: 'flex-end',
          width: '100%',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
        }}
        spacing={2}
        padding="1rem">
        <Button variant="text" onClick={onFileModelClose}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={handleLibraryOpen}>
          Open
        </Button>
      </Stack>
    </React.Fragment>
  );
};

export default Open;
