import styled from '@emotion/styled';
import { palette } from 'config/theme';

export const Container = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  height: 6rem;
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  background-color: ${palette.background.paper};
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e2e2e2;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
`;

export const SlideCardContainer = styled.div`
  border: 1px solid ${({ isselected }) => (isselected ? palette.text.primary : palette.text.grey2)};
  border-radius: 8px;
  width: calc((4.375rem * 16) / 9);
  height: 4.375rem;
  position: relative;
`;

export const AddNewButton = styled.div`
  height: 4.375rem;
  aspect-ratio: 16/9;
  border: 1px solid ${palette.text.grey2};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${palette.background.paper};
  position: relative;
  &:hover .more-icon {
    display: flex; /* or display: block; */
  }
`;

export const AddSlideContainer = styled.div`
  border-radius: 16px;
  width: 60vw;
  height: 65vh;
  display: flex;
  flex-direction: column;
`;

export const SlideContainer = styled.div`
  border: 1px solid ${({ isselected }) => (isselected ? palette.text.primary : palette.text.grey2)};
  border-radius: 8px;
  width: 100%;
  aspect-ratio: 16/9;
`;

export const ScrollableStackContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  padding: 1rem;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e2e2e2;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
`;

export const Preview = styled.div`
  width: 90%;
  aspect-ratio: 16/9;
  background-color: ${palette.background.paper};
`;

export const MoreButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 7px;
  width: 1.5rem;
  height: 1.2rem;
  display: ${({ type }) => (type === 'slide' ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  background-color: ${palette.background.dark};
  color: ${palette.background.paper};
  cursor: pointer;
`;
