export const ELEMENT_Type = {
    DIV: 'div',
    SVG: 'svg',
    IMG: 'img',
    TEXT: 'text',
    RND: 'rnd',
    INDIV: 'indiv',
    POS: 'pos',
    SHAPES: 'shapes',
    ALL: 'all',
    COMPLEX_TEXT: 'complex_text',
};

export const SLIDE_TYPES = {
    SLIDE: 'slide',
};


export const sortAssetList = [
    {
        id: 1,
        name: 'User',
        value: 'user',
    },
    {
        id: 2,
        name: 'Button',
        value: 'button',
    },
    {
        id: 3,
        name: 'Background',
        value: 'background',
    },
    {
        id: 4,
        name: 'Forextraction',
        value: 'forextraction',
    },
    {
        id: 5,
        name: 'Gif',
        value: 'gif',
    },
    {
        id: 6,
        name: 'Icon',
        value: 'icon',
    },
    {
        id: 7,
        name: 'Image',
        value: 'image',
    },
    {
        id: 8,
        name: 'Lesson title',
        value: 'lessontitle',
    },
    {
        id: 9,
        name: 'Text boxes',
        value: 'textboxes',
    },
    {
        id: 10,
        name: 'Wood Land Tree',
        value: 'woodLandTreeCollection',
    },
    {
        id: 11,
        name: 'Welbeing Leisure',
        value: 'welbeingLeisureCollection',
    },
    {
        id: 12,
        name: 'Waether',
        value: 'waetherCollection',
    },
    {
        id: 13,
        name: 'Water Colour Forest',
        value: 'waterColourForestCollection',
    },
    {
        id: 14,
        name: 'Vegetables Characters',
        value: 'vegetablesCharacters',
    },
    {
        id: 15,
        name: 'Think Green',
        value: 'thinkGreenCollection',
    },
    {
        id: 16,
        name: 'Store Front',
        value: 'storeFrontCollection',
    },
    {
        id: 17,
        name: 'Sationary',
        value: 'sationaryCollection',
    },
    {
        id: 18,
        name: 'Sport',
        value: 'sportCollection',
    },
    {
        id: 19,
        name: 'Space Elements',
        value: 'spaceElementsCollection',
    },
    {
        id: 20,
        name: 'Science',
        value: 'scienceCollection',
    },
    {
        id: 21,
        name: 'Rockand Mountain',
        value: 'rockandMountainCollection',
    },
    {
        id: 22,
        name: 'Plant',
        value: 'plantCollection',
    },
    {
        id: 23,
        name: 'Para Ability',
        value: 'paraAbilityCollection',
    },
    {
        id: 24,
        name: 'Outer Space',
        value: 'outerSpaceCollection',
    },
    {
        id: 25,
        name: 'Notepad',
        value: 'notepadCollection',
    },
    {
        id: 26,
        name: 'MultiCultural People',
        value: 'multiCulturalPeopleCollection',
    },
    {
        id: 27,
        name: 'Lw',
        value: 'lwcollection',
    },
    {
        id: 28,
        name: 'Healthy Food',
        value: 'healthyFoodCollection',
    },
    {
        id: 29,
        name: 'Healthy Drinks',
        value: 'healthyDrinksCollection',
    },
    {
        id: 30,
        name: 'Halloween',
        value: 'halloweenCollection',
    },
    {
        id: 31,
        name: 'Grass and Bush',
        value: 'grassandBushCollection',
    },
    {
        id: 32,
        name: 'Gardenning and Spring',
        value: 'gardenningandSpringCollection',
    },
    {
        id: 33,
        name: 'Forest Animal',
        value: 'forestAnimalCollection',
    },
    {
        id: 34,
        name: 'Finance',
        value: 'financeCollection',
    },
    {
        id: 35,
        name: 'Farm',
        value: 'farmCollection',
    },
    {
        id: 36,
        name: 'Famous Landmark',
        value: 'famousLandmarkCollection',
    },
    {
        id: 37,
        name: 'Emoji',
        value: 'emojiCollection',
    },
    {
        id: 38,
        name: 'Easter',
        value: 'easterCollection',
    },
    {
        id: 39,
        name: 'Earth Day',
        value: 'earthDayCollection',
    },
    {
        id: 40,
        name: 'Dinosaur',
        value: 'dinosaurCollection',
    },
    {
        id: 41,
        name: 'Dental',
        value: 'dentalCollection',
    },
    {
        id: 42,
        name: 'Cute Monster 1',
        value: 'cuteMonsterCollection1',
    },
    {
        id: 43,
        name: 'Cute Monster 2',
        value: 'cuteMonsterCollection2',
    },
    {
        id: 44,
        name: 'Cute Animal 2',
        value: 'cuteAnimalCollection2',
    },
    {
        id: 45,
        name: 'Cute animal',
        value: 'cuteanimalCollection',
    },
    {
        id: 46,
        name: 'Cloud',
        value: 'cloudCollection',
    },
    {
        id: 47,
        name: 'Classroom',
        value: 'classroomCollection',
    },
    {
        id: 48,
        name: 'Cityscape',
        value: 'cityscapeCollection',
    },
    {
        id: 49,
        name: 'Chritmas',
        value: 'chritmasCollection',
    },
    {
        id: 50,
        name: 'Bird',
        value: 'birdCollection',
    },
    {
        id: 51,
        name: 'Banner',
        value: 'bannerCollection',
    },
    {
        id: 52,
        name: 'Autumn Leaves',
        value: 'autumnLeavesCollection',
    },
    {
        id: 53,
        name: 'Animal Avatar',
        value: 'animalAvatarCollection',
    },
    {
        id: 54,
        name: 'Alien',
        value: 'alienCollection',
    },
    {
        id: 55,
        name: 'Africa',
        value: 'africaCollection',
    },
    {
        id: 56,
        name: 'Abstract Graffiti Sticker',
        value: 'abstractGraffitiStickerCollection',
    },
    {
        id: 57,
        name: 'Winter',
        value: 'winterIcons',
    },
    {
        id: 58,
        name: 'Weahter',
        value: 'weahterIcons',
    },
    {
        id: 59,
        name: 'Tropical',
        value: 'tropicalIcons',
    },
    {
        id: 60,
        name: 'Transport',
        value: 'transportIcons',
    },
    {
        id: 61,
        name: 'Three WiseMen',
        value: 'threeWiseMenIcons',
    },
    {
        id: 62,
        name: 'Summer',
        value: 'summerIcons',
    },
    {
        id: 63,
        name: 'Stationary',
        value: 'stationaryIcons',
    },
    {
        id: 64,
        name: 'Spring',
        value: 'springIcons',
    },
    {
        id: 65,
        name: 'Sealife',
        value: 'sealifeIcons',
    },
    {
        id: 66,
        name: 'Receptionn',
        value: 'receptionnIcons',
    },
    {
        id: 67,
        name: 'LGBTQ',
        value: 'LGBTQIcons',
    },
    {
        id: 68,
        name: 'International Movements',
        value: 'internationalMovementsIcons',
    },
    {
        id: 69,
        name: 'History',
        value: 'historyIcons',
    },
    {
        id: 70,
        name: 'FineArts',
        value: 'fineArtsIcons',
    },
    {
        id: 71,
        name: 'Farm',
        value: 'farmIcons',
    },
    {
        id: 72,
        name: 'Families',
        value: 'familiesIcons',
    },
    {
        id: 73,
        name: 'Fair',
        value: 'fairIcons',
    },
    {
        id: 74,
        name: 'Environment',
        value: 'environmentIcons',
    },
    {
        id: 75,
        name: 'Egypt',
        value: 'egyptIcons',
    },
    {
        id: 76,
        name: 'Eduction',
        value: 'eductionIcons',
    },
    {
        id: 77,
        name: 'Ecology',
        value: 'ecologyIcons',
    },
    {
        id: 78,
        name: 'Easter',
        value: 'easterIcons',
    },
    {
        id: 79,
        name: 'Diwali',
        value: 'diwaliIcons',
    },
    {
        id: 80,
        name: 'City',
        value: 'cityIcons',
    },
    {
        id: 81,
        name: 'Christmas 1',
        value: 'christmasIcons1',
    },
    {
        id: 82,
        name: 'Christmas 2',
        value: 'christmasIcons2',
    },
    {
        id: 83,
        name: 'Birthday',
        value: 'birthdayIcons',
    },
    {
        id: 84,
        name: 'Autumn',
        value: 'autumnIcons1',
    },
    {
        id: 85,
        name: 'Autumn',
        value: 'autumnIcons2',
    },
    {
        id: 86,
        name: 'Animals',
        value: 'animalsIcons',
    },
    {
        id: 87,
        name: 'Wooden Planks',
        value: 'woodenPlanks',
    },
    {
        id: 88,
        name: 'Vegetables',
        value: 'vegetables',
    },
    {
        id: 89,
        name: 'Teacher Doodles',
        value: 'teacherDoodles',
    },
    {
        id: 90,
        name: 'Sun Illustration',
        value: 'sunIllustration',
    },
    {
        id: 91,
        name: 'Summer Time',
        value: 'summerTime',
    },
    {
        id: 92,
        name: 'Social Media',
        value: 'socialMedia',
    },
    {
        id: 93,
        name: 'Sea Animals',
        value: 'seaAnimals',
    },
    {
        id: 94,
        name: 'Science',
        value: 'science',
    },
    {
        id: 95,
        name: 'Safety',
        value: 'safety',
    },
    {
        id: 96,
        name: 'Medieval',
        value: 'medieval',
    },
    {
        id: 97,
        name: 'Lawand Order',
        value: 'lawandOrder',
    },
    {
        id: 98,
        name: 'Knights',
        value: 'knights',
    },
    {
        id: 99,
        name: 'Happy Students',
        value: 'happyStudents',
    },
    {
        id: 100,
        name: 'Halloween',
        value: 'halloween',
    },
    {
        id: 101,
        name: 'GirlyDoodles',
        value: 'girlyDoodles',
    },
    {
        id: 102,
        name: 'Environment',
        value: 'environment',
    },
    {
        id: 103,
        name: 'EarthDay',
        value: 'earthDay',
    },
    {
        id: 104,
        name: 'Dinosaurs',
        value: 'dinosaurs',
    },
    {
        id: 105,
        name: 'Dental',
        value: 'dental',
    },
    {
        id: 106,
        name: 'CulturalWomen',
        value: 'culturalWomen',
    },
    {
        id: 107,
        name: 'Circus',
        value: 'circus',
    },
    {
        id: 108,
        name: 'Christmas',
        value: 'christmas',
    },
    {
        id: 109,
        name: 'Characters',
        value: 'characters',
    },
    {
        id: 110,
        name: 'Cartoon Monesters',
        value: 'cartoonMonesters',
    },
    {
        id: 111,
        name: 'Cartoon Animals',
        value: 'cartoonAnimals',
    },
    {
        id: 112,
        name: 'Party Collection',
        value: 'partyCollection',
    },
    {
        id: 113,
        name: 'PetIcon Collection',
        value: 'petIconCollection',
    },
    {
        id: 114,
        name: 'Pets Collection',
        value: 'petsCollection',
    },
];

