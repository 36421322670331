import React, { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { CustomButton, TextInput } from './styles';
import { palette } from 'config/theme';
import { InsertIcon } from 'pages/studio/Components/SVGs';
import { Modal } from 'components/shared';
import { useSelector } from 'react-redux';
import SlidePreview from 'pages/studio/Components/SlidePreview';

const Guidelines = ({
  previewText,
  setPreviewText,
  emit,
  setOpenSnackbar,
  loading,
  setLoading,
  setError,
  handleSlideInsert,
  openPreview,
  setOpenPreview,
  preview,
}) => {
  const { selectedSlide } = useSelector((state) => state.Canvas.present);

  const handleSlideModal = () => setOpenPreview(true);

  const handleSliderGenerate = () => {
    setLoading(true);
    try {
      emit(
        'pipeline:execute',
        JSON.stringify({
          slug: 'regenerate-slide',
          payload: {
            guidelines: previewText,
            slide: selectedSlide,
          },
        })
      );
      if (loading) {
        handleSlideModal();
      }
    } catch (e) {
      setLoading(false);
      setError('server error');
      setOpenSnackbar(true);
      console.log('regeneration text ERROR::', e);
    }
  };

  return (
    <>
      <Typography variant="body2" sx={{ color: palette?.text?.grey3, mt: 3, mb: 1 }}>
        Slide guidelines
      </Typography>
      <TextInput
        placeholder="Example: Recreate the slide with appropriate imagery"
        variant="outlined"
        multiline
        value={previewText}
        onChange={(e) => setPreviewText(e.target.value)}
        sx={{ mt: 2 }}
      />
      <CustomButton onClick={handleSliderGenerate} disabled={loading ? true : false}>
        {loading ? 'Loading...' : 'Preview'}
      </CustomButton>
      <Modal
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        title="Slide Preview"
        aria-labelledby="slide-modal-title"
        aria-describedby="slide-modal-description"
        size="xl"
      >
        <Box
          sx={{
            position: 'relative',
            width: '800px', // Make the width responsive
            maxWidth: '850px', // Maintain a max width for scaling
            height: 'auto', // Adjust height automatically based on content
            aspectRatio: '16/9', // Ensure the aspect ratio is maintained
            backgroundColor: preview?.Meta?.Background || '#fff',
            margin: '20px auto', // Center the preview box
            overflow: 'hidden', // Ensure content doesn't overflow the box
            margin: '20px'
          }}
        >
          <SlidePreview slide={preview} />
        </Box>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ width: '100%', mt: 2, p: 2 }}>
          <Button onClick={() => setOpenPreview(false)} color="secondary" sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button variant="contained" color="secondary" startIcon={<InsertIcon />} onClick={handleSlideInsert}>
            Insert
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default Guidelines;