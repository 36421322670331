import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import images from 'config/images';
import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import styled from '@emotion/styled';
import theme from 'config/theme';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'redux-layout/actions';
import { ProfileIcon } from 'config/svgs';

const LogoImg = styled.img`
  width: 100%;
  object-fit: cover;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.palette.background.bg1};
  height: 56px;
  padding: 0 16px;
`;

const LibraryBody = styled.div`
  overflow: hidden;
  height: 100vh;
  width: 100vw;
`;

const LibraryLayout = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logoutUser(null));
    setAnchorEl(null);
    navigate('/account/logout');
  };
  return (
    <LibraryBody>
      <Container>
        <Button variant="backBtn" startIcon={<KeyboardBackspaceIcon />} sx={{ padding: '6px 4px' }}>
          Back to LifeWise
        </Button>
        <Box>
          <LogoImg src={images.header.lifewiseLogo} alt="Lifewise Studio" />
        </Box>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          <ProfileIcon color={'#FFFFFF'} />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <ProfileIcon color={'#717171'} />
            </ListItemIcon>
            <ListItemText>profile</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu>
      </Container>
      <Outlet />
    </LibraryBody>
  );
};

export default LibraryLayout;
