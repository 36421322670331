import styled from '@emotion/styled';
import { Box, Divider, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const Container = styled.div`
  padding: 12px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  box-sizing: border-box;
  background: #ffffff
    ${
      '' /* &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #FFFFFF;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #FFFFFF;
  } */
    };
`;

export const SwipeButtonRight = styled(ChevronRightIcon)`
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  padding: 8px var(--spacing-none, 0px);
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  background: linear-gradient(270deg, #262725 0%, rgba(38, 39, 37, 0) 100%);
  min-height: 60px;
  margin-top: 12px;
  ${'' /* color: #000;  */}
`;
export const SwipeButtonLeft = styled(ChevronLeftIcon)`
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  padding: 8px var(--spacing-none, 0px);
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  background: linear-gradient(270deg, #262725 0%, rgba(38, 39, 37, 0) 100%);
  min-height: 60px;
  margin-top: 12px;
  ${'' /* color: #000;  */}
`;

export const NavButtonStyles = {
  backgroundColor: 'transparent',
  borderRadius: 0,
  marginRight: '-14px',
  marginLeft: '-14px',
  zIndex: 10,
};

export const ColorLensIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
}));

export const DividerStyled = styled(Divider)(({ theme }) => ({
  background: '#E2E2E2',
  height: '50px',
  width: '1px',
}));

export const ColorCircle = styled('div')(({ color }) => ({
  backgroundColor: color,
  borderRadius: '50%',
  width: 40,
  height: 40,
}));

export const CarouselContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  position: 'relative',
}));
