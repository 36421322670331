import { Box, Button, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BoxAlign, TopbarContainer, TopbarTab } from './styles';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'components/shared';
import FileUploader from 'components/shared/FileUploader';
import { newAssets } from 'helpers/apiCalls';
import { useSelector } from 'react-redux';

const Topbar = ({ refreshData, setAddAssets, addAssets }) => {
  const navigate = useNavigate();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.Auth);

  useEffect(() => {
    if (addAssets) {
      setIsModelOpen(true);
      setAddAssets(false);
    }
  }, [addAssets]);

  const onModelOpen = () => setIsModelOpen(true);
  const onModelClose = () => setIsModelOpen(false);

  const HandleUploadAssets = async () => {
    setIsLoading(true);
    try {
      let makeApi = uploadedFile.map((file) => {
        let name = file.name.split('.')[0] || '';
        let extensionType = '';
        const regexImage = /\.(jpg|jpeg)$/i;
        const regexGif = /\.(gif)$/i;
        const regexPng = /\.(png)$/i;
        const regexSvg = /\.(svg)$/i;
        if (regexImage.test(file.name)) {
          extensionType = 'jpg';
        } else if (regexGif.test(file.name)) {
          extensionType = 'Gif';
        } else if (regexPng.test(file.name)) {
          extensionType = 'png';
        } else if (regexSvg.test(file.name)) {
          extensionType = 'svg';
        }
        let data = {
          data: {
            asset: file.id,
            type: 'user',
            contenttype: 'user',
            name,
            assetType: extensionType,
            username: user?.user?.email
          },
        };
        // let data = {
        //   data: {
        //     asset: file.id,
        //     type: 'system',
        //     contenttype: 'storeFrontCollection',
        //     name,
        //     assetType: extensionType,
        //     section: 'Image'
        //   }
        // };
        newAssets(data)
          .then((res) => {
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      });
      let response = await Promise.all(makeApi);
    } catch (error) {
      console.log('error', error);
    }
    refreshData();
    onModelClose();
  };

  return (
    <TopbarContainer>
      <TopbarTab>
        <Button variant="tab" onClick={() => navigate('/library')}>
          Library
        </Button>
        <Button variant="activeTab">Assets</Button>
      </TopbarTab>
      <BoxAlign>
        <Box>
          <Typography variant="body3">Upload your own assets and use them in your lessons</Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={onModelOpen}
            sx={{
              padding: '8px 16px 8px 16px',
              width: '120px',
            }}>
            Upload
          </Button>
        </Box>
      </BoxAlign>
      <Modal open={isModelOpen} onClose={onModelClose} title={'Upload File'} size="md">
        <FileUploader setUploadedFile={setUploadedFile} />
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '1rem' }}>
          {isLoading ? (
            <Button variant="contained" color="secondary" disabled>
              Uploading...
            </Button>
          ) : (
            <Button variant="contained" color="secondary" onClick={HandleUploadAssets} disabled={uploadedFile.length === 0}>
              Done
            </Button>
          )}
        </Box>
      </Modal>
    </TopbarContainer>
  );
};

export default Topbar;
