import React, { useContext, useState } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import {
  ColorLensIconButton,
  CarouselContainer,
  ColorCircle,
  NavButtonStyles,
  SwipeButtonLeft,
  SwipeButtonRight,
  DividerStyled,
} from './styles';
import { palette } from 'config/theme';
import { elementUpdated, slideUpdated } from 'redux-layout/actions';
import { useDispatch, useSelector } from 'react-redux';
import SketchColorPicker from 'pages/studio/Components/ColorPicker';
import _ from 'lodash';
import { StudioContext } from 'pages/studio';

const ColorPicker = () => {
  const dispatch = useDispatch();
  const { contextState, setContextState } = useContext(StudioContext);
  const { selectedElement } = useSelector((state) => state.Canvas.present);

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState('#fff');
  const [colors, setColors] = useState(['#FAD53F', '#B6E2D3', '#EC407A', '#42A5F5', '#66BB6A', '#E2E2E2']);

  const handleColorClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleColorChange = (color) => {
    _.debounce(() => {
      setColors((prevColors) => [color.hex, ...prevColors]);
    }, 500)();
    setSelectedColor(color.hex);
    setDisplayColorPicker(false);
  };

  const getColorChunks = () => {
    const chunkSize = 5;
    const chunks = [];
    for (let i = 0; i < colors.length; i += chunkSize) {
      chunks.push(colors.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleColorUpdate = (color) => {
    if (contextState?.color === 'background') {
      dispatch(slideUpdated('background-color', color));
    } else {
      dispatch(elementUpdated(selectedElement, color, 'color'));
    }
  };

  const handleBackgroundColorAdd = (color) => {
    dispatch(slideUpdated('background-color', color));
  };

  return (
    <Box mt={2}>
      <Typography variant="body2" sx={{ color: palette?.text?.dark2 }}>
        Document Colours
      </Typography>
      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: '100%', gap: 1 }}>
        <SketchColorPicker color={selectedColor} handleColorComplete={handleColorChange}>
          <ColorLensIconButton onClick={handleColorClick}>
            <ColorLensIcon sx={{ color: palette?.background?.dark }} />
          </ColorLensIconButton>
        </SketchColorPicker>
        <DividerStyled />
        <CarouselContainer>
          <Carousel
            autoPlay={false}
            navButtonsAlwaysVisible
            indicators={false}
            // animation='fade'
            cycleNavigation={false}
            NextIcon={<SwipeButtonRight />}
            PrevIcon={<SwipeButtonLeft />}
            navButtonsProps={{ style: NavButtonStyles }}
            sx={{ width: '100%', height: 60 }}>
            {getColorChunks().map((chunk, idx) => (
              <Box key={idx} display="flex" sx={{ gap: 2, p: 1 }}>
                {chunk.map((color, colorIdx) => (
                  <IconButton key={colorIdx} onClick={() => handleColorUpdate(color)} sx={{ p: 0 }}>
                    <ColorCircle color={color} />
                  </IconButton>
                ))}
              </Box>
            ))}
          </Carousel>
        </CarouselContainer>
      </Stack>
    </Box>
  );
};

export default ColorPicker;
