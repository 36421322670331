import React from 'react';
import { Container, Img, TextBox } from './styles';
import images from 'config/images';
import { Spacer } from 'components/shared';
import { Box, Typography } from '@mui/material';

const IsAuthorise = () => {
    return (
        <Container>
            <Img src={images.studio.error.error_popup} />
            {/* <Spacer x={1} y={1} /> */}
            <TextBox>
                <Typography variant="bold_text">
                    Sorry! But you cannot view this lesson as it has not been published for you yet
                </Typography>
                <Spacer x={0.5} y={0.5} />
                <Typography variant="normal_text" color="#0D0D0D">
                    Please ask the author to publish this lesson if you need to access it in the studio.
                </Typography>
            </TextBox>
        </Container>
    );
};

export default IsAuthorise;
