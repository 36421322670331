import React, { useContext } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { CustomButton, PhotoItem, PhotoItemWrapper } from './style';
import { StudioContext } from 'pages/studio';
import { PHOTO_COLLECTION } from '../config';

const CustomCarousel = ({ items }) => {
    const { setContextState, setElementType } = useContext(StudioContext);
    const NextArrow = ({ onClick }) => (
        <CustomButton onClick={onClick} style={{ right: -10 }}>
            <KeyboardArrowRightIcon />
        </CustomButton>
    );

    const PrevArrow = ({ onClick }) => (
        <CustomButton onClick={onClick} style={{ left: -10 }}>
            <KeyboardArrowLeftIcon />
        </CustomButton>
    );

    const settings = {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: 1, // Show only 1 slide (we'll control the scroll via variableWidth)
        slidesToScroll: 1, // Scroll 1 by 1 (or use `swipeToSlide` for smooth swiping)
        variableWidth: true, // Enables custom width for slides
        swipeToSlide: true, // Allows smooth swiping
        centerMode: false, // Centers the active slide
        nextArrow: <NextArrow />, // Custom Next button
        prevArrow: <PrevArrow />, // Custom Prev button
    };

    const handleCollection = (item) => {
        setContextState((prev) => ({ ...prev, collectionType: item }));
        setElementType(PHOTO_COLLECTION);
    };

    return (
        <Slider {...settings}>
            {items.map((item, index) => (
                <PhotoItemWrapper key={index} onClick={() => handleCollection(item)}>
                    <PhotoItem>
                        <Typography variant="body1">
                            {item.name}
                        </Typography>
                    </PhotoItem>
                </PhotoItemWrapper>
            ))}
        </Slider>
    );
};

export default CustomCarousel;
