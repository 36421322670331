import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { pad } from 'lodash';
import { fontFamily, fontSize } from 'pages/studio/Toolbar/config';

export const typography = {
  custom: {
    colorCode: {
      textPrimary: 'rgba(0,0,0,0.87)',
      textSecondary: 'rgba(0,0,0,0.60)',
    },
  },
  fontFamily: ['Filson Soft', 'sans-serif'].join(','),
  body1: {
    fontSize: '16px',
    fontWeight: 400,
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
  },
  body3: {
    fontSize: '14px',
    fontWeight: 700,
  },
  body4: {
    fontSize: '14px',
    fontWeight: 400,
  },
  h1: {
    fontSize: '20px',
    lineHeight: '120%',
    fontWeight: 500,
  },
  h2: {
    fontSize: '18px',
    lineHeight: '120%',
    fontWeight: 500,
    letterSpacing: '-0.5px',
  },
  h3: {
    fontSize: '16px',
    lineHeight: '123.5%',
    fontWeight: 400,
    letterSpacing: '0.25px',
  },
  h4: {
    fontSize: '16px',
    color: '#000000',
  },
  h5: {
    fontSize: '12px',
    lineHeight: '133.4%',
    fontWeight: 400,
  },
  h6: {
    fontSize: '12px',
    lineHeight: '133.4%',
    fontWeight: 400,
    letterSpacing: '0.15px',
  },
  faded: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.6)',
    letterSpacing: '0.15px',
    lineHeight: '150%',
    fontWeight: 400,
  },
  small: {
    fontSize: '10px',
    color: 'rgba(255, 255, 255, 1)',
    letterSpacing: '0.15px',
    lineHeight: '150%',
    fontWeight: 400,
  },
  semi_large: {
    fontSize: '18px',
    fontWeight: 400,
  },
  large: {
    fontSize: '24px',
    fontWeight: 400,
  },
  bold_text: {
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'Filson Pro',
  },
  normal_text: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Filson Pro',
  }
};

export const palette = {
  type: 'light',
  primary: {
    main: '#031729',
    dark: '#010C15',
    light: '#132B41',
    shapeBg: '#D9D9D9',
  },
  secondary: {
    main: '#009569',
    dark: '#0a805d',
    light: '#12c993',
  },
  success: {
    main: 'rgba(46, 125, 50, 1)',
  },
  text: {
    primary: 'rgba(0,0,0,0.87)',
    secondary: 'rgba(0,0,0,0.60)',
    grey1: 'rgba(0,0,0,0.54)',
    grey2: 'rgba(0,0,0,0.30)',
    grey3: '#595D62',
    grey4: '#E2E2E2',
    grey5: '#F8F8F8',
    grey6: '#F5F5F5',
    black: '#000',
    white: '#fff',
    dark2: '#0D0D0D'
  },
  background: {
    default: '#FAFAFA',
    paper: '#ffffff',
    bg1: 'rgba(0, 149, 105, 1)',
    dark: '#000000',
    light: 'rgba(0, 149, 104, 0.2)',
    grey1: '#E2E2E2',
    dark2: '#191B18',
    dark3: '#262725',
  },
  border: {
    main: 'rgba(0,0,0,0.12)',
    secondary: 'rgba(0,0,0,0.08)',
    light: 'rgba(0,0,0,0.06)',
    primary: 'rgba(0, 149, 105, 0.8)',
  },
  tab: {
    background: '#f5f5f5',
  },
};

export const components = {
  MuiDialog: {
    styleOverrides: {
      root: {
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 1)', // Custom backdrop color for modal
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          '& input': {
            padding: '6px 14px', // Decreased padding
            minWidth: '100px',
          },
          '& .MuiSelect-outlined': {
            padding: '6px 14px', // Decreased padding
            paddingRight: '32px', // Decreased padding
          },
          '& .MuiInputAdornment-root': {
            marginRight: '0px', // Adjust if you have adornments
          },
          '&:hover fieldset': {
            borderColor: '#009569',
            borderWidth: '2px',
          },
        },
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      list: {
        '& li': {
          gap: '0.5rem',
          alignItems: 'center',
          height: '45px',
          fontSize: '14px',
          color: 'rgba(0,0,0,0.6)',
          '& svg': {
            width: '24px',
            height: '24px',
            color: 'rgba(0,0,0,0.6)',
            marginRight: '8px',
          },
          '&:hover': {
            color: palette.secondary.main,
            backgroundColor: 'rgba(25, 118, 210, 0.08)',
            '& svg': {
              color: palette.secondary.main,
            },
          },
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        textTransform: 'inherit',
      },
      backBtn: {
        color: '#fff', // Set the text color for text variant buttons
        textTransform: 'inherit',
        fontSize: '16px',
      },
      btnText1: {
        fontSize: '24px',
        fontWeight: 400,
      },
      btnText2: {
        fontSize: '16px',
        fontWeight: 400,
      },
      btnText3: {
        fontSize: '12px',
        fontWeight: 400,
      },
      btnText4: {
        fontSize: '16px',
        fontWeight: 700,
      },
      preview: {
        background: '#fff', // Set the text color for text variant buttons
        color: palette.secondary.main,
        textTransform: 'inherit',
        fontSize: '16px',
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.95)',
        },
      },
      seeAll: {
        color: '#fff',
        textTransform: 'inherit',
        fontSize: '14px',
        fontWeight: 700,
      },
      contained: {
        color: '#fff', // Set the text color for text variant buttons
        textTransform: 'inherit',
        fontSize: '16px',
      },
      activeTab: {
        color: '#000', // Set the text color for text variant buttons
        textTransform: 'inherit',
        fontSize: '14px',
        backgroundColor: '#fff',
        fontWeight: 700,
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.8)',
        },
      },
      tab: {
        color: '#000', // Set the text color for text variant buttons
        textTransform: 'inherit',
        fontSize: '14px',
        fontWeight: 700,
      },
      loadMore: {
        color: '#009569', // Set the text color for text variant buttons
        textTransform: 'inherit',
        fontSize: '14px',
        fontWeight: 700,
        backgroundColor: '#fff',
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,1)',
          color: '#0a805d',
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        '& .MuiButtonBase-root.Mui-selected': {
          color: palette.secondary.main,
          fontWeight: 700,
        },
        '& .MuiTabs-indicator': {
          backgroundColor: palette.secondary.main,
          height: '3px',
        },
        '& .MuiButtonBase-root ': {
          fontWeight: 700,
          textTransform: 'inherit',
        },
      },
    },
  },
};

export const overrides = {
  overrides: {
    MuiListItem: {
      root: {},
    },
  },
};

const theme = createTheme({
  palette,
  typography,
  components,
  overrides,
});

export default theme;

export const drawerWidth = 220;
export const drawerCollapseWidth = 55;
