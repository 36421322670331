import React from 'react';

export const AIIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1143 12.0723C18.3744 12.9605 16.2267 15.1082 15.3384 17.8493C14.4501 15.1082 12.3024 12.9605 9.56252 12.0723C12.3024 11.184 14.4501 9.03627 15.3384 6.29639C16.2267 9.03627 18.3744 11.184 21.1143 12.0723Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.98341 5.56871C8.02757 5.87858 7.27833 6.62783 6.96843 7.58409C6.65853 6.62783 5.90928 5.87858 4.95344 5.56871C5.90928 5.2588 6.65853 4.50955 6.96843 3.55371C7.27833 4.50955 8.02757 5.2588 8.98341 5.56871Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.09278 17.4563C7.67478 17.916 6.56328 19.0275 6.10354 20.4462C5.64379 19.0275 4.53227 17.916 3.11426 17.4563C4.53227 16.9966 5.64379 15.885 6.10354 14.467C6.56328 15.885 7.67478 16.9966 9.09278 17.4563Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ImproveIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9998 7.556L12.8387 8.1066C12.1801 8.41933 11.3965 8.2838 10.8812 7.76847L8.23133 5.1186C7.716 4.6033 7.58053 3.81977 7.8932 3.16047L8.4438 2M10.8021 7.68967L9.953 12.813C9.89647 13.1539 9.60147 13.401 9.25553 13.3918C8.01247 13.3601 5.07813 13.3629 2.87507 13.9749C2.36471 14.1168 1.88329 13.6353 2.02518 13.125C2.63718 10.9219 2.64001 7.9876 2.60824 6.74453C2.59977 6.39863 2.84613 6.10357 3.18707 6.04709L8.3104 5.19792M5.64666 10.3529L2.20313 13.7961M5.64666 10.3529C5.21889 9.92513 5.21858 9.23227 5.64635 8.8052C6.07341 8.37747 6.7666 8.37747 7.19433 8.8052C7.62213 9.23227 7.62213 9.92547 7.19433 10.3532C6.7666 10.7803 6.07372 10.7799 5.64666 10.3529Z"
        stroke="#009569"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const SimplifyIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6317 13.6983H13.4201M7.48107 13.6205L13.5093 7.59231C14.1636 6.93797 14.1636 5.87685 13.5093 5.22251L11.0796 2.79288C10.4253 2.13854 9.36487 2.13854 8.71047 2.79288L2.52935 8.97404C1.82355 9.67984 1.82355 10.8244 2.52935 11.5302L4.15652 13.1713C4.47986 13.4974 4.92004 13.681 5.37968 13.6817L13.9946 13.6984M5.57292 5.92973L10.3723 10.7292"
        stroke="#009569"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const ShortenIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1987 11.8946L6.70054 5.86983M13.1987 4.10421L6.70054 10.129M5.47923 6.46371C6.34355 6.46371 7.0444 5.76286 7.0444 4.89854C7.0444 4.03422 6.34355 3.33337 5.47923 3.33337C4.61491 3.33337 3.91406 4.03422 3.91406 4.89854C3.91406 5.76286 4.61491 6.46371 5.47923 6.46371ZM5.47923 9.53637C6.34355 9.53637 7.0444 10.2372 7.0444 11.1016C7.0444 11.9658 6.34355 12.6667 5.47923 12.6667C4.61491 12.6667 3.91406 11.9658 3.91406 11.1016C3.91406 10.2372 4.61491 9.53637 5.47923 9.53637Z"
        stroke="#009569"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const FriendlyIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.84635 6.13637V6.10199M10.1538 6.13637V6.10199M6.20313 9.42707C6.64032 9.9966 7.28447 10.3514 7.99993 10.3514C8.7154 10.3514 9.35947 9.9966 9.79667 9.42707M5.47213 13.4429C7.69313 14.4704 10.4097 14.0741 12.2428 12.2403C14.5838 9.89933 14.5877 6.1008 12.2428 3.75526C9.9018 1.41491 6.09873 1.41491 3.75773 3.75526C1.92463 5.58901 1.52766 8.30493 2.55577 10.5259C2.68745 10.8548 2.78994 11.1188 2.78994 11.375C2.78994 12.0911 2.09912 12.9779 2.55967 13.4384C3.02021 13.8989 3.90693 13.2081 4.61915 13.2036C4.87472 13.2042 5.14391 13.3106 5.47213 13.4429ZM5.81601 6C5.73334 6 5.66601 6.06733 5.66667 6.15C5.66667 6.23267 5.73401 6.3 5.81667 6.3C5.89934 6.3 5.96667 6.23267 5.96667 6.15C5.96667 6.06667 5.89934 6 5.81601 6ZM10.1493 6C10.0667 6 9.99933 6.06733 10 6.15C10 6.23267 10.0673 6.3 10.15 6.3C10.2327 6.3 10.3 6.23267 10.3 6.15C10.3 6.06667 10.2327 6 10.1493 6Z"
        stroke="#009569"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const TextLeftIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.0133 5.53499H4.65332M20.0133 14.4946H4.65332M16.5996 10.0125H4.65332M16.5996 18.9731H4.65332"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TextNumberIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.31982 16.0953C4.31982 15.0351 5.17908 14.175 6.24008 14.175C7.30026 14.175 8.15952 15.0351 8.15952 16.0953C8.15952 16.5568 7.95446 17.2609 7.24796 17.8968C6.54145 18.5319 4.61621 19.935 4.61621 19.935H8.15952M6.24022 10.3341V4.575L4.46607 6.00378M11.3605 6.495H19.6799M11.3605 12.2545H19.6799M11.3605 18.0141H19.6799"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TextBulletPointIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.66699 6H21.667M8.66699 12H21.667M8.66699 18H21.667M3.66699 6H3.67699M3.66699 12H3.67699M3.66699 18H3.67699"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const InsertIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.793 12.7764L11.9985 15.58L9.20701 12.7764M12 15.5802V8.41895M14.8117 19.7708L19.77 14.8116C21.5029 13.0787 21.3589 10.7767 19.627 9.04476L14.9547 4.37254C13.2238 2.64162 10.9218 2.49664 9.18793 4.23049L4.22965 9.18876C2.49678 10.9216 2.64176 13.2237 4.37268 14.9546L9.0449 19.6268C10.7768 21.3587 13.0837 21.4988 14.8117 19.7708Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
