import { Box, CircularProgress, Grid, Typography, Modal } from '@mui/material';
import useResizeHeight from 'components/hooks/useResizeHeight';
import React, { useState, useEffect, createContext } from 'react';
import LeftAddElements from './LeftAddElements';
import Toolbar from './Toolbar';
import CanvasFrame from './Canvas';
import Slides from './Slider';
import Footer from './Footer';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OpenAssets from './LeftAddElements/OpenAssets';
import { logoutUser, projectInit } from 'redux-layout/actions';
import IsAuthorise from './Components/Authorise';
import { useStore } from 'Socket';

const StudioContext = createContext();

const Studio = () => {
  const { ref, height } = useResizeHeight();
  const [elementType, setElementType] = useState('');
  const [imageType, setImageType] = useState('');
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const [formatting, setFormatting] = useState({
    bold: false,
    italic: false,
    underline: false,
    list: '',
    isFormattingChanged: false,
  });
  const [contextState, setContextState] = useState({
    collectionType: {},
    regeneratedText: { id: '', text: '' },
    color: ''
  });
  const [nonAuthourise, setNonAuthorise] = useState(false);
  const { disconnect, isConnected } = useStore();

  const [style, setStyle] = useState({
    height: '100%',
    width: '100%',
  });
  const { project, selectedElement, isError, isAuthorise } = useSelector((state) => state.Canvas.present);

  useEffect(() => {
    let projectType = '';
    if (location.pathname.includes('theme')) {
      projectType = 'theme';
    }
    let type = location.search.split('=')[1];
    dispatch(projectInit(params?.cid, location.state?.type || type || projectType));

    return () => {
      dispatch(projectInit(null));
    };
  }, [location.pathname, location.search, location.state, params?.cid, dispatch]);


  useEffect(() => {
    if ('id' in selectedElement) {
      // setElementType('TOOLBAR_ELEMENT');
    } else {
      if (elementType.includes('TOOLBAR')) {
        setElementType('');
      }
    }
  }, [selectedElement]);

  useEffect(() => {
    if (!isAuthorise) {
      setNonAuthorise(true);
    }
  }, [isAuthorise]);

  const handleModalClose = () => {
    setNonAuthorise(false);
    dispatch(logoutUser(null));
    if (isConnected) {
      disconnect();
    }
  };
  if (!project?.meta) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" ref={ref} height={height}>
        <CircularProgress color="secondary" />
      </Box>
    );
  }
  if (isError) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={'100%'}>
        <Typography variant="h2">Server issue. Please reload the page!!</Typography>
      </Box>
    );
  }
  return (
    <StudioContext.Provider value={{ formatting, setFormatting, elementType, setElementType, setImageType, imageType, setContextState, contextState }}>
      <Grid container ref={ref} height={height}>
        <Grid item xs={0.65} sx={{ background: '#000', zIndex: 10, height: '100%' }}>
          <LeftAddElements elementType={elementType} setElementType={setElementType} />
        </Grid>
        <OpenAssets elementType={elementType} setElementType={setElementType} />
        <Grid item xs={elementType ? 9 : 11.35} display={'flex'} flexDirection={'column'} zIndex={1}>
          <Toolbar />
          <CanvasFrame style={style} setStyle={setStyle} />
          <Slides setElementType={setElementType} />
          <Footer style={style} setStyle={setStyle} />
        </Grid>
      </Grid>
      <Modal
        open={nonAuthourise}
        onClose={handleModalClose}
        size="md"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <IsAuthorise />
      </Modal>
    </StudioContext.Provider>
  );
};

export default Studio;
export { StudioContext };
