import React, { useEffect, useState } from 'react';
import { Button, Grid, Snackbar, Stack, Typography } from '@mui/material';
import { Form } from 'components/shared';
import { AttachmentButton, CustomButton, GridContainer, PublishModalContainer } from 'layouts/styles';
import { ModalPublishIcon, TagIcon, UploadIcon } from 'config/svgs';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { palette } from 'config/theme';
import { subjectOptions } from './config';
import { useSelector } from 'react-redux';
import { baseUrl, createPublishLesson, createPublishLessonForLifeWise, getKeywords, getYearGroups } from 'helpers/apiCalls';

const PublishToLifeWise = ({ onPublishModelClose }) => {
  const { project, projectId, selectedSlide } = useSelector((state) => state.Canvas.present);
  const { user } = useSelector((state) => state.Auth);

  // const backgroundImageStyle = selectedSlide?.style?.['background-image'] || '';
  // const urlMatch = backgroundImageStyle.match(/url\(["']?(.*?)["']?\)/);
  // const backgroundImageUrl = urlMatch ? urlMatch[1] : '';
  // const quotedBackgroundImageUrl = `"${backgroundImageUrl}"`;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [keywordsList, setKeywordsList] = useState([]);
  const [activityImage, setActivityImage] = useState([]);
  const [lessonImage, setLessonImage] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState([]);
  const [yearGroupsOptions, setYearGroupsOptions] = useState([]);
  const [keywordOptions, setKeywordOptions] = useState([]);

  useEffect(() => {
    getYearGroups()
      .then((res) => {
        setYearGroupsOptions(res?.tags || []);
      })
      .catch((err) => {
        console.log('🚀 ~ useEffect ~ err:', err);
      });
  }, []);

  useEffect(() => {
    getKeywords()
      .then((res) => {
        setKeywordOptions(res?.tags || []);
      })
      .catch((err) => {
        console.log('🚀 ~ useEffect ~ err:', err);
      });
  }, []);

  const handleSubmit = (values) => {
    setLoading(true);
    setError('');
    try {
      const payloadData = {
        project: project !== undefined && project !== null ? project : '',
        project_ID: projectId ? String(projectId) : '',
        lessonName: values?.title || '',
        description: values?.description || '',
        yearGroups: values?.['year-group'] ? [values['year-group']] : [],
        tags: Array.isArray(values?.keywords) ? values.keywords : [],
        ...(lessonImage && lessonImage.id ? { lessonPlan: lessonImage.id } : {}),
        ...(backgroundImage && backgroundImage.id ? { background: backgroundImage.id } : {}),
        ...(activityImage && activityImage.id ? { activityWorkbook: activityImage?.id } : {}),
        subject: values?.subject || ''
      };
  
      createPublishLessonForLifeWise(payloadData)
        .then((res) => {
          setLoading(false);
          setOpenSnackbar(true);
          setError('Congratulations! Your lesson has been sent for approval.');
  
          setTimeout(() => {
            onPublishModelClose();
            navigate('/library');
          }, 2000);
        })
        .catch((err) => {
          console.error('Failed to publish lesson:', err);
          setError('Failed to publish lesson');
          setOpenSnackbar(true);
          setLoading(false);
        });
    } catch (error) {
      console.error('Failed to publish lesson:', error);
      setError('Failed to publish lifewise lesson');
      setOpenSnackbar(true);
      setLoading(false);
    }
  };
  

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <PublishModalContainer>
      <Form
        initialValues={{
          title: '',
          'year-group': '',
          description: '',
          subject: '',
          keywords: [],
          files: null,
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('This field is required!'),
          'year-group': Yup.string().required('This field is required!'),
          'keywords': Yup.array().min(1, 'This field is required!'),
          subject: Yup.string().required('This field is required!'),
        })}
        onSubmit={async (values, form) => {
          await handleSubmit(values);
        }}
        enableReinitialize={true}
        validateOnBlur={false}
        validateOnChange={false}>
        {({ values, ...props }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            noValidate
            style={{ height: '100%' }}>
            <GridContainer container spacing={2} sx={{ ml: 0, height: '600px', marginTop: '5px' }}>
              <Grid item xs={2} display="flex" alignItems={'center'} sx={{ paddingTop: '0px !important' }}>
                <label>
                  <Typography variant="body2">Title*</Typography>
                </label>
              </Grid>
              <Grid item xs={10} sx={{ paddingTop: '0px !important' }}>
                <Form.Field.Input
                  optValue="title"
                  optLabel="title"
                  variant="outlined"
                  placeholder="Title"
                  name="title"
                  fullWidth
                  onChange={() => setError('')}
                  InputProps={{ style: { height: '55px' } }}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'center'} sx={{ paddingTop: '0px !important' }}>
                <label>
                  <Typography variant="body2">Description</Typography>
                </label>
              </Grid>
              <Grid item xs={10} sx={{ paddingTop: '0px !important' }}>
                <Form.Field.Textarea
                  minRows={3}
                  variant="outlined"
                  name="description"
                  placeholder="description"
                  fullWidth
                  onChange={() => setError('')}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'center'} sx={{ paddingTop: '0px !important' }}>
                <label>
                  <Typography variant="body2">Year group</Typography>
                </label>
              </Grid>
              <Grid item xs={10} sx={{ paddingTop: '0px !important' }}>
                <Form.Field.Select
                  options={yearGroupsOptions}
                  optValue="id"
                  optLabel="tagName"
                  variant="outlined"
                  placeholder="Select one"
                  name="year-group"
                  fullWidth
                  onChange={() => setError('')}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'center'} sx={{ paddingTop: '0px !important' }}>
                <label>
                  <Typography variant="body2">Subject*</Typography>
                </label>
              </Grid>
              <Grid item xs={10} sx={{ paddingTop: '0px !important' }}>
                <Form.Field.Select
                  options={subjectOptions}
                  optValue="subjectId"
                  optLabel="name"
                  variant="outlined"
                  placeholder="Select one"
                  name="subject"
                  fullWidth
                  onChange={() => setError('')}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'center'} sx={{ paddingTop: '0px !important' }}>
                <label>
                  <Typography variant="body2">Keywords*</Typography>
                </label>
              </Grid>
              <Grid item xs={10} sx={{ paddingTop: '0px !important' }}>
                <Form.Field.Select
                  multiple={true}
                  options={keywordOptions}
                  optValue="id"
                  optLabel="tagName"
                  variant="outlined"
                  placeholder="Keywords"
                  name="keywords"
                  fullWidth
                  freeSolo={true}
                  showNone={false}
                  onChange={(e) => {
                    console.log('🚀 ~ Publish ~ e:', e);
                    // setKeywordsList(e.map((kw) => kw.name));
                  }}
                />
                <Stack direction="row" spacing={1}>
                  {keywordsList.map((kw, index) => (
                    <CustomButton key={index} startIcon={<TagIcon />} endIcon={<CloseIcon />}>
                      {kw}
                    </CustomButton>
                  ))}
                </Stack>
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'center'} sx={{ paddingTop: '0px !important' }}>
                <label>
                  <Typography variant="body2">Background</Typography>
                </label>
              </Grid>
              <Grid item xs={10} sx={{ paddingTop: '0px !important' }}>
                <Form.Field.File
                  size="small"
                  fullWidth
                  rows={4}
                  variant="outlined"
                  name="files"
                  maxSize={100}
                  setBackgroundImage={setBackgroundImage}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'center'} sx={{ paddingTop: '0px !important' }}>
                <label>
                  <Typography variant="body2">Activity</Typography>
                </label>
              </Grid>
              <Grid item xs={10} sx={{ paddingTop: '0px !important' }}>
                <Form.Field.File
                  size="small"
                  fullWidth
                  rows={4}
                  variant="outlined"
                  name="files"
                  maxSize={100}
                  setBackgroundImage={setActivityImage}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'center'} sx={{ paddingTop: '0px !important' }}>
                <label>
                  <Typography variant="body2">Lesson Plan</Typography>
                </label>
              </Grid>
              <Grid item xs={10} sx={{ paddingTop: '0px !important' }}>
                <Form.Field.File
                  size="small"
                  fullWidth
                  rows={4}
                  variant="outlined"
                  name="files"
                  maxSize={100}
                  setBackgroundImage={setLessonImage}
                />
              </Grid>
            </GridContainer>
            <Stack
              direction="row"
              sx={{
                // position: 'absolute',
                right: 0,
                bottom: 0,
                background: palette.background.paper,
                justifyContent: 'flex-end',
                width: '100%',
              }}
              spacing={2}
              padding={'1rem'}>
              <LoadingButton variant="text" onClick={onPublishModelClose} loading={loading}>
                Cancel
              </LoadingButton>
              <LoadingButton
                variant="contained"
                color="secondary"
                type="submit"
                startIcon={<ModalPublishIcon />}
                loading={loading}>
                Publish
              </LoadingButton>
            </Stack>
          </form>
        )}
      </Form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={error}
        // action={
        //   <React.Fragment>
        //     <Button color="inherit" onClick={handleCloseSnackbar}>
        //       Close
        //     </Button>
        //   </React.Fragment>
        // }
      />
    </PublishModalContainer>
  );
};

export default PublishToLifeWise;
