import React, { forwardRef, useMemo } from 'react';
import { Card as MuiCard, CardContent, Typography, Box } from '@mui/material';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Spacer from 'components/shared/Spacer';
import { palette } from 'config/theme';
import SlidePreview from 'pages/studio/Components/SlidePreview';

const CardImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ImageBox = styled.div`
  width: 100%;
  aspect-ratio: ${({ aspectRatio }) => aspectRatio || '1/1'};
  position: relative;
  background-color: ${({ bgColor }) => bgColor || '#fff'};
  border-radius: 4px;
`;

const Tag = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  padding: 4px;
  background-color: #e2e2e2;
  color: rgba(0, 0, 0, 1);
  font-size: 10px;
  font-weight: 400;
  border-radius: 4px;
`;

const MuiCardRoot = styled(MuiCard)`
  box-shadow: none;

  .MuiCardContent-root {
    padding-bottom: 0 !important;
    ${({ padding }) => padding && `padding: ${padding} !important;`}
  }
`;

function timeAgo(dateString) {
  const now = new Date();
  const createdAt = new Date(dateString);
  const timeDifference = Math.floor((now - createdAt) / 1000);

  if (timeDifference < 60) {
    return `${timeDifference} second${timeDifference !== 1 ? 's' : ''} ago`;
  } else if (timeDifference < 3600) {
    const minutes = Math.floor(timeDifference / 60);
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  } else if (timeDifference < 86400) {
    const hours = Math.floor(timeDifference / 3600);
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else {
    const days = Math.floor(timeDifference / 86400);
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  }
}

const Card = forwardRef(
  ({ tag, image, name, ratio, bgColor, padding, isDate = false, timeValue = '', isSlide = {} }, ref) => {
    const isTimeAgo = useMemo(() => {
      let today = new Date();
      let created = new Date(timeValue);
      let diff = today.getTime() - created.getTime();
      let days = Math.floor(diff / (1000 * 60 * 60 * 24));
      if (days === 0) {
        return 'Today';
      } else if (days === 1) {
        return 'Yesterday';
      } else {
        return `${days} days ago`;
      }
    }, [timeValue]);
    return (
      <MuiCardRoot padding={padding}>
        <CardContent>
          <ImageBox aspectRatio={ratio} bgColor={bgColor}>
            {image ? <CardImg src={image} alt={name} /> : <SlidePreview slide={isSlide} />}
            {tag && <Tag>{tag}</Tag>}
          </ImageBox>
          <Spacer x={1} y={1} />
          <Box>
            <Typography variant="body3">{name}</Typography>
          </Box>
          {isDate ? (
            <Box>
              <Typography variant="small" sx={{ color: palette.text.grey3 }}>
                {isTimeAgo}
              </Typography>
            </Box>
          ) : null}
        </CardContent>
      </MuiCardRoot>
    );
  }
);

Card.propTypes = {
  tag: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  ratio: PropTypes.string,
  bgColor: PropTypes.string,
  padding: PropTypes.string,
};

Card.displayName = 'Card';

export default Card;
