import { Box, Slider } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { elementUpdated } from 'redux-layout/actions';

const LineHeightSlider = () => {
  const { selectedElement } = useSelector((state) => state.Canvas.present);
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    dispatch(elementUpdated(selectedElement, newValue, 'line-height'));
  };
  return (
    <Box sx={{ width: '208px', paddingInline: '1rem' }}>
      <Slider
        size="small"
        color="secondary"
        aria-label="line-height"
        min={1}
        max={10}
        step={0.1}
        value={selectedElement?.style['line-height'] || 1.5}
        onChange={handleChange}
      />
    </Box>
  );
};

export default LineHeightSlider;
