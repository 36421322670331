import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import ButtonGroup from './ButtonGroup';
import ParagraphOptions from './ParagraphOptions';
import { Container } from './styles';
import { Snackbar } from '@mui/material';
import { useStore } from 'Socket';
import { elementUpdated } from 'redux-layout/actions';
import { StudioContext } from 'pages/studio';
import { CustomizeIcon, FriendlyIcon, ImproveIcon, ShortenIcon, SimplifyIcon } from 'config/svgs';
import { set } from 'lodash';

const RecreateWithAI = ({ setElementType }) => {
  const dispatch = useDispatch();
  const { selectedElement } = useSelector((state) => state.Canvas.present);
  const { setContextState } = useContext(StudioContext);
  const { emit, events, setEvents } = useStore();

  const canvasTextValue = selectedElement?.content?.text?.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ') || '';

  const [previewText, setPreviewText] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customLoading, setCustomLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [text, setText] = useState(canvasTextValue);
  const [selectedButton, setSelectedButton] = useState(null);
  const [customizeText, setCustomizeText] = useState('');
  const [customizedButtonText, setCustomizedButtonText] = useState('');

  const buttonsData = useMemo(
    () => [
      { name: 'Improve it', icon: <ImproveIcon /> },
      { name: 'Simplify it', icon: <SimplifyIcon /> },
      { name: 'Shorten it', icon: <ShortenIcon /> },
      { name: 'Friendly', icon: <FriendlyIcon /> },
      { name: 'Customize it', icon: <CustomizeIcon /> },
    ],
    []
  );

  const capitalizeFirstLetter = (string) => string?.charAt(0)?.toUpperCase() + string?.slice(1);

  const matchedButton = useMemo(() => {
    if (customizedButtonText) {
      return buttonsData.find((button) => button.name === capitalizeFirstLetter(customizedButtonText));
    } else {
      return buttonsData.find((button) => button.name === capitalizeFirstLetter(selectedButton));
    }
  }, [selectedButton, customizedButtonText]);

  useEffect(() => {
    if (canvasTextValue) {
      setText(canvasTextValue);
    }
  }, [canvasTextValue]);

  useEffect(() => {
    const handleEvent = (eventType) => {
      const event = events.find((item) => item.event === eventType);
      if (event) {
        try {
          setLoading(true);
          setCustomLoading(true);
          const response = JSON.parse(event.payload);
          if (eventType === 'pipeline:execute:success') {
            setText(response?.json?.result);

            const newItem = {
              text: response?.json?.result,
              icon: matchedButton?.icon,
              name: matchedButton?.name,
            };

            setPreviewText((prev) => {
              const isDuplicate = prev.some((item) => item.text === newItem.text);
              return isDuplicate ? prev : [...prev, newItem];
            });

            setCustomizeText('');
            setEvents((prev) => prev.filter((item) => item.event !== eventType));
          } else {
            setEvents((prev) => prev.filter((item) => item.event !== 'pipeline:execute:error'));
            throw new Error('AI generation failed');
          }
        } catch (error) {
          setEvents((prev) => prev.filter((item) => item.event !== 'pipeline:execute:error'));
          setSnackbar({ open: true, message: error.message || 'Invalid response from AI', severity: 'error' });
        } finally {
          setLoading(false);
          setCustomLoading(false);
        }
      }
    };

    handleEvent('pipeline:execute:success');
    handleEvent('pipeline:execute:error');
  }, [events, matchedButton]);

  const handleInsertText = (selectedText) => {
    if (selectedText) {
      dispatch(elementUpdated(selectedElement, selectedText, 'text'));
      setContextState((prev) => ({ ...prev, regeneratedText: { id: selectedElement?.id, text: selectedText } }));
    }
  };

  const handleCloseSnackbar = useCallback(() => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  const handleRewriteDrawerClose = () => {
    setEvents((prev) =>
      prev.filter((item) => item.event !== 'pipeline:execute:success' || item.event !== 'pipeline:execute:error')
    );
    setPreviewText([]);
    setText('');
    setSelectedButton(null);
    setCustomizeText('');
    setCustomizedButtonText('');
    setElementType('');
  };
  return (
    <Container>
      <Header handleClose={handleRewriteDrawerClose} />
      <ParagraphOptions
        previewText={previewText}
        text={text}
        setText={setText}
        selectedButton={selectedButton}
        customizedButtonText={customizedButtonText}
        handleInsertText={handleInsertText}
      />
      <ButtonGroup
        emit={emit}
        text={text}
        loading={loading}
        setLoading={setLoading}
        customLoading={customLoading}
        setCustomLoading={setCustomLoading}
        setSnackbar={setSnackbar}
        selectedButton={selectedButton}
        setSelectedButton={setSelectedButton}
        customizeText={customizeText}
        setCustomizeText={setCustomizeText}
        setCustomizedButtonText={setCustomizedButtonText}
      />
      <Snackbar
        open={snackbar.open}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        severity={snackbar.severity}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Container>
  );
};

export default RecreateWithAI;
