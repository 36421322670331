import {
    Box,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField
} from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { elementUpdated } from 'redux-layout/actions';

const LinkItem = () => {
    const { slides, selectedElement, selectedSlide } = useSelector((state) => state.Canvas.present);
    const [selectedValue, setSelectedValue] = useState({
        value: '',
        details: 0,
    });
    const dispatch = useDispatch();
    const [link, setLink] = useState('');
    const [jumpSlide, setJumpSlide] = useState({
        id: '',
        name: '',
    });

    useEffect(() => {
        if ('id' in selectedElement) {
            selectedElement?.event.forEach((item) => {
                if (item.type === 'click' && item.param) {
                    if (item.call === 'link') {
                        setSelectedValue((prev) => ({
                            ...prev,
                            value: 'external_link',
                            details: item.param
                        }));
                        setLink(item.param);
                    } else {
                        setSelectedValue((prev) => ({
                            ...prev,
                            value: 'jump_to_slide',
                            details: item.param
                        }));
                        setJumpSlide(item.param);
                    }
                } else if (item.type === 'click') {
                    if (item.call === 'previousSlide') {
                        setSelectedValue((prev) => ({
                            ...prev,
                            value: 'previous_slide'
                        }));
                    } else if (item.call === 'nextSlide') {
                        setSelectedValue((prev) => ({
                            ...prev,
                            value: 'next_slide'
                        }));
                    }
                }

            });
        }
    }, [selectedElement]);


    const handleRadioChange = (event) => {
        const value = event.target.value;
        setSelectedValue((prevState) => ({
            ...prevState,
            value: value,
        }));
        if ('id' in selectedElement) {
            switch (event.target.value) {
                case 'previous_slide':
                    dispatch(elementUpdated(selectedElement, 'previousSlide', 'callBehaviour', 1));
                    dispatch(elementUpdated(selectedElement, 'click', 'typeBehaviour', 1));
                    break;
                case 'next_slide':
                    dispatch(elementUpdated(selectedElement, 'nextSlide', 'callBehaviour', 1));
                    dispatch(elementUpdated(selectedElement, 'click', 'typeBehaviour', 1));
                    break;
                case 'jump_to_slide':
                    dispatch(elementUpdated(selectedElement, 'switchslide', 'callBehaviour', 1));
                    dispatch(elementUpdated(selectedElement, 'click', 'typeBehaviour', 1));
                    // dispatch(elementUpdated(selectedElement, 'linkParam', 'paramBehaviour', 1));
                    break;
                case 'external_link':
                    dispatch(elementUpdated(selectedElement, 'link', 'callBehaviour', 4));
                    dispatch(elementUpdated(selectedElement, 'click', 'typeBehaviour', 4));
                    break;
                default:
                    break;
            }
        }

    };

    const handleLink = (value) => {
        setLink(value);
        _.debounce(() => {
            dispatch(elementUpdated(selectedElement, value, 'paramBehaviour', 4));
        }, 500)();
    };

    const handleJumpSlide = (event) => {
        const value = event.target.value;
        setJumpSlide(value);
        dispatch(elementUpdated(selectedElement, value, 'paramBehaviour', 1));
    };


    return (
        <Box sx={{ width: '488px', padding: '1rem' }}>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="external_link"
                name="radio-buttons-group"
                value={selectedValue.value} // Control the selected value
                onChange={handleRadioChange} // Add onChange handler here
            >
                {/* External Link Option */}
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={5}>
                        <FormControlLabel
                            value="external_link"
                            control={<Radio color="secondary" />}
                            label="External link"
                            color="secondary"
                        />
                    </Grid>
                    <Grid item xs={7}>
                        <TextField
                            sx={{
                                '& .MuiInputBase-input': {
                                    height: '28px',
                                    borderRadius: '8px',
                                }
                            }}
                            onChange={(e) => handleLink(e.target.value)}
                            value={link}
                            id="outlined-basic"
                            placeholder="Link"
                            fullWidth />
                    </Grid>
                </Grid>

                {/* Jump to Slide Option */}
                <Grid container spacing={2} alignItems="center" mt={2}>
                    <Grid item xs={5}>
                        <FormControlLabel
                            value="jump_to_slide"
                            control={<Radio color="secondary" />}
                            label="Jump to Slide"
                            color="secondary"
                        />
                    </Grid>
                    <Grid item xs={7}>
                        <Select
                            id="demo-simple-select"
                            value={jumpSlide}
                            fullWidth
                            sx={{ height: '40px', width: '160px', borderRadius: '8px' }}
                            displayEmpty
                            onChange={handleJumpSlide}
                            placeholder="Select Slide"
                        >
                            {slides?.filter((item) => item.id !== selectedSlide.id).map((item, index) => (
                                <MenuItem key={index + 1} value={item.id} >
                                    {/* {`Slide ${index + 1}`} */}
                                    {item.id}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>

                {/* Next Slide Option */}
                <Grid container spacing={2} alignItems="center" mt={2}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            value="next_slide"
                            control={<Radio color="secondary" />}
                            label="Next Slide"
                            color="secondary"
                        />
                    </Grid>
                </Grid>

                {/* Previous Slide Option */}
                <Grid container spacing={2} alignItems="center" mt={2}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            value="previous_slide"
                            control={<Radio color="secondary" />}
                            label="Previous Slide"
                            color="secondary"
                        />
                    </Grid>
                </Grid>
            </RadioGroup>
        </Box>
    );
};

export default LinkItem;

