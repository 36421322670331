import React, { useState } from 'react';
import { Box, Input, LinearProgress, Typography } from '@mui/material';
import Upload from 'components/shared/Upload';
import styled from '@emotion/styled';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { newMediaUpload } from 'helpers/apiCalls';
import appConfig from 'config';

const ContainerFile = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 108px;
    height: 104px;
    border: 1px dashed #595D62;
    border-radius: 8px;
    background: #E2E2E2;
    color: #595D62;
    font-size: 12px;
    font-weight: 700;
    padding: 10px;
    position: relative;
    cursor: pointer;

    &:hover .delete-icon {
        display: flex;
    }
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const DeleteIcon = styled(Box)`
    display: none;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    padding: 4px;

    &:hover {
        background: rgba(255, 255, 255, 1);
    }
`;

const FileUploader = ({ setUploadedFile }) => {
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [fileData, setFileData] = useState([]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            setUploading(true);
            uploadFiles(files);  // Update to handle multiple files
        }
    };

    const onProgress = (percent) => {
        setProgress(percent);
    };

    const uploadFiles = async (files) => {
        const formData = new FormData();
        files.forEach((file) => formData.append('files', file));  // Append each file to formData

        try {
            const response = await newMediaUpload(formData, onProgress);
            if (response) {
                setFileData((prev) => [...prev, ...response]);
                setUploadedFile((prev) => [...prev, ...response]);
            } else {
                // Handle error response
                console.error('Upload failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setUploading(false);
            setProgress(0);
        }
    };

    const removeFile = (index) => {
        const newFiles = [...fileData];
        newFiles.splice(index, 1);
        setFileData(newFiles);
    };

    return (
        <Box sx={{ padding: '1rem', width: '425px', height: '100%', overflow: 'auto' }}>
            <Box sx={{ height: '73%', display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
                {fileData.map((file, index) => (
                    <Box sx={{ padding: '10px' }} key={index}>
                        <ContainerFile>
                            <Img src={appConfig.socketURL + file?.url?.replace('/', '')} alt={file?.name} />
                            <DeleteIcon className="delete-icon" onClick={() => removeFile(index)}>
                                <DeleteOutlineIcon />
                            </DeleteIcon>
                        </ContainerFile>
                    </Box>
                ))}
                {uploading && (
                    <Box sx={{ padding: '10px' }}>
                        <ContainerFile>
                            <Typography>Uploading...</Typography>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress variant="determinate" value={progress} />
                            </Box>
                        </ContainerFile>
                    </Box>
                )}
                {!uploading && (
                    <Box>
                        <input
                            accept="image/*"
                            id="file-upload"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            multiple
                        />
                        <label htmlFor="file-upload">
                            <Box sx={{ padding: '10px' }}>
                                <Upload />
                            </Box>
                        </label>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default FileUploader;
