import appConfig from 'config';
import $ from 'jquery';
import { newID } from './studioApi';

export const bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) { return '0 Byte'; }
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' (' + sizes[i] + ')';
};

export const cookieUtils = {
    get: function (name) {
        const cookieName = encodeURIComponent(name) + '=';
        const cookieStart = document.cookie.indexOf(cookieName);
        let cookieValue = null;

        if (cookieStart > -1) {
            let cookieEnd = document.cookie.indexOf(';', cookieStart);
            if (cookieEnd === -1) {
                cookieEnd = document.cookie.length;
            }
            cookieValue = decodeURIComponent(
                document.cookie.substring(cookieStart + cookieName.length, cookieEnd)
            );
        }
        return cookieValue;
    },

    set: function (name, value, days) {
        let expires = '';
        if (days && days > 0) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toUTCString();
        }

        const cookieDomain = appConfig.cookiesDoamin || 'localhost'; // Add fallback
        document.cookie =
            encodeURIComponent(name) +
            '=' +
            encodeURIComponent(value || '') +
            expires +
            `; domain=${cookieDomain}; path=/`;
    },

    unset: function (name) {
        this.set(name, '', -1); // Set expiry to the past
    },
};

export const localStorageUtils = {
    get: function (key) {
        try {
            const value = localStorage.getItem(key);
            return value ? JSON.parse(value) : null;
        } catch (e) {
            console.error('Error getting data from localStorage', e);
            return null;
        }
    },

    set: function (key, value) {
        try {
            const valueToStore = JSON.stringify(value);
            localStorage.setItem(key, valueToStore);
        } catch (e) {
            console.error('Error setting data in localStorage', e);
        }
    },

    unset: function (key) {
        try {
            localStorage.removeItem(key);
        } catch (e) {
            console.error('Error removing data from localStorage', e);
        }
    },
};



export const getRange = (plans) => {
    let numbers = [];

    // Extract numbers from the name field and add to the numbers array
    plans.forEach(plan => {
        const [start, end] = plan.name.split(' - ').map(Number);
        numbers.push(start, end);
    });

    // Find the minimum and maximum values
    const min = Math.min(...numbers);
    const max = Math.max(...numbers);

    return `${min} - ${max}`;
};

export const handleSplitRangeAtBoundaries = (range) => {
    const startContainer = range.startContainer;
    const endContainer = range.endContainer;

    // If the start or end containers are text nodes, split them
    if (startContainer.nodeType === Node.TEXT_NODE) {
        const startOffset = range.startOffset;
        const startText = startContainer.nodeValue;

        // Split the start text node at the startOffset
        const beforeText = startText.slice(0, startOffset);
        const afterText = startText.slice(startOffset);

        if (beforeText.length) {
            const beforeNode = document.createTextNode(beforeText);
            startContainer.parentNode.insertBefore(beforeNode, startContainer);
        }

        startContainer.nodeValue = afterText;
        range.setStart(startContainer, 0);
    }

    if (endContainer.nodeType === Node.TEXT_NODE) {
        const endOffset = range.endOffset;
        const endText = endContainer.nodeValue;

        // Split the end text node at the endOffset
        const beforeText = endText.slice(0, endOffset);
        const afterText = endText.slice(endOffset);

        if (afterText.length) {
            const afterNode = document.createTextNode(afterText);
            endContainer.parentNode.insertBefore(afterNode, endContainer.nextSibling);
        }

        endContainer.nodeValue = beforeText;
        range.setEnd(endContainer, endOffset);
    }
};


export const setHeightWidth = (projectHeight, projectWidth) => {
    let el = $('#canvasFrame');
    console.log('el canvasFrame', el, el.height(), el.width());
    let hight = projectHeight || '500';
    let width = projectWidth || '1000';
    let top = (el.height() - (el.width() * 9) / 16) / 2;
    let left = (el.width() - (el.height() * 16) / 9) / 2;
    let elh = el.height();
    let elw = el.width();

    if (width > el.width()) {
        hight = (el.width() * 9) / 16;
        return {
            height: hight + 'px',
            width: elw + 'px',
            top: top + 'px',
            left: '0px',
        };
    } else if (hight > el.height()) {
        width = (el.height() * 16) / 9;
        return {
            height: elh + 'px',
            width: width + 'px',
            top: '0px',
            left: left,
        };
    } else {
        width = (el.height() * 16) / 9;
        return {
            height: elh + 'px',
            width: width + 'px',
            top: '0px',
            left: left,
        };
    }
};

export const getLength = (type) => {
    switch (type) {
        case 'Title':
            return 13;
        case 'Subheading':
            return 20;
        case 'BulletPoints':
            return 30;
        case 'BodyText':
            return 30;
        case 'Text':
            return 30;
        default:
            break;
    }
};
export const isDivide = (length, content) => {
    if (content.length <= length) {
        return -1;
    } else if (content[length] === ' ') {
        return length;
    } else {
        return isDivide(length + 1, content);
    }
};

export const formatText = (type, content) => {
    let typeLength = getLength(type);
    let result = '';
    function recursion(typeLength, updatedContent) {
        let divideLength = isDivide(typeLength, updatedContent);
        if (divideLength === -1) {
            result += updatedContent;
            return;
        } else {
            let startStr = updatedContent.slice(0, divideLength);
            let endStr = updatedContent.slice(divideLength + 1);
            result += `${startStr}<br />`;
            recursion(typeLength, endStr);
        }
    }
    recursion(typeLength, content);
    return result;
};



export const updateAiDataHandler = (aiData = [], data = [], themeName = '') => {
    let removedKeys = ['designtips', 'logic', 'navigationitems',];
    let slideKeys = ['image', 'Next', 'Previous', 'Back'];
    let updateAiData = aiData.map((slideOriginal) => {
        let slide = {};

        Object.keys(slideOriginal).forEach((item) => {
            slide[item?.toLowerCase()] = slideOriginal[item];
        });
        const pattern = /Answer|Question|Welcome/;
        if (pattern.test(slide['title']) && slide['bodytext'] && !slide['image'] && !slide['bulletpoints']) {
            console.log('slide key changes', slide);
            slide['text'] = slide['bodytext'];
            delete slide['bodytext'];
        }
        let slide_type = Object.keys(slide).filter((item) => !removedKeys?.includes(item?.toLowerCase()))?.join('-');
        let filterSlide = data.find((item) => item.attributes.description?.toLowerCase() === slide_type?.toLowerCase());
        console.log('filterSlide', filterSlide, slide_type);
        if (filterSlide) {
            let elements = filterSlide?.attributes?.slide?.elements
                .filter((element) => {
                    if (element?.aiKey === 'Next' || element?.aiKey === 'Previous') {
                        // console.log('elementKeys', element?.aiKey, slide['navigationitems'], slide['navigationitems'].map((item) => item === 'Back' ? 'Previous' : item)?.includes(element?.aiKey));
                        return slide['navigationitems']?.includes(element?.aiKey);
                    } else {
                        return true;
                    }

                })
                .map((element) => {
                    if (element?.aiKey?.toLowerCase() === 'bulletpoints') {
                        let content = `
                                    <ul>
                                        ${slide['bulletpoints'].map((item) => {
                            return `<li>${item}</li>`;
                        }).join('')}
                                    </ul>
                                    `;
                        return {
                            ...element,
                            id: newID('element'),
                            content: {
                                ...element.content,
                                text: content
                            }
                        };
                    } else if (element?.aiKey && element?.aiKey?.toLowerCase() === 'image') {
                        console.log('slide.image', slide, slide?.image, element);
                        return {
                            ...element,
                            id: newID('element'),
                            imageDescription: slide?.image?.length && slide?.image[0]?.imageDescription || '',
                            themeName: themeName
                        };
                    } else if (element?.aiKey && !slideKeys?.includes(element?.aiKey?.toLowerCase())) {
                        if (typeof slide[element.aiKey?.toLowerCase()] === 'object') {
                            let content = `
                                        <div>
                                            ${slide[element.aiKey?.toLowerCase()].map((item) => {
                                return `<div>${item}</div>`;
                            }).join('')}
                                        </div>
                                        `;
                            return {
                                ...element,
                                id: newID('element'),
                                content: {
                                    ...element.content,
                                    text: content
                                }
                            };
                        } else {
                            return {
                                ...element,
                                id: newID('element'),
                                content: {
                                    ...element.content,
                                    text: slide[element.aiKey?.toLowerCase()] || ''
                                }
                            };
                        }
                    } else {
                        return {
                            ...element,
                            id: newID('element'),
                        };
                    }
                });
            return {
                ...filterSlide?.attributes?.slide,
                id: newID('slide'),
                elements
            };
        } else {
            // return {
            //     ...data[0]?.attributes?.slide,
            //     id: newID('slide'),
            //     slide_type_ai: slide_type,
            //     elements: data[0]?.attributes?.slide?.elements?.map((element) => {
            //         return {
            //             ...element,
            //             id: newID('element'),
            //         };
            //     }),
            // };
            return null;
        }
    }).filter((slide) => slide !== null);
    return updateAiData;
};


// export const uuidv4 = () => {
//     return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
//         (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
//     );
// };
