import styled from '@emotion/styled';
import { AlignIconRight } from 'config/svgs';
import { Submenu } from 'react-contexify';
import React from 'react';


export const Container = styled.div`
    width: 100%;
    display:flex;
    flex-grow: 1;
    position: relative;
`;

export const CanvasBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    padding: 20px;
    overflow: auto;
    position: relative;
`;

export const SubmenuItem = styled(Submenu)`

`;

