import React from 'react';
import { Navigate } from 'react-router-dom';
import Auth from 'layouts/Auth';
import Login from 'pages/auth/Login';
import { renderRoutes } from './generateRoutes';
import LibraryLayout from 'layouts/LibraryLayout';
import Assets from 'pages/assets';
import Library from 'pages/library';
import Logout from 'pages/auth/Logout';
import Create from 'pages/create';
import Studio from 'pages/studio';
import StudioDuplicate from 'pages/StudioDuplicate';
import StudioLayout from 'layouts/StudioLayout';

export const routes = [
  {
    layout: Auth,
    routes: [
      {
        name: 'login',
        title: 'Login page',
        component: Login,
        exact: true,
        path: '/account/login',
        isPublic: true,
      },
      {
        name: 'logout',
        title: 'Logout page',
        component: Logout,
        exact: true,
        path: '/account/logout',
        isPublic: true,
      },
      {
        name: 'create',
        title: 'Create page',
        component: Create,
        exact: true,
        path: '/create',
      },
    ],
  },
  {
    layout: LibraryLayout,
    routes: [
      {
        name: 'home',
        title: 'Home page',
        exact: true,
        component: () => <Navigate to="/library" replace />,
        path: '/',
      },
      {
        name: 'library',
        title: 'Library page',
        exact: true,
        component: Library,
        path: '/library',
      },
      {
        name: 'assets',
        title: 'Assets page',
        exact: true,
        component: Assets,
        path: '/assets',
      },
    ],
  },
  {
    layout: StudioLayout,
    routes: [
      {
        name: 'studio',
        title: 'Studio page',
        exact: true,
        component: Studio,
        path: '/studio/:cid',
      },
      {
        name: 'studio',
        title: 'Studio page',
        exact: true,
        component: StudioDuplicate,
        path: '/studio/duplicate/:cid',
      },
    ],
  },
];

export const Routes = renderRoutes(routes);
