


export const yearGroupsOptions = [
    {
        planId: 'Nursery',
        name: 'Nursery',
    },
    {
        planId: 'Reception',
        name: 'Reception',
    },
    {
        planId: 'Year 1',
        name: 'Year 1',
    },
    {
        planId: 'Year 2',
        name: 'Year 2',
    },
    {
        planId: 'Year 3',
        name: 'Year 3',
    },
    {
        planId: 'Year 4',
        name: 'Year 4',
    },
    {
        planId: 'Year 5',
        name: 'Year 5',
    },
    {
        planId: 'Year 6',
        name: 'Year 6',
    },
    {
        planId: 'Year 7',
        name: 'Year 7',
    },
    {
        planId: 'Year 8',
        name: 'Year 8',
    },
    {
        planId: 'Year 9',
        name: 'Year 9',
    },
    {
        planId: 'Year 10',
        name: 'Year 10',
    },
    {
        planId: 'Year 11',
        name: 'Year 11',
    },
];

export const subjectOptions = [
    {
        subjectId: 'english-language',
        name: 'English - Language',
    },
    {
        subjectId: 'english-literature',
        name: 'English - Literature',
    },
    {
        subjectId: 'mathematics',
        name: 'Mathematics',
    },
    {
        subjectId: 'science-biology',
        name: 'Science - Biology',
    },
    {
        subjectId: 'science-chemistry',
        name: 'Science - Chemistry',
    },
    {
        subjectId: 'science-physics',
        name: 'Science - Physics',
    },
    {
        subjectId: 'art-and-design',
        name: 'Art and Design',
    },
    {
        subjectId: 'computing',
        name: 'Computing',
    },
    {
        subjectId: 'design-and-technology',
        name: 'Design & Technology',
    },
    {
        subjectId: 'geography',
        name: 'Geography',
    },
    {
        subjectId: 'history',
        name: 'History',
    },
    {
        subjectId: 'languages',
        name: 'Languages',
    },
    {
        subjectId: 'music',
        name: 'Music',
    },
    {
        subjectId: 'physical-education',
        name: 'Physical education',
    }
];

export const keywordOptions = [
    {
        keywordsId: 'soccer',
        name: 'Soccer',
    },
    {
        keywordsId: 'american-football',
        name: 'American Football',
    },
    {
        keywordsId: 'slots',
        name: 'Slots',
    },
    {
        keywordsId: 'blackjack',
        name: 'Blackjack',
    },
    {
        keywordsId: 'baccarat',
        name: 'Baccarat',
    },
];

export const difficultyOptions = [
    {
        planId: 'easy',
        name: 'Easy',
    },
    {
        planId: 'medium',
        name: 'Medium',
    },
    {
        planId: 'hard',
        name: 'Hard',
    }
];

export const slideOptions = [
    {
        planId: '1',
        name: '1',
    },
    {
        planId: '2',
        name: '2',
    },
    {
        planId: '3',
        name: '3',
    },
    {
        planId: '4',
        name: '4',
    },
    {
        planId: '5',
        name: '5',
    },
    {
        planId: '6',
        name: '6',
    },
    {
        planId: '7',
        name: '7',
    },
    {
        planId: '8',
        name: '8',
    },
    {
        planId: '9',
        name: '9',
    },
    {
        planId: '10',
        name: '10',
    },
    {
        planId: '11',
        name: '11',
    },
    {
        planId: '12',
        name: '12',
    },
    {
        planId: '13',
        name: '13',
    },
    {
        planId: '14',
        name: '14',
    },
    {
        planId: '15',
        name: '15',
    },
    {
        planId: '16',
        name: '16',
    },
    {
        planId: '17',
        name: '17',
    },
    {
        planId: '18',
        name: '18',
    },
    {
        planId: '19',
        name: '19',
    },
    {
        planId: '20',
        name: '20',
    },

];

export const template = [
    {
        id: 1,
        name: 'No Template',
        background: '#fff',
    },
    {
        id: 2,
        name: 'Template 2',
        background: '#968F8F'
    },
    {
        id: 3,
        name: 'Template 3',
        background: '#968F8F'
    },
    {
        id: 4,
        name: 'Template 4',
        background: '#968F8F'
    },
    {
        id: 5,
        name: 'Template 5',
        background: '#968F8F'
    }

];
