import React, { useEffect, useState } from 'react';
import Leftbar from './Leftbar';
import Topbar from './Topbar';
import { Box, Divider, Grid } from '@mui/material';
import Content from './Content';
import { useSelector } from 'react-redux';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import { fetchProjects } from 'helpers/apiCalls';

const Library = () => {
  const { user } = useSelector((state) => state.Auth);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    $or: [
      {
        username: {
          $eq: user?.user?.username,
        }
      },
      {
        lwSchoolId: {
          $eq: user?.user?.lwSchoolId
        }
      },
      {
        isLifewise: {
          $eq: true
        }
      }
    ]
  });
  const [pagination, setPagination] = useState({
    start: 0,
    limit: 15,
  });

  useEffect(() => {
    if (user) {
      setLoading(true);
      const query = { filters, ...pagination, sort: { createdAt: 'DESC' } };
      getData(query).then((response) => {
        setData((prev) => [...prev, ...response.items]);
        setCount(response?.count);
        setLoading(false);
      }).catch((error) => {
        setError(error);
        setLoading(false);
      });
    }
  }, [user, filters, pagination]);

  const getData = async (query) => {
    const response = await fetchProjects(query);
    return response;
  };

  const debounceSearch = _.debounce((value, key) => {
    setPagination({
      start: 0,
      limit: 15,
    });
    setData([]);
    setFilters((prev) => ({
      ...prev,
      $and: [
        {
          [key]: {
            $containsi: value
          }
        }
      ]
    }));
  }, 500);


  const searchItems = (value, key) => {
    debounceSearch(value, key);
  };

  const refreshData = () => {
  };
  const loadMore = () => {
    setPagination((prev) => ({
      ...prev,
      start: prev.start + prev.limit,
    }));
  };
  const searchParams = (query, key) => {
    if (!query && !key) {
      setPagination({
        start: 0,
        limit: 15,
      });
      setData([]);
      setFilters((prev) => (
        {
          ...prev,
          $or: [
            {
              username: {
                $eq: user?.user?.username,
              }
            },
            {
              lwSchoolId: {
                $eq: user?.user?.lwSchoolId
              }
            },
            {
              isLifewise: {
                $eq: true
              }
            }
          ]
        }
      ));
      return;
    }
    setPagination({
      start: 0,
      limit: 15,
    });
    setData([]);
    setFilters((prev) => ({
      ...prev,
      $or: [
        {
          [key]: {
            $eq: query
          }
        }
      ]
    }));
  };

  return (
    <Box>
      <Topbar />
      <Divider />
      <Grid container direction="row" wrap="nowrap">
        <Grid item xs={2}>
          <Leftbar searchParams={searchParams} loading={loading} />
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={10}>
          <Content
            user={user}
            projects={data}
            count={count}
            loading={loading}
            error={error}
            loadMore={loadMore}
            searchItems={searchItems}
            refreshData={refreshData}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Library;
