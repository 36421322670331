import { Box, Grid, IconButton, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { ColorCircle } from './styles';
import { palette } from 'config/theme';
import { elementUpdated, slideUpdated } from 'redux-layout/actions';
import { useDispatch, useSelector } from 'react-redux';
import { StudioContext } from 'pages/studio';

const DefaultColors = ({ defaultColors = [], title = '' }) => {
  const dispatch = useDispatch();
  const { selectedElement } = useSelector((state) => state.Canvas.present);
  const { contextState, setContextState } = useContext(StudioContext);

  const handleColorChange = (color) => {
    if (contextState?.color === 'background') {
      if (color.startsWith('linear-gradient')) {
        dispatch(slideUpdated('background-image', color));
      } else {
        dispatch(slideUpdated('background-color', color));
      }
    } else {
      dispatch(elementUpdated(selectedElement, color, 'color'));
    }
  };

  const isGradient = (color) => {
    return color.startsWith('linear-gradient');
  };

  return (
    <Box mt={4}>
      <Typography variant="body2" sx={{ color: palette?.text?.dark2 }}>
        {title}
      </Typography>
      <Grid container spacing={2} mt={1}>
        {defaultColors.map((color, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={2.4}>
            <IconButton key={index} onClick={() => handleColorChange(color)} sx={{ p: 0 }}>
              <ColorCircle
                style={{
                  background: isGradient(color) ? color : undefined,
                  backgroundColor: !isGradient(color) ? color : undefined,
                  width: 40,
                  height: 40,
                  borderRadius: '50%',
                }}
              />
            </IconButton>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DefaultColors;
