import { PROJECT_LOADING, SET_DIMENSIONS } from './constants';



export const projectLoading = (value) => async (dispatch) => {
    dispatch({
        type: PROJECT_LOADING,
        payload: { value },
    });
};

export const setHeightWidth = (height, width) => async (dispatch) => {
    dispatch({
        type: SET_DIMENSIONS,
        payload: { height, width },
    });
};
