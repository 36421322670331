import React, { useState } from 'react';
import FrameElement from './FrameElement';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import LeftNavHeader from 'pages/studio/Components/LeftNavHeader';
import LeftNavSearch from 'pages/studio/Components/LeftNavSearch';
import { FrameContainer } from './styles';
import { Typography } from '@mui/material';
import { palette } from 'config/theme';

const Frames = (props) => {
  const { elementType, setElementType } = props;
  const [buttonSearch, setButtonSearch] = useState('');
  const elementConfigs = {
    FRAMES: { api: 'frames', params: {} },
  };

  const { api, params: apiParams } = elementConfigs[elementType] || { api: '', params: {} };

  const { data, loading, error, loadMore, searchItems, count } = usePaginatedApi(api, 16, apiParams, 'manual');
  const handleBack = () => {
    setElementType('ELEMENTS');
  };
  const clearSearch = () => {
    searchItems('', 'name');
    setButtonSearch('');
  };

  const handleSearch = (value) => {
    searchItems(value, 'name');
    setButtonSearch(value);
  };

  return (
    <FrameContainer>
      <LeftNavHeader elementType={elementType} setElementType={setElementType} handleBack={handleBack} />
      <LeftNavSearch
        searchValue={buttonSearch}
        clearSearch={clearSearch}
        handleSearch={handleSearch}
        placeholder={'Search Frames'}
      />
      <FrameElement
        data={data}
        elementLoadMore={loadMore}
        elementLoading={loading}
        elementError={error}
        elementCount={count}
        dataFound={data}
        buttonSearch={buttonSearch}
        clearSearch={clearSearch}
      />
    </FrameContainer>
  );
};

export default Frames;
