import React from 'react';
import { ContentContainer } from './styles';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import useResizeHeight from 'components/hooks/useResizeHeight';
import SearchSection from 'components/App/SearchSection';
import CardContainer from './CardContainer';
import { Spacer } from 'components/shared';
import { useNavigate } from 'react-router-dom';

const Content = (props) => {
    const { projects, count, loading, error, loadMore, searchItems, refreshData, user } = props;
    const { ref, height } = useResizeHeight();
    const navigate = useNavigate();

    if (height === 0) {
        return <ContentContainer ref={ref}></ContentContainer>;
    }
    return (
        <ContentContainer ref={ref} height={`${height}px`}>
            <Box sx={{ padding: '30px' }}>
                <SearchSection searchItems={searchItems} searchKey={'title'} padding={'0 0 1.5rem'} />
                <Box>
                    {error ? (
                        <Typography variant={'h2'} color="secondary">Error 404!</Typography>
                    ) : (
                        <React.Fragment>
                            <Grid container spacing={2}>
                                {projects.map((item, index) => {
                                    return (
                                        <Grid item xs={4} key={index} md={2.4}>
                                            <CardContainer id={item.id} attributes={item.attributes} refreshData={refreshData} />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            {loading ? (
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <CircularProgress color="secondary" />
                                </Box>
                            ) : projects.length === 0 ? (
                                <Box display={'grid'} sx={{ placeItems: 'center' }}>
                                    <Spacer x={15} y={15} />
                                    <Typography variant={'h2'} align={'center'}>Create your first project</Typography>
                                    <Spacer x={2} y={2} />
                                    <Button variant="contained" color="secondary" onClick={() => navigate('/create')}>Create New</Button>
                                </Box>
                            ) : count === projects.length ? null : (
                                <Box display="flex" justifyContent={'center'} alignItems={'center'}>
                                    <Button variant={'loadMore'} onClick={loadMore}>Load More</Button>
                                </Box>
                            )}
                        </React.Fragment>
                    )}
                </Box>
            </Box>
        </ContentContainer>
    );
};

export default Content;
