import React, { useState } from 'react';
import { GridContainer, ManualContainer } from './styles';
import { Box, Grid, Snackbar, Stack, Typography } from '@mui/material';
import { Form } from 'components/shared';
import { difficultyOptions, imageThemeOptions, slideOptions, yearGroupsOptions } from './config';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { palette } from 'config/theme';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import appConfig from 'config';
import { createData } from 'helpers/api.services';
import { useSelector } from 'react-redux';
import { newID } from 'helpers/studioApi';
import * as Yup from 'yup';
import { getRange, updateAiDataHandler } from 'helpers/utils';
import { createAI } from 'helpers/apiCalls';
import usePaginatedApi from 'components/hooks/usePaginationApi';



const CreateAi = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.Auth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const { data, loading: templateLoading, error: templateError } = usePaginatedApi('template-ais', 30, {}, 'manual');
  // console.log('data::::', data);

  const mappingData = async (aiData, themeName) => {
    if (data.length > 0) {
      try {
        console.log('AI response', aiData);
        console.log('AI data from template', data);
        const getUpdatedAiData = updateAiDataHandler(aiData, data, themeName);
        if (getUpdatedAiData.length > 0) {
          console.log('updateAiData', getUpdatedAiData);
          await createProject(getUpdatedAiData);
        } else {
          console.log('error data empty', getUpdatedAiData);
          setError('server error');
          setLoading(false);
          setOpenSnackbar(true);
        }
      } catch (error) {
        console.log('error', error);
        setError('server error');
        setLoading(false);
        setOpenSnackbar(true);
      }
    } else {
      console.log('data', data);
      console.log('error data empty', error);
      setError('server error');
      setLoading(false);
      setOpenSnackbar(true);
    }
  };

  const createProject = async (slides) => {
    let newSlides =
      slides &&
      slides?.map((slide) => {
        const sldid = newID('slide');
        return {
          ...slide,
          id: sldid,
        };
      });
    let payload = {
      title: 'Created with AI',
      description: '',
      slides: newSlides,
      project: {
        ...appConfig.initailProject,
        slides: newSlides,
      },
      username: user.user.username,
      lwuser: user.user.lwId,
      lwSchoolId: user.user.lwSchoolId,
      isLifewise: false,
    };
    let projectData = await createData('projects', payload);
    navigate(`/studio/${projectData?.data?.id}`);
    setError('');
    setLoading(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = async (values) => {
    handleCloseSnackbar();
    setError('');
    setLoading(true);
    setIsCancel(false);
    let payload = {
      ...values,
      ['year_group']: getRange(values['year_group']),
    };
    let themeName = payload.theme;
    try {
      const response = await createAI(payload);
      if (isCancel) {
        return;
      } else if (response?.data?.json?.slides) {
        mappingData(response?.data?.json?.slides, themeName);
      } else {
        setError('server error');
        setLoading(false);
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.log('error', error);
      setError('server error');
      setLoading(false);
      setOpenSnackbar(true);
    }
  };

  return (
    <ManualContainer>
      {templateError ? <Typography variant="h6">{'Getting Template Error'}</Typography> : (
        <Form
          initialValues={{
            ['year_group']: '',
            ['guidelines']: '',
            ['difficulty']: '',
            ['theme']: '',
            ['slides_count']: 1,
            // isAssesement: false,
          }}
          validationSchema={Yup.object().shape({
            ...Object.assign(
              {},
              {},
              {
                ['difficulty']: Yup.string().required('This field is required!'),
              },
              {
                ['theme']: Yup.string().required('This field is required!'),
              },
              {
                ['slides_count']: Yup.string().required('This field is required!'),
              }
            ),
          })}
          onSubmit={async (values, form) => {
            handleSubmit(values);
          }}
          enableReinitialize={true}
          validateOnBlur={false}
          validateOnChange={false}>
          {({ values, ...props }) => {
            return (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  props.submitForm();
                  return false;
                }}
                noValidate
                style={{ height: '100%' }}>
                {loading ? (
                  <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height="100%">
                    <img
                      src={`${appConfig.socketURL}uploads/ai_lesson_loader_6f1830e1fb.gif?updated_at=2024-09-12T06:00:06.619Z`}
                    />
                  </Box>
                ) : (
                  <GridContainer container spacing={2}>
                    <Grid item xs={4} display="flex" alignItems={'flex-start'} sx={{ transform: 'translateY(33px)' }}>
                      <label>
                        <Typography variant="body2">Age range</Typography>
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <Form.Field.AutoComplete
                        multiple={true}
                        options={yearGroupsOptions}
                        optValue="name"
                        optLabel="name"
                        variant="outlined"
                        placeholder="Select"
                        name="year_group"
                        value={values['year_group']}
                        fullWidth
                        onChange={(e) => {
                          handleCloseSnackbar();
                          setError('');
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <label>
                        <Typography variant="body2">Lesson guidelines</Typography>
                      </label>
                      <Form.Field.Textarea
                        minRows={3}
                        variant="outlined"
                        name={'guidelines'}
                        placeholder={'(optional)'}
                        value={values['guidelines']}
                        fullWidth
                        onChange={(e) => {
                          handleCloseSnackbar();
                          setError('');
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} display="flex" alignItems={'center'}>
                      <label>
                        <Typography variant="body2">Difficulty</Typography>
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <Form.Field.Select
                        options={difficultyOptions}
                        optValue="planId"
                        optLabel="name"
                        variant="outlined"
                        placeholder="Please Select"
                        name="difficulty"
                        value={values['difficulty']}
                        fullWidth
                        onChange={(e) => {
                          handleCloseSnackbar();
                          setError('');
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} display="flex" alignItems={'center'}>
                      <label>
                        <Typography variant="body2">Image Theme</Typography>
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <Form.Field.Select
                        options={imageThemeOptions}
                        optValue="planId"
                        optLabel="name"
                        variant="outlined"
                        placeholder="Select"
                        showNone={false}
                        name="theme"
                        value={values['theme']}
                        fullWidth
                        onChange={(e) => {
                          handleCloseSnackbar();
                          setError('');
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={4} display="flex" alignItems={'center'}>
                      <label>
                        <Typography variant="body2">Number of Slides</Typography>
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <Form.Field.Select
                        options={slideOptions}
                        optValue="planId"
                        optLabel="name"
                        variant="outlined"
                        placeholder="Select"
                        name="slides_count"
                        fullWidth
                        value={values['slides_count']}
                        showNone={false}
                        onChange={(e) => {
                          handleCloseSnackbar();
                          setError('');
                        }}
                      />
                    </Grid> */}
                    {/* <Grid item xs={4} display="flex" alignItems={'center'}>
                                        <label>
                                            <Typography variant="body2">Include assesements</Typography>
                                        </label>
                                    </Grid>
                                    <Grid item xs={8} display={'flex'} justifyContent={'flex-end'}>
                                        <Form.Field.Switch
                                            name="isAssesement"
                                        />
                                    </Grid> */}
                  </GridContainer>
                )}
                <Stack
                  direction="row"
                  sx={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    background: palette.background.paper,
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                  spacing={2}
                  padding={'1rem'}>
                  <LoadingButton
                    variant="text"
                    onClick={() => {
                      setIsCancel(true);
                      setLoading(false);
                    }}>
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                    startIcon={<AutoAwesomeOutlinedIcon />}
                    disabled={templateLoading}
                    loading={loading}>
                    Create with AI
                  </LoadingButton>
                </Stack>
              </form>
            );
          }}
        </Form>
      )}
      <Snackbar open={openSnackbar} handleClose={handleCloseSnackbar} message={error} severity={'error'} />
    </ManualContainer>
  );
};

export default CreateAi;
