import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const propTypes = {
    buttonTitle: PropTypes.string,
    buttonProps: PropTypes.any,
    title: PropTypes.string,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    onConfirm: PropTypes.func,
    cancelButtonVariant: PropTypes.string,
    showCancelButton: PropTypes.bool,  // Updated to PropTypes.bool
    confirmButtonVariant: PropTypes.string,
    showConfirmButton: PropTypes.bool, // Added PropTypes for confirm button visibility
};


const ConfirmDialog = (props) => {
    const {
        title = '',
        children,
        open = false,
        onClose = () => { },
        onConfirm = () => { },
        buttonTitle = 'Confirm',
        loading = false,
        cancelButtonColor = 'primary',
        showCancelButton = true,
        showConfirmButton = true,
        confirmButtonColor = 'secondary',
        cancelButtonVariant = 'text',
        confirmButtonVariant = 'text',
    } = props;
    return (
        <Dialog
            sx={{
                '& .MuiDialog-paper': { width: '450px' },
                '& #confirm-dialog': { borderBottom: '1px solid #00000027' },
                '& .MuiDialogContent-root': { padding: '20px 24px !important' },
            }}
            open={open}
            onClose={onClose}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant="h2" component="span" color="textPrimary">
                        {title}
                    </Typography>
                    <Button iconButton onClick={onClose} sx={{ padding: 0, '&.MuiButtonBase-root ': { display: 'flex', justifyContent: 'flex-end' } }}>
                        <CloseIcon />
                    </Button>
                </Stack>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions sx={{ padding: '16px 24px' }}>
                {showCancelButton && (
                    <Button
                        disabled={loading}
                        variant={cancelButtonVariant}
                        color={cancelButtonColor}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                )}
                {showConfirmButton && <Button
                    variant={confirmButtonVariant}
                    onClick={() => {
                        onConfirm();
                    }}
                    color={confirmButtonColor}
                    loading={loading}
                    {...props.buttonProps}
                >
                    {buttonTitle}
                </Button>}
            </DialogActions>
        </Dialog>
    );
};

ConfirmDialog.propTypes = propTypes;

export default ConfirmDialog;
