import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { Box, Slider } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { CompressIcon } from 'config/svgs';
import { useSelector } from 'react-redux';

const Footer = ({ setStyle, style }) => {
    const [value, setValue] = useState(0);
    const { canvasHeight, canvasWidth } = useSelector((state) => state.Studio);
    const { selectedSlide } = useSelector((state) => state.Canvas.present);

    useEffect(() => {
        setValue(0);
    }, [selectedSlide]);


    const handleFullscreen = () => {
        let elem = document.documentElement;
        if (document.webkitIsFullScreen) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                /* IE11 */
                document.msExitFullscreen();
            }
        } else {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) {
                /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                /* IE11 */
                elem.msRequestFullscreen();
            }
        }
    };


    const handleChange = (event, newValue) => {
        if (newValue === 0) {
            setStyle((prev) => ({
                ...prev,
                height: canvasHeight,
                width: canvasWidth,
            }));
            return;
        }
        if (style.height.includes('%')) {
            setStyle((prev) => ({
                ...prev,
                height: (parseInt(canvasHeight.split('%')[0]) * (1 + (newValue / 100))) + '%',
                width: (parseInt(canvasWidth.split('%')[0]) * (1 + (newValue / 100))) + '%',
            }));
        } else if (style.height.includes('px')) {
            setStyle((prev) => ({
                ...prev,
                height: (parseInt(canvasHeight.split('px')[0]) * (1 + (newValue / 100))) + 'px',
                width: (parseInt(canvasWidth.split('px')[0]) * (1 + (newValue / 100))) + 'px',
            }));
        } else if (typeof style.height === 'number') {
            setStyle((prev) => ({
                ...prev,
                height: (canvasHeight * (1 + (newValue / 100))),
                width: (canvasWidth * (1 + (newValue / 100))),
            }));
        }
        setValue(newValue);
    };

    return (
        <Container>
            <Box display="flex" justifyContent="flex-end" alignItems="center" gap="1rem">
                {/* <Box>
                    <GridOnIcon />
                </Box> */}
                <Box width="10%">
                    <Slider size="small" color="secondary" aria-label="Zoom" value={value} onChange={handleChange} />
                </Box>
                <Box onClick={handleFullscreen}>
                    {document.webkitIsFullScreen ? <CompressIcon /> : <OpenInFullIcon color="secondary" />}

                </Box>
                <Box>
                    <HelpOutlineIcon color="secondary" />
                </Box>
            </Box>
        </Container>
    );
};

export default Footer;
