import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CircularProgress, Stack, Typography } from '@mui/material';

import { createDuplicateProject, createLessonOverride } from 'helpers/api.services';
import { getLessonById } from 'helpers/apiCalls';

function StudioDuplicate() {
  const location = useLocation();
  const navigate = useNavigate();

  const params = useParams();

  const queryParams = new URLSearchParams(location.search);
  const lessonId = queryParams.get('lessonId');

  useEffect(() => {
    if (lessonId && lessonId !== '' && params?.cid && params?.cid > 0) {
      handleDup(params?.cid, lessonId);
    }
  }, [params, lessonId]);

  const handleDup = async (projectId, lessonId) => {
    try {
      const lessonObj = await getLessonById(lessonId);

      const dupData = {
        lwLessonId: 'f1404288-801f-453f-b92c-4fef68bbac9d',
        isLifewise: true,
      };

      let newProj = await createDuplicateProject(params?.cid, dupData);
      const payload = {
        lessonId,
        data: {
          lessonName: `${lessonObj.lessonName} - Copy`,
          description: lessonObj.description,
          content: {
            articulateLink: `https://stplr.superteach.org/?prj=${newProj.id}`,
          },
        },
      };
      const override = await createLessonOverride(payload);
      navigate(`/studio/${newProj.id}`);
    } catch (error) {
      console.log('error');
      console.log(error);
    }
  };

  return (
    <>
      <Stack direction="column" gap={4} alignItems="center" justifyContent="center" mt={10}>
        <CircularProgress color="secondary" />
        <Typography color="textSecondary" variant="h1">
          Please wait while we prepare the Lesson for studio editor...
        </Typography>
      </Stack>
    </>
  );
}

export default StudioDuplicate;
