import React, { useState } from 'react';
import StickerElement from './StickerElement';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import LeftNavHeader from 'pages/studio/Components/LeftNavHeader';
import LeftNavSearch from 'pages/studio/Components/LeftNavSearch';
import { StickerContainer } from './styles';
import { Typography } from '@mui/material';
import { palette } from 'config/theme';

const Stickers = (props) => {
  const { elementType, setElementType } = props;
  const [buttonSearch, setButtonSearch] = useState('');

  const elementConfigs = {
    STICKERS: { api: 'assets', params: { contenttype: { $eq: 'image' } } },
  };

  const { api, params: apiParams } = elementConfigs[elementType] || { api: '', params: {} };
  const { data, loading, error, loadMore, searchItems, count } = usePaginatedApi(api, 16, apiParams);

  const handleBack = () => {
    setElementType('ELEMENTS');
  };

  const handleSearch = (value) => {
    searchItems(value, 'name');
    setButtonSearch(value);
  };

  const clearSearch = () => {
    setButtonSearch('');
    searchItems('', 'name');
  };

  return (
    <StickerContainer>
      <LeftNavHeader elementType={elementType} setElementType={setElementType} handleBack={handleBack} />
      <LeftNavSearch
        searchValue={buttonSearch}
        handleSearch={handleSearch}
        placeholder={'Search Stickers'}
        clearSearch={clearSearch}
      />
      <StickerElement
        data={data && data?.map((item) => item?.attributes?.asset?.data[0]?.attributes)}
        elementLoadMore={loadMore}
        elementLoading={loading}
        elementError={error}
        elementCount={count}
        dataFound={data}
        buttonSearch={buttonSearch}
        clearSearch={clearSearch}
      />
    </StickerContainer>
  );
};

export default Stickers;
