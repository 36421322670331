
const images = {
    auth: {
        loginbg: require('assets/images/auth-bg.jpg'),
        loginlogo: require('assets/images/logo.png'),
    },
    header: {
        lifewiseLogo: require('assets/images/lifewise_logo.png'),
    },
    studio: {
        questionImage: require('assets/images/question.png'),

    },
    library: {
        cardImage: require('assets/images/library_image.png'),
    },
    studio: {
        addLeft: {
            elements: require('assets/images/add_elements.png'),
            text: require('assets/images/add_text.png'),
            image: require('assets/images/add_image.png'),
            video: require('assets/images/add_video.png'),
            audio: require('assets/images/add_audio.png'),
            code: require('assets/images/add_code.png'),
            upload: require('assets/images/add_upload.png'),
            background: require('assets/images/add_background.png'),
            hidebar: require('assets/images/hidebar.svg'),
        },
        animate: {
            swipe: require('assets/images/background/wizard-question-modal-1.png'),
            bounce: require('assets/images/background/wizard-question-modal-2.png'),
            zoom: require('assets/images/background/wizard-question-modal-1.png'),
            spin: require('assets/images/background/wizard-question-modal-1.png'),
        },
        ai_image_loader: {
            image: require('assets/images/ai_image_loader.gif'),
            placeholder: require('assets/images/placeholder_image_ai.webp')
        },
        error: {
            error_popup: require('assets/images/error_image.png'),
        }
    }
};

export default images;
