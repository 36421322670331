import React, { useState } from 'react';
import { AddNewButton, Container, MoreButton } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { slideadded, slideIndexChanged, slideSelectionChanged } from 'redux-layout/actions';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import appConfig from 'config';
import { newID } from 'helpers/studioApi';
import DraggableSlide from './DraggableItem';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AddSlide from './AddSlide';
import { Modal } from 'components/shared';

const Slides = () => {
  const dispatch = useDispatch();
  const { slides, selectedSlide } = useSelector((state) => state.Canvas.present);
  const [open, setOpen] = useState(false);

  const handleAddNewSlide = () => {
    let newSlide = appConfig.slideColor;
    dispatch(slideadded([{ ...newSlide, id: newID('slide') }], -1));
  };
  const handleSlideChange = (slide) => {
    if (slide.id !== selectedSlide.id) {
      dispatch(slideSelectionChanged(slide));
    }
  };
  const handleAddSlide = () => {
    setOpen(true);
  };

  const moveSlide = (dragIndex, hoverIndex) => {
    const updatedSlides = [...slides];
    const [draggedSlide] = updatedSlides.splice(dragIndex, 1);
    updatedSlides.splice(hoverIndex, 0, draggedSlide);

    dispatch(slideIndexChanged(dragIndex, hoverIndex));
  };

  return (
    <Container>
      <DndProvider backend={HTML5Backend}>
        <Stack direction="row" spacing={2} padding="0 1.5rem">
          {slides.map((slide, index) => (
            <DraggableSlide
              key={slide.id}
              index={index}
              slide={slide}
              moveSlide={moveSlide}
              selectedSlide={selectedSlide}
              handleSlideChange={handleSlideChange}
            />
          ))}
          <AddNewButton>
            <Box onClick={handleAddNewSlide}>
              <AddIcon />
            </Box>
            <MoreButton type="new" className="more-icon" onClick={handleAddSlide}>
              <MoreHorizIcon />
            </MoreButton>
          </AddNewButton>
        </Stack>
      </DndProvider>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title={'Add Slide'}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="xl">
        <AddSlide onClose={() => setOpen(false)} setOpen={setOpen} />
      </Modal>
    </Container>
  );
};

export default Slides;
