import React, { useMemo } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { CustomBox, PreviewBox } from './styles';
import {
  CustomizeIcon,
  FriendlyIcon,
  ImproveIcon,
  RewriteOutlineInsertIcon,
  RewriteTextInsertIcon,
  ShortenIcon,
  SimplifyIcon,
} from 'config/svgs';
import { palette } from 'config/theme';

const ParagraphOptions = React.memo(({ previewText, text, customizedButtonText, handleInsertText }) => {
  return (
    <React.Fragment>
      {previewText.length ? (
        [...new Set(previewText)]
          .filter((item) => item?.text)
          .map((item, index) => (
            <Stack key={index} direction="column" alignItems="flex-end" justifyContent="space-between" gap={'16px'}>
              <CustomBox>
                <Typography variant="body2" sx={{ color: palette?.text?.grey3, display: 'flex', alignItems: 'center' }}>
                  {item.icon} {item.name}
                </Typography>
              </CustomBox>
              <PreviewBox>{item.text}</PreviewBox>
              <Button
                onClick={() => handleInsertText(item.text)}
                variant={index === previewText.length - 1 ? 'contained' : 'outlined'}
                color="secondary"
                sx={{ width: '50%' }}
                startIcon={index === previewText.length - 1 ? <RewriteTextInsertIcon /> : <RewriteOutlineInsertIcon />}>
                Insert
              </Button>
            </Stack>
          ))
      ) : (
        <PreviewBox>{text}</PreviewBox>
      )}
    </React.Fragment>
  );
});

ParagraphOptions.displayName = 'ParagraphOptions';
export default ParagraphOptions;
