import React, { useContext, useState } from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import {
  ColorLensIconButton,
  DividerStyled,
  CarouselContainer,
  ColorCircle,
  NavButtonStyles,
  SwipeButtonLeft,
  SwipeButtonRight,
} from './styles';
import { palette } from 'config/theme';
import { slideUpdated } from 'redux-layout/actions';
import { useDispatch } from 'react-redux';
import SketchColorPicker from 'pages/studio/Components/ColorPicker';
import _ from 'lodash';
import { StudioContext } from 'pages/studio';

const ColorPicker = ({ setElementType }) => {
  const dispatch = useDispatch();
  const { contextState, setContextState } = useContext(StudioContext);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState('#fff');
  const [colors, setColors] = useState(['#FAD53F', '#B6E2D3', '#EC407A', '#42A5F5', '#66BB6A', '#FFFFFF']);

  const handleColorClick = () => {
    setContextState((prev) => ({ ...prev, color: 'background' }));
    setElementType('TOOLBAR_COLORDRAWER');
    // setDisplayColorPicker(!displayColorPicker);
  };

  const handleColorChange = (color) => {
    _.debounce(() => {
      setColors((prevColors) => [color.hex, ...prevColors]);
    }, 500)();
    setSelectedColor(color.hex);
    setDisplayColorPicker(false);
  };

  const getColorChunks = () => {
    const chunkSize = 5;
    const chunks = [];
    for (let i = 0; i < colors.length; i += chunkSize) {
      chunks.push(colors.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleBackgroundColorAdd = (color) => {
    dispatch(slideUpdated('background-color', color));
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: '100%', gap: 1 }}>
      <SketchColorPicker color={selectedColor} handleColorComplete={handleColorChange}>
        <ColorLensIconButton onClick={handleColorClick}>
          <ColorLensIcon sx={{ color: palette?.background?.default }} />
        </ColorLensIconButton>
      </SketchColorPicker>
      <DividerStyled />

      <CarouselContainer>
        <Carousel
          autoPlay={false}
          navButtonsAlwaysVisible
          indicators={false}
          animation="slide"
          cycleNavigation={false}
          NextIcon={<SwipeButtonRight />}
          PrevIcon={<SwipeButtonLeft />}
          navButtonsProps={{ style: NavButtonStyles }}
          sx={{ width: '100%', height: 60 }}>
          {getColorChunks().map((chunk, idx) => (
            <Box key={idx} display="flex" sx={{ gap: 3, p: 1 }}>
              {chunk.map((color, colorIdx) => (
                <IconButton key={colorIdx} onClick={() => handleBackgroundColorAdd(color)} sx={{ p: 0 }}>
                  <ColorCircle color={color} />
                </IconButton>
              ))}
            </Box>
          ))}
        </Carousel>
      </CarouselContainer>
    </Stack>
  );
};

export default ColorPicker;
