import { Box, Typography } from '@mui/material';
import { palette } from 'config/theme';
import React from 'react';

const ContextItem = ({ icon, text, shortcut = '' }) => {
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" width="262px" height="35px" sx={{ '&:hover .shortcut-contextify': { background: 'transparent' } }}>
            <Box display="flex" gap="10px">
                {icon}
                <Typography variant="body2">{text}</Typography>
            </Box>
            {shortcut && (
                <Box className="shortcut-contextify" sx={{ background: '#E2E2E2', padding: '5px', borderRadius: '5px', }}>
                    <Typography variant="body2">{shortcut}</Typography>
                </Box>
            )}
        </Box>
    );
};

export default ContextItem;
