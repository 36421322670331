import React, { useState, useCallback, useMemo } from 'react';
import { LeftbarContainer } from './styles';
import { Divider, Typography } from '@mui/material';
import { leftbarOptions } from './config';
import Spacer from 'components/shared/Spacer';
import { CheckBox } from 'components/shared';
import { useSelector } from 'react-redux';

const Leftbar = ({ searchParams, loading }) => {
  const { user } = useSelector((state) => state.Auth);
  const [options, setOptions] = useState(leftbarOptions);

  const onChange = useCallback((checked, e) => {
    const value = e.target.name;
    console.log('🚀 ~ onChange ~ value:', value, checked);
    if (checked) {
      switch (value) {
        case 'Me':
          searchParams(user.user.username, 'username');
          break;
        case 'School':
          searchParams(user.user.lwSchoolId, 'lwSchoolId');
          break;
        case 'LifeWise':
          searchParams(true, 'isLifewise');
          break;
        default:
          break;
      }
    } else {
      searchParams();
    }
    setOptions((prev) => {
      const newOptions = { ...prev };
      Object.keys(newOptions).forEach((key) => {
        newOptions[key] = newOptions[key].map((item) => ({
          ...item,
          checked: item.value === value ? checked : false,
        }));
      });
      return newOptions;
    });
  }, [searchParams, user]);

  return (
    <LeftbarContainer>
      {Object.keys(options).map((option, index, arr) => (
        <React.Fragment key={option}>
          <Typography variant="body1">{option}</Typography>
          {options[option].map((item) => (
            <CheckBox
              key={item.id}
              label={item.name}
              value={item.checked}
              name={item.value}
              onChange={onChange}
              disabled={loading}
            />
          ))}
          {index < arr.length - 1 && (
            <>
              <Spacer x={1} y={1} />
              <Divider />
              <Spacer x={1} y={1} />
            </>
          )}
        </React.Fragment>
      ))}
    </LeftbarContainer>
  );
};

export default Leftbar;
