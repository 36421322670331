import React, { useContext, useState } from 'react';
import { Container } from './styles';
import ColorPicker from './ColorPicker';
import Header from './Header';
import DefaultColors from './DefaultColors';
import { StudioContext } from 'pages/studio';
import { gradientColors, solidColors } from './config';

const ColorDrawer = ({ elementType, setElementType }) => {
  const { contextState, setContextState } = useContext(StudioContext);
  return (
    <Container>
      <Header setElementType={setElementType} />
      <ColorPicker />
      <DefaultColors defaultColors={solidColors} title={'Default Colours'} />
      {contextState?.color === 'text' ? null : (
        <DefaultColors defaultColors={gradientColors} title={'Default Gradient'} />
      )}
    </Container>
  );
};

export default ColorDrawer;
