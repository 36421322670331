

export const leftbarOptions = {
    ['Created by']: [
        {
            id: 1,
            name: 'Me',
            checked: false,
            value: 'Me',

        },
        {
            id: 2,
            name: 'My School',
            checked: false,
            value: 'School',

        },
        {
            id: 3,
            name: 'LifeWise',
            checked: false,
            value: 'LifeWise',

        }
    ]
};

export const contentFilter = [
    {
        value: 'recent',
        label: 'Recent'
    }
];
