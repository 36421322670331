export const solidColors = [
  '#000',
  '#4B4B4B',
  '#686868',
  '#9B9B9C',
  '#D3D3D3',
  '#FF3532',
  '#FF5351',
  '#FF5FB9',
  '#C763DD',
  '#8749F7',
  '#5B16E0',
  '#0D8BA6',
  '#17B6D7',
  '#52DBE1',
  '#39ABFA',
  '#5165F7',
  '#17409E',
  '#00B560',
  '#6ED25A',
  '#B5FE76',
  '#FDDA60',
  '#FEB65B',
  '#FE894D',
  '#FFF',
];

export const gradientColors = [
  'linear-gradient(270deg, #585858 0%, #515151 100%)',
  'linear-gradient(270deg, #A97726 0%, #0F0B04 100%)',
  'linear-gradient(270deg, #3026A4 0%, #050412 100%)',
  'linear-gradient(270deg, #F7F7F7 0%, #A6A6A6 100%)',
  'linear-gradient(270deg, #FFA6EF 0%, #FEF0B0 100%)',
  'linear-gradient(270deg, #90B5F8 0%, #C1F9D8 100%)',
  'linear-gradient(270deg, #FF814B 0%, #FF3A34 100%)',
  'linear-gradient(270deg, #904AE9 0%, #FB5257 100%)',
  'linear-gradient(270deg, #EF60BF 0%, #5A64F4 100%)',
  'linear-gradient(270deg, #B35FD6 0%, #1E42A1 100%)',
  'linear-gradient(270deg, #56D0E3 0%, #8351F5 100%)',
  'linear-gradient(270deg, #1E51A5 0%, #50D3DD 100%)',
  'linear-gradient(270deg, #00AB6D 0%, #7F4EF0 100%)',
  'linear-gradient(270deg, #65CB62 0%, #108DA4 100%)',
  'linear-gradient(270deg, #EDD768 0%, #1EABC9 100%)',
  'linear-gradient(270deg, #FE8D4E 0%, #FDD45E 100%)',
  'linear-gradient(270deg, #FDD067 0%, #FF65B5 100%)',
  'linear-gradient(270deg, #F2825F 0%, #7F49E0 100%)',
];
