import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Container, ImageContainer } from './style';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { BackButton, CrossButton } from 'config/svgs';
import { IconButton } from '@mui/material';
import { StudioContext } from 'pages/studio';
import { PHOTOS } from '../config';
import { baseUrl, fetchAssets } from 'helpers/apiCalls';
import LeftNavSearch from 'pages/studio/Components/LeftNavSearch';
import _ from 'lodash';
import Loader from 'pages/studio/Components/Loader';
import PhotoNotFound from '../Photos/PhotoNotFound';
import appConfig from 'config';
import { newID } from 'helpers/studioApi';
import { elementUpdated, handleAddElement } from 'redux-layout/actions';
import { useDispatch, useSelector } from 'react-redux';

const PhotoCollection = ({ collectionType }) => {
    const { setElementType, setContextState, setImageType, imageType } = useContext(StudioContext);
    const dispatch = useDispatch();
    const { selectedElement } = useSelector((state) => state.Canvas.present);
    const [filter, setFilter] = useState({
        filters: {
            $and: [
                { type: { $eq: collectionType.value === 'user' ? 'user' : 'system' } },
                { contenttype: { $eq: collectionType.value } },
            ]
        }
    });
    const [paging, setPaging] = useState({
        start: 0,
        limit: 10,
    });
    const [data, setData] = useState({
        data: [],
        count: 0,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState('');

    useEffect(() => {
        setLoading(true);
        const query = { ...filter, ...paging };
        getData(query).then((response) => {
            setData(response);
            setLoading(false);
        }).catch((error) => {
            setError(error);
            setLoading(false);
        });
    }, [filter]);



    const handleSearch = (value) => {
        setSearch(value);
        handleSearchDebounce(value);
    };

    const handleLoadMore = () => {
        setPaging((prev) => ({
            ...prev,
            start: prev.start + prev.limit,
        }));
        const pagination = {
            ...paging,
            start: paging.start + paging.limit,
        };
        setLoading(true);
        const query = { ...filter, ...pagination };
        getData(query).then((response) => {
            setData((prev) => ({
                ...prev,
                items: [...prev.items, ...response.items],
            }));
            setLoading(false);
        }).catch((error) => {
            setError(error);
            setLoading(false);
        });
    };

    const getData = async (query) => {
        const response = await fetchAssets(query);
        return response;
    };
    const handleSearchDebounce = useCallback(_.debounce((value) => {
        setFilter((prev) => ({
            ...prev,
            filters: {
                $and: [
                    { type: { $eq: 'system' } },
                    { contenttype: { $eq: collectionType.value } },
                    { name: { $contains: value } },
                ]
            },
        }));
    }, 500), [collectionType]);

    const handleAddImage = (item) => {
        const { width, height, url: imageUrl } = item || {};
        if (imageType === 'frame' && selectedElement?.type === 'frame') {
            setImageType('');
            setElementType('');
            dispatch(elementUpdated(selectedElement, `${baseUrl}${imageUrl}`, 'zindex-src'));
        } else if (imageType === 'replace_image' && selectedElement?.type === 'img') {
            setImageType('');
            setElementType('');
            dispatch(elementUpdated(selectedElement, `${baseUrl}${imageUrl}`, 'replace-image'));
        } else {
            const defaultImg = { ...appConfig.elemImg };
            const element = {
                ...defaultImg,
                id: newID('element'),
                content: {
                    src: `${baseUrl}${imageUrl}`,
                },
                position: {
                    ...defaultImg.position,
                    height: (height / width) * 20,
                    width: 20,
                },
            };
            dispatch(handleAddElement(element));
        }

    };

    return (
        <Container>
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} padding="10px">
                <Button variant="backBtn" startIcon={<BackButton />} onClick={() => {
                    setElementType(PHOTOS);
                    setContextState((prev) => ({ ...prev, collectionType: {} }));
                }}>
                    {collectionType.name}
                </Button>
                <IconButton onClick={() => {
                    setElementType('');
                    setContextState((prev) => ({ ...prev, collectionType: {} }));
                }}>
                    <CrossButton />
                </IconButton>
            </Stack>
            {error ? (
                <Typography>Found 404 Error</Typography>
            ) : (
                <>
                    <Box padding="10px">
                        <LeftNavSearch
                            searchValue={search}
                            handleSearch={handleSearch}
                            placeholder={'Search Photos'}
                            setSearchValue={setSearch}
                        />
                    </Box>
                    {data?.count !== 0 && (
                        <ImageContainer>
                            <Grid container spacing={2}>
                                {data?.items && data.items.map((item, index) => {
                                    const url = item?.attributes?.asset?.data[0]?.attributes?.url;
                                    return (
                                        <Grid item key={index} xs={6}>
                                            <Box sx={{ cursor: 'pointer' }} onClick={() => handleAddImage(item?.attributes?.asset?.data[0]?.attributes)}>
                                                <IconButton>
                                                    <img className="img-contain" src={`${baseUrl}${url}`} alt={`${baseUrl}${url}`} />
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            {data?.count && data?.count > 0 && data?.count !== data?.items?.length && !loading && (
                                <Button variant="backBtn" onClick={handleLoadMore}>
                                    {'Load More'}
                                </Button>
                            )}
                            {loading && (
                                <Stack>
                                    <Loader />
                                </Stack>
                            )}
                        </ImageContainer>
                    )}
                    {data?.count === 0 && (
                        <PhotoNotFound title={collectionType?.name || ''} handleClear={() => {
                            if (search) {
                                setSearch('');
                            } else {
                                setElementType(PHOTOS);
                            }
                        }} />
                    )}
                </>
            )}
        </Container>
    );
};

export default PhotoCollection;
