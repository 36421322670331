import React, { useRef } from 'react';
import ResizableDraggable from '../Components/Editor/ResizableDragable';
import { MultilineEdit } from '../Components/Editor/MultiLineEdit';

const TextElement = ({
    handleMouseDown = () => { },
    el = {},
    selectedElement = {},
    handleContextMenu = () => { },
    getElemStyle = () => { },
    index,
    style = {},
    addNewStyle = {},
    lcanvasRef,
}) => {
    const textRef = useRef(null);
    if (addNewStyle?.position?.x === 0 || addNewStyle?.position?.y === 0) {
        return null;
    } else {
        return (
            <ResizableDraggable
                key={index}
                {...addNewStyle}
                id={el.id}
                onMouseDownEvent={(e) => handleMouseDown(e, el)}
                isVisible={el.id === selectedElement?.id}
                handleContextMenu={handleContextMenu}
                element={el}
                textRef={textRef}
                lcanvasRef={lcanvasRef}
            >
                {el.type === 'complex_text' && (
                    <img
                        style={{ width: '100%', height: '100%', position: 'absolute', borderRadius: '1rem', objectFit: 'cover' }}
                        src={el?.content?.image}
                        alt={el.content.text || el.content.placeholder}
                    />
                )
                }
                <MultilineEdit
                    elementObj={el}
                    value={el.content.text}
                    style={{
                        ...getElemStyle(el, index, 'indiv', false, style),
                        pointerEvents: 'auto',
                        height: '100%',
                        cursor: 'text',
                        overflow: 'visible',
                    }}
                    ref={textRef}
                    lcanvasRef={lcanvasRef}
                />
            </ResizableDraggable>
        );
    }
};

export default TextElement;
