import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const TopbarTab = styled.div`
  background-color: #f5f5f5;
  border-radius: 12px;
  padding: 4px;
`;

export const TopbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

export const LeftbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: ${({ height }) => height || 'auto'};
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.background.dark3};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.palette.background.dark2};
  }
`;

export const MoreButton = styled('div')`
  right: 10px;
  top: 10px;
  background-color: #262725;
  position: absolute;
  color: #fff;
  height: 23px;
  border-radius: 4px;
  display: none; 
  z-index: 1;
`;

export const BoxAlign = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 82%;
`;
