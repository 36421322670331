import { Box, InputAdornment, MenuItem, Select, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import { DropdownInput, TextInput } from './styles';
import { palette } from 'config/theme';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SearchIcon } from 'config/svgs';

const options = [
  {
    value: 'recent',
    label: 'Recent',
  },
];

const SearchSection = ({ searchItems, searchKey, padding }) => {
  const [searchItem, setSearchItem] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false); // State for dropdown open/close

  const handleDropdownOpen = () => {
    setDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  return (
    <Stack direction="row" justifyContent="space-between" padding={padding}>
      <TextInput
        id="outlined-basic"
        placeholder="Search"
        value={searchItem}
        onChange={(e) => {
          searchItems(e.target.value, searchKey);
          setSearchItem(e.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ color: palette.text.grey3 }}>
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                searchItems('', searchKey);
                setSearchItem('');
              }}>
              Clear
            </InputAdornment>
          ),
        }}
      />
      <Stack direction="row" justifyContent="flex-end" alignItems={'center'} spacing={2}>
        <label>Sort</label>
        <DropdownInput
          sx={{ width: '142px' }}
          id="outlined-select-currency"
          defaultValue="recent"
          onOpen={handleDropdownOpen}
          onClose={handleDropdownClose}
          IconComponent={dropdownOpen ? KeyboardArrowUpIcon : KeyboardArrowDownIcon}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </DropdownInput>
      </Stack>
    </Stack>
  );
};

export default SearchSection;
