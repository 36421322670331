import React, { useEffect, useState } from 'react';
import { ListItemIcon, ListItemSecondaryAction, Box, Typography, FormHelperText, Link, Button } from '@mui/material';

import { CustomButton, FileItem, Progress } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { newMediaUpload } from 'helpers/apiCalls';
import { deleteData } from 'helpers/api.services';
import { bytesToSize } from 'helpers/utils';
import ConfirmDialog from 'components/App/ConfirmDialog';
import { TagIcon, UpoloadedFileIcon } from 'config/svgs';
import { useSelector } from 'react-redux';

function FileUpload({ file, ...props }) {
  const { selectedSlide } = useSelector((state) => state.Canvas.present);
  const backgroundImageStyle = selectedSlide?.style?.['background-image'] || '';

  const urlMatch = backgroundImageStyle.match(/url\(["']?(.*?)["']?\)/);
  const backgroundImageUrl = urlMatch ? urlMatch[1] : '';

  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onProgress = (percent) => {
    setProgress(percent);
  };

  useEffect(() => {
    if (!file.id) {
      setIsUploading(true);
      let formData = new FormData();
      formData.append('files', file);
      console.log('formData', formData, file);
      newMediaUpload(formData, onProgress)
        .then((res) => {
          if (res.length !== 0) {
            props.onUpload(file, res);
          }

          setIsUploading(false);
        })
        .catch((err) => {
          setIsUploading(false);
        });
    }
  }, [file]);

  const toggleDeleteModal = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleDialogSubmit = async () => {
    setIsDeleting(true);
    await deleteData(`assets/${file.id}`);
    setIsDeleting(false);
    props.onDelete(file.id);
  };

  return (
    <React.Fragment>
      {/* <FileItem loading={isUploading}> */}
      {/* <ListItemIcon style={{ minWidth: '40px' }}>
          <InsertDriveFileIcon />
        </ListItemIcon> */}
      {/* <Box> */}
      {file?.url && (
        <CustomButton
          // iconButton={true}
          // edge="end"
          aria-label="Delete file"
          loading={isDeleting}
          onClick={toggleDeleteModal}
          startIcon={<UpoloadedFileIcon />}
          endIcon={<CloseIcon />}>
          {file?.originalFilename ? file?.originalFilename : file?.name || ''}
        </CustomButton>
      )}

      {/* <FormHelperText>{bytesToSize(file?.size)}</FormHelperText> */}
      {/* </Box> */}
      {/* {file.id && (
        <ListItemSecondaryAction>
          <Button
            iconButton={true}
            edge="end"
            aria-label="Delete file"
            loading={isDeleting}
            onClick={toggleDeleteModal}>
            {file?.originalFilename ? file?.originalFilename : file?.name || ''}
          </Button>
        </ListItemSecondaryAction>
      )} */}
      {/* </FileItem> */}
      {isUploading && <Progress value={progress} />}

      <ConfirmDialog
        title="Delete file"
        open={isDialogOpen}
        onClose={() => {
          toggleDeleteModal();
        }}
        showCancelButton={true}
        showConfirmButton={true}
        buttonTitle={'Delete'}
        onConfirm={handleDialogSubmit}>
        <Typography variant="body1" color="textSecondary">
          Are you sure you want to delete {file?.originalFilename ? file?.originalFilename : file.name}
        </Typography>
      </ConfirmDialog>
    </React.Fragment>
  );
}

export default FileUpload;
