import { Grid } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { Trapezium } from './styles';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'; import Elements from './Elements';
import Shapes from './Shapes';
import Buttons from './Buttons';
import Icons from './Icons';
import Stickers from './Stickers';
import GIFs from './GIFs';
import Frames from './Frames';
import Text from './Text';
import Photos from './Photos';
import Videos from './Videos';
import {
  AUDIO,
  AUDIOUPLOADS,
  BACKGROUND,
  BUTTONS,
  ELEMENTS,
  EMBED,
  FRAMES,
  GIFS,
  ICONS,
  PHOTOS,
  SHAPES,
  STICKERS,
  TEXT,
  VIDEOS,
  YOURUPLOADS,
  BACKGROUNDUPLOADS,
  UPLOADS,
  TOOLBAR_RECREATEWITHAI,
  TOOLBAR_REGENERATESLIDE,
  TOOLBAR_ANIMATION,
  PHOTO_COLLECTION,
  TOOLBAR_COLORDRAWER,
  DEVELOPER_BOX,
} from './config';
import AllUploadsAssets from './Photos/AllUploadsAssets';
import Audio from './Audio';
import AllUploadedAudio from './Audio/AllUploadedAudio';
import Embed from './Embed';
import Background from './Background';
import AllBackgroundUploadsAssets from './Background/AllBackgroundUploadsAssets';
import Uploads from './Uploads';
import RecreateWithAI from './RecreateWithAI';
import RegenerateSlide from './RegenerateSlide';
import Animate from './Animate';
import PhotoCollection from './PhotoCollection';
import { StudioContext } from '..';
import { useSelector } from 'react-redux';
import ColorDrawer from './ColorDrawer';
import Developer from './Developer';

const OpenAssets = ({ elementType, setElementType }) => {
  const { contextState: { collectionType } } = useContext(StudioContext);
  const { user } = useSelector((state) => state.Auth);
  const leftSideContent = useMemo(() => {
    switch (elementType) {
      case ELEMENTS:
        return <Elements elementType={elementType} setElementType={setElementType} />;
      case SHAPES:
        return <Shapes elementType={elementType} setElementType={setElementType} />;
      case BUTTONS:
        return <Buttons elementType={elementType} setElementType={setElementType} />;
      case ICONS:
        return <Icons elementType={elementType} setElementType={setElementType} />;
      case STICKERS:
        return <Stickers elementType={elementType} setElementType={setElementType} />;
      case GIFS:
        return <GIFs elementType={elementType} setElementType={setElementType} />;
      case FRAMES:
        return <Frames elementType={elementType} setElementType={setElementType} />;
      case TEXT:
        return <Text elementType={elementType} setElementType={setElementType} />;
      case PHOTOS:
        return <Photos elementType={elementType} setElementType={setElementType} email={user?.user?.email} />;
      case YOURUPLOADS:
        return <AllUploadsAssets elementType={elementType} setElementType={setElementType} email={user?.user?.email} />;
      case VIDEOS:
        return <Videos elementType={elementType} setElementType={setElementType} />;
      case AUDIO:
        return <Audio elementType={elementType} setElementType={setElementType} />;
      case AUDIOUPLOADS:
        return <AllUploadedAudio elementType={elementType} setElementType={setElementType} />;
      case EMBED:
        return <Embed elementType={elementType} setElementType={setElementType} />;
      case BACKGROUND:
        return <Background elementType={elementType} setElementType={setElementType} />;
      case BACKGROUNDUPLOADS:
        return <AllBackgroundUploadsAssets elementType={elementType} setElementType={setElementType} />;
      case UPLOADS:
        return <Uploads elementType={elementType} setElementType={setElementType} email={user?.user?.email} />;
      case TOOLBAR_RECREATEWITHAI:
        return <RecreateWithAI elementType={elementType} setElementType={setElementType} />;
      case TOOLBAR_COLORDRAWER:
        return <ColorDrawer elementType={elementType} setElementType={setElementType} />;
      case TOOLBAR_REGENERATESLIDE:
        return <RegenerateSlide elementType={elementType} setElementType={setElementType} />;
      case TOOLBAR_ANIMATION:
        return <Animate />;
      case PHOTO_COLLECTION:
        return <PhotoCollection collectionType={collectionType} />;
      case DEVELOPER_BOX:
        return <Developer />;
      default:
        return null;
    }
  }, [elementType]);

  if (elementType) {
    return (
      <Grid item xs={2.35} sx={{ background: '#262725', position: 'relative', zIndex: 10, height: '100%' }}>
        {leftSideContent}
        {
          elementType.includes('TOOLBAR') ? null : (
            <Trapezium onClick={() => setElementType('')}>
              {elementType === '' ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftIcon />}
            </Trapezium>
          )
        }
      </Grid>
    );
  } else {
    return null;
  }
};

export default OpenAssets;
