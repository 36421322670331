import { Box } from '@mui/material';
import React from 'react';
import { SketchPicker } from 'react-color';

const ColorPicker = ({ children, type = 'color', selectedColor = '#fff', handleColorComplete = () => {} }) => {
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  const [colorPicker, setColorPicker] = React.useState(selectedColor);

  const styles = {
    color: {
      width: '20px',
      height: '14px',
      borderRadius: '2px',
      background: colorPicker,
    },
    swatch: {
      padding: '5px',
      cursor: 'pointer',
      border: type === 'svgcolor' ? '1px solid #ccc' : '1px solid transparent',
    },
    popover: {
      position: 'absolute',
      zIndex: '1000',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  };
  const colorChangeComplete = (color) => {
    handleColorComplete(color, type);
  };
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setColorPicker(color.hex);
  };
  return (
    <Box>
      <Box sx={styles.swatch} onClick={handleClick}>
        {children}
      </Box>
      {displayColorPicker ? (
        <Box sx={styles.popover}>
          <Box sx={styles.cover} onClick={handleClose} />
          <SketchPicker
            color={colorPicker}
            onChange={handleChange}
            onChangeComplete={colorChangeComplete}
            disableAlpha={true}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default ColorPicker;
