import styled from '@emotion/styled';
import { Box } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const ElementContainer = styled.div`
  padding: 16px;
  height: calc(100vh - 100px);
  overflow-y: auto;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #191b18;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #191b18;
  }
`;

export const SwipeButtonRight = styled(ChevronRightIcon)`
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  padding: 8px var(--spacing-none, 0px);
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  background: linear-gradient(270deg, #262725 0%, rgba(38, 39, 37, 0) 100%);
  height: 100px;
  margin-top: 100px;
`;

export const SwipeButtonLeft = styled(ChevronLeftIcon)`
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  padding: 8px var(--spacing-none, 0px);
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  background: linear-gradient(270deg, rgba(38, 39, 37, 0) 0%, #262725 100%);
  height: 100px;
  margin-top: 100px;
`;

export const ElementBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.backgroundColor};
`;

export const NavButtonStyles = {
  backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)',
  borderRadius: 0,
  marginRight: '0',
  marginLeft: '0',
  height: '100%',
  marginTop: '-35px',
  width: '20px',
};
