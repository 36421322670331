import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import Card from 'components/App/LibraryCard';
import images from 'config/images';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import { createData } from 'helpers/api.services';
import { useSelector } from 'react-redux';
import { CardBox, MoreButton } from './styles';
import { Sledding } from '@mui/icons-material';

const CardContainer = ({ id, attributes, refreshData, selectedCard, onSelectCard, onFileModelClose, }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.Auth);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    navigate(`/studio/${id}`);
    onFileModelClose();
  };

  const handleCopy = async () => {
    let payload = {
      title: attributes?.title,
      project: attributes?.project,
      description: attributes?.description,
      username: attributes?.username,
    };
    await createData('projects', payload);
    refreshData();
    handleClose();
  };

  const handleCardClick = () => {
    onSelectCard(id);
  };



  return (
    <CardBox selected={selectedCard === id} onClick={handleCardClick}>
      <Card
        tag={attributes?.assetType || 'Draft'}
        image={images.library.cardImage}
        name={attributes?.title}
        date={'1 day'}
        ratio={'16/9'}
        bgColor={'#1ed4c1'}
        padding={'0px'}
        isSlide={attributes?.project?.slides?.[0]}
        isDate={true}
        timeValue={attributes?.createdAt}
      />
      <MoreButton className="more-icon" onClick={handleMenuOpen}>
        <MoreHorizIcon />
      </MoreButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon sx={{ color: 'background.paper' }} />
          </ListItemIcon>
          <ListItemText>Continue Editing</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleCopy}>
          <ListItemIcon>
            <ContentCopyIcon sx={{ color: 'background.paper' }} />
          </ListItemIcon>
          <ListItemText>Make a copy</ListItemText>
        </MenuItem>
      </Menu>
    </CardBox>
  );
};

export default CardContainer;
