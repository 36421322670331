import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleWysiwyg from './CustomEditor';
import { elementChanged, elementUpdated } from 'redux-layout/actions';
import _ from 'lodash';
import { StudioContext } from 'pages/studio';

export const MultilineEdit = React.forwardRef(({ value, style, elementObj, lcanvasRef }, ref) => {
    const [editingValue, setEditingValue] = React.useState(value);
    const dispatch = useDispatch();
    const { contextState, setContextState } = useContext(StudioContext);
    const { selectedElement } = useSelector((state) => state.Canvas.present);

    const updateElement = (value) => {
        if (value) {
            dispatch(elementUpdated(elementObj, value, 'text'));

        } else {
            let editorData = ref.current.innerHTML;
            if (editorData !== elementObj.content.text) {
                dispatch(elementUpdated(elementObj, ref.current.innerHTML, 'text'));
            }
        }
    };

    useEffect(() => {
        if (contextState?.regeneratedText?.text) {
            if (ref.current && contextState?.regeneratedText?.id === elementObj.id) {
                ref.current.innerHTML = contextState?.regeneratedText?.text;
            }
            setContextState((prev) => ({ ...prev, regeneratedText: { id: '', text: '' } }));
        }

    }, [contextState?.regeneratedText?.text]);


    useEffect(() => {
        if (ref.current) {
            ref.current.innerHTML = value;
        }
    }, [style.fontSize, style.fontFamily, style.color, style.backgroundColor, style.textAlign, style.textTransform, style.lineHeight]);

    return (
        <SimpleWysiwyg
            className={selectedElement.id === elementObj.id ? 'selected-rsw-ce' : ''}
            style={{ ...style, resize: 'none' }}
            rows={1}
            aria-label="Field name"
            value={editingValue}
            placeholder={elementObj?.content?.placeholder || ''}
            onMouseDown={(e) => dispatch(elementChanged(elementObj))}
            updateElement={updateElement}
            key={JSON.stringify(style)}
            ref={ref}
            elementObj={elementObj}
            lcanvasRef={lcanvasRef}
        />
    );
});

MultilineEdit.displayName = 'MultilineEdit';
