import { PROJECT_LOADING, SET_DIMENSIONS } from './constants';

const INIT_STATE = {
    loading: false,
    canvasHeight: 0,
    canvasWidth: 0,
};


const Studio = (state = INIT_STATE, action) => {
    switch (action.type) {
        case PROJECT_LOADING:
            console.log('action.payload.value', action.payload);
            return { ...state, loading: action.payload };
        case SET_DIMENSIONS:
            return { ...state, canvasHeight: action.payload.height, canvasWidth: action.payload.width };
        default:
            return state;
    }
};

export default Studio;

