import { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { fetchData as getData, fetchCount } from 'helpers/api.services';
import qs from 'qs';

const usePaginatedApi = (initialUrl = '', initialPageSize = 10, initialParams = {}, paginationType = 'custom') => {
    const [data, setData] = useState([]);
    const [start, setStart] = useState(0); // Start from 0 for better pagination handling
    const [limit, setLimit] = useState(initialPageSize);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState({});
    const [params, setParams] = useState(initialParams);
    const [count, setCount] = useState(0);

    const fetchData = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            let queryObject = {};
            if (paginationType === 'custom') {
                queryObject = {
                    start,
                    limit,
                    sort: 'updatedAt:DESC',
                    filters: {
                        $and: [
                            params,
                            search
                        ]
                    }
                };
            } else {
                queryObject = {
                    pagination: {
                        start,
                        limit
                    },
                    sort: 'updatedAt:DESC',
                    filters: {
                        $and: [
                            params,
                            search
                        ]
                    }
                };
            }
            const queryString = qs.stringify(queryObject, { encode: false });
            const response = await getData(`${initialUrl}?${queryString}`);
            if (start === 0 && paginationType === 'custom') {
                const count = await fetchCount(`${initialUrl.slice(0, -1)}/count?${queryString}`);
                if (count.entity) {
                    setCount(count.entity);
                } else if (count.entity === 0) {
                    setCount(0);
                }
            } else if (start === 0) {
                setCount(response.count);
            }

            const responseData = response.data || [];
            setData(prevData => [...prevData, ...responseData]);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }, [initialUrl, start, limit, search, params]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const loadMore = () => {
        setStart(prevStart => prevStart + limit);
    };

    const searchItems = useCallback(
        _.debounce((query, key) => {
            setData([]); // Clear current data
            setStart(0); // Reset to the first page
            setSearch({
                [key]: {
                    $containsi: query
                }
            });
        }, 500), // 500ms debounce delay
        []
    );

    const searchParams = useCallback(
        _.debounce((query, key) => {
            setData([]); // Clear current data
            setStart(0); // Reset to the first page
            if (key) {
                setParams({
                    [key]: {
                        $eq: query
                    }
                });
            } else {
                setParams({});
            }
        }, 500), // 500ms debounce delay
        []
    );

    const refreshData = () => {
        setData([]); // Clear current data
        setStart(0); // Reset to the first page
        fetchData();
    };

    return { data, count, loading, error, loadMore, searchItems, searchParams, refreshData };
};

export default usePaginatedApi;
