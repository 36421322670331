import styled from '@emotion/styled';


export const ImgText = styled.div`
    position: absolute;
    bottom: 10%;
    z-index: 2;
    font-size: 44px;
    color: #fff;
    transform: translateX(20px);
    `;
