import { Box, Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { palette } from 'config/theme';
import { CreateLessonModal } from 'layouts/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const CreateLesson = ({ handleCreateLessonClose, handleRegenerateLesson }) => {
  const { user } = useSelector((state) => state.Auth);

  return (
    <CreateLessonModal>
      <Grid container padding={'20px'}>
        <Typography variant={'body1'} color={palette.text.dark2}>
          Are you sure you want to regenerate the entire lesson?
        </Typography>
        <Typography variant={'body1'} color={palette.success.main}>
          You will lose all the current content and cannot undo this change.
        </Typography>
      </Grid>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ width: '100%' }}
        spacing={2}
        padding="1rem">
        <Button variant="text" onClick={handleCreateLessonClose}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={() => handleRegenerateLesson('Regenerate-lesson-using-AI')}>
          Create
        </Button>
      </Stack>
    </CreateLessonModal>
  );
};

export default CreateLesson;
