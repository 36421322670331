import React, { useState } from 'react';
import { Container, TextInput } from './styles';
import { Button } from '@mui/material';
import { Spacer } from 'components/shared';
import { useDispatch, useSelector } from 'react-redux';
import { elementUpdated } from 'redux-layout/actions';

const Developer = () => {
    const [value, setValue] = useState('');
    const dispatch = useDispatch();
    const { selectedElement } = useSelector((state) => state.Canvas.present);
    const handleSubmit = () => {
        dispatch(elementUpdated(selectedElement, value, 'ai-key'));
    };
    return (
        <Container>
            <TextInput
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder={'add field'}
                variant="outlined"
                sx={{ m: 1 }}
            />
            <Spacer y={2} />
            <Button width="100%" variant="contained" color="secondary" onClick={handleSubmit}>Add key</Button>
        </Container >
    );
};

export default Developer;
