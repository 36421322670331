import styled from '@emotion/styled';

export const StyledTextarea = styled.div`
  display: inline-block;
  width: 100%;
  textarea {
    overflow-y: hidden;
    width: 100%;
    &::placeholder {
      color: #c4c4c4; /* Correct placeholder text color */
    }
    ,
    &:focus {
      background: #fff;
    }
  }
`;
