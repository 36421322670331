import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, Popover, Snackbar, Stack, Typography } from '@mui/material';
import { Form } from 'components/shared';
import { AttachmentButton, CustomButton, GridContainer, PublishModalContainer } from 'layouts/styles';
import { ModalPublishIcon, TagIcon, UploadIcon } from 'config/svgs';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { palette } from 'config/theme';
// import { subjectOptions } from './config';
import { useSelector } from 'react-redux';
import {
  baseUrl,
  createPublishLesson,
  createPublishLessonForLifeWise,
  getKeywords,
  getLessonById,
  getSubjects,
  getYearGroups,
  updateLessonById,
} from 'helpers/apiCalls';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { updateData } from 'helpers/api.services';

const Publish = ({ onPublishModelClose }) => {
  const { project, projectId, selectedSlide, lessonId } = useSelector((state) => state.Canvas.present);

  const { user } = useSelector((state) => state.Auth);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [keywordsList, setKeywordsList] = useState([]);
  const [activityImage, setActivityImage] = useState([]);
  const [lessonImage, setLessonImage] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState([]);
  const [yearGroupsOptions, setYearGroupsOptions] = useState([]);
  const [keywordOptions, setKeywordOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [publishLifeWiseEl, setPublishLifeWiseEl] = useState(null);
  const [initialValues, setInitialValues] = useState({
    title: '',
    'year-group': '',
    description: '',
    introduction: '',
    subject: '',
    keywords: [],
    lessonThumbnail: null,
    lessonPlanImg: null,
    activityImg: null,
  });
  const [editData, setEditData] = useState({});
  const [activityImageUrl, setActivityImageUrl] = useState([{ url: '', name: '' }]);
  const [lessonImageUrl, setLessonImageUrl] = useState([{ url: '', name: '' }]);
  const [lessonThumbnailUrl, setThumbnailImageUrl] = useState([{ url: '', name: '' }]);

  const backgroundImageStyle = selectedSlide?.style?.['background-image'] || '';
  const backgroundImageUrl = (backgroundImageStyle.match(/url\(["']?(.*?)["']?\)/) || [])[1] || '';
  const quotedBackgroundImageUrl = `"${backgroundImageUrl}"`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [yearGroupsRes, keywordsRes, subjectRes] = await Promise.all([
          getYearGroups(),
          getKeywords(),
          getSubjects(),
        ]);
        setYearGroupsOptions(yearGroupsRes?.tags || []);
        setKeywordOptions(keywordsRes?.tags || []);
        setSubjectOptions(subjectRes?.tags || []);
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (lessonId) {
      getLessonById(lessonId)
        .then((lesson) => {
          setEditData(lesson);
          const { lessonName, content, tags } = lesson;
          const description = content?.data?.description || '';
          const yearGroup = tags?.find((item) => item?.tagType === 'yearGroup')?.id || '';
          const subject = tags?.find((item) => item?.tagType === 'category')?.id || '';
          const keywords = tags?.filter((item) => item?.tagType === 'topic').map((kw) => kw.id) || [];
          const activityImage = content?.data?.activityWorkbook || '';
          const lessonImage = content?.data?.lessonPlan || '';
          const lessonThumbnail = content?.data?.icon || '';
          const introduction = content?.content?.find((item) => item.type === 'introduction')?.data || '';
          console.log("🚀 ~ .then ~ content:", introduction)

          setInitialValues({
            title: lessonName,
            'year-group': yearGroup,
            subject,
            description,
            keywords,
            introduction
          });
          setThumbnailImageUrl([{ url: lessonThumbnail.replace(baseUrl, ''), name: lessonThumbnail.split('/').pop() }]);
          setActivityImageUrl([{ url: activityImage.replace(baseUrl, ''), name: activityImage.split('/').pop() }]);
          setLessonImageUrl([{ url: lessonImage.replace(baseUrl, ''), name: lessonImage.split('/').pop() }]);
        })
        .catch((err) => {
          console.error('Failed to fetch lesson details:', err);
          setError('Failed to fetch lesson details');
          setOpenSnackbar(true);
        });
    }
  }, [lessonId]);

  const handleSubmit = async (values, type) => {
    setLoading(true);
    setError('');
    const payloadData = {
      project: project || '',
      project_ID: projectId || '',
      projectObj: projectId || '',
      lessonName: values.title || '',
      description: values.description || '',
      introduction: values.introduction || '',
      yearGroups: values['year-group'] ? [values['year-group']] : [],
      tags: Array.isArray(values.keywords) ? [...values.keywords, values.subject] : [],
      ...(lessonImage.id ? { lessonPlan: lessonImage.id } : {}),
      ...(backgroundImage.id ? { background: backgroundImage.id } : {}),
      ...(activityImage.id ? { activityWorkbook: activityImage.id } : {}),
      // subject: values.subject ? [values.subject] : [],
    };

    try {
      if (type === 'Publish') {
        await createPublishLesson(payloadData);
        setSuccessMessage('Congratulations, teachers from your school may now use your created lesson.');
      } else {
        await createPublishLessonForLifeWise(payloadData);
        setSuccessMessage('Congratulations! Your lesson has been sent for approval.');
      }
      setTimeout(() => {
        onPublishModelClose();
        navigate('/library');
      }, 2000);
    } catch (error) {
      setError('Failed to publish lesson');
    } finally {
      setLoading(false);
      setOpenSnackbar(true);
    }
  };

  const handleUpdate = async (values) => {
    setLoading(true);
    setError('');
    const payload = {
      lessonName: values.title,
      tags: Array.isArray(values.keywords) ? [...values.keywords, values.subject, values['year-group']] : [],
      yearGroups: values['year-group'] ? [values['year-group']] : [],
      content: [
        {
          data: values?.introduction,
          type: 'introduction',
          content: 'MARKDOWN',
        },
      ],
      data: {
        ...editData.content.data,
        description: values.description,
        lessonPlan: lessonImage.id ? `${baseUrl}${lessonImage.url}` : `${baseUrl}${lessonImageUrl[0].url}`,
        icon: backgroundImage.id ? `${baseUrl}${backgroundImage.url}` : `${baseUrl}${lessonThumbnailUrl[0].url}`,
        activityWorkbook: activityImage.id ? `${baseUrl}${activityImage.url}` : `${baseUrl}${activityImageUrl[0].url}`,
      },
    };

    const projectPayload = {
      isLifewise: true,
    };

    try {
      await updateLessonById(lessonId, payload);
      // await updateData(`projects/${projectId}`, projectPayload);
      setSuccessMessage('Lesson updated successfully');
      setTimeout(() => {
        onPublishModelClose();
      }, 2000);
    } catch (error) {
      setError('Failed to update lesson');
    } finally {
      setLoading(false);
      setOpenSnackbar(true);
    }
  };

  const setSuccessMessage = (message) => {
    setError(message);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const handlePublishToLifeWiseOpen = (event) => setPublishLifeWiseEl(event.currentTarget);

  const handlePublishToLifeWiseClose = () => setPublishLifeWiseEl(null);

  return (
    <PublishModalContainer>
      <Form
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('This field is required!'),
          'year-group': Yup.string().required('This field is required!'),
          keywords: Yup.array().min(1, 'This field is required!'),
          subject: Yup.string().required('This field is required!'),
        })}
        // onSubmit={async (values, e) => {
        //   console.log("🚀 ~ onSubmit={ ~ e:", e, values)
        //   lessonId ? await handleUpdate(values) : await handleSubmit(values);
        // }}
        enableReinitialize={true}
        validateOnBlur={false}
        validateOnChange={false}>
        {({ values, ...props }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            noValidate
            style={{ height: '100%' }}>
            <GridContainer container spacing={2} sx={{ ml: 0, height: '600px', marginTop: '5px' }}>
              <Grid item xs={2} display="flex" alignItems={'center'} sx={{ paddingTop: '0px !important' }}>
                <label>
                  <Typography variant="body2">Title*</Typography>
                </label>
              </Grid>
              <Grid item xs={10} sx={{ paddingTop: '0px !important' }}>
                <Form.Field.Input
                  optValue="title"
                  optLabel="title"
                  variant="outlined"
                  placeholder="Title"
                  name="title"
                  fullWidth
                  onChange={() => setError('')}
                  InputProps={{ style: { height: '55px' } }}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'center'} sx={{ paddingTop: '0px !important' }}>
                <label>
                  <Typography variant="body2">Description</Typography>
                </label>
              </Grid>
              <Grid item xs={10} sx={{ paddingTop: '0px !important' }}>
                <Form.Field.Textarea
                  minRows={3}
                  variant="outlined"
                  name="description"
                  placeholder="description"
                  fullWidth
                  onChange={() => setError('')}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'center'} sx={{ paddingTop: '0px !important' }}>
                <label>
                  <Typography variant="body2">Introduction</Typography>
                </label>
              </Grid>
              <Grid item xs={10} sx={{ paddingTop: '0px !important' }}>
                <Form.Field.Textarea
                  minRows={3}
                  variant="outlined"
                  name="introduction"
                  placeholder="Inroduction"
                  fullWidth
                  onChange={() => setError('')}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'center'} sx={{ paddingTop: '0px !important' }}>
                <label>
                  <Typography variant="body2">Year group</Typography>
                </label>
              </Grid>
              <Grid item xs={10} sx={{ paddingTop: '0px !important' }}>
                <Form.Field.Select
                  options={yearGroupsOptions}
                  optValue="id"
                  optLabel="tagName"
                  variant="outlined"
                  placeholder="Select one"
                  name="year-group"
                  fullWidth
                  onChange={() => setError('')}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'center'} sx={{ paddingTop: '0px !important' }}>
                <label>
                  <Typography variant="body2">Subject*</Typography>
                </label>
              </Grid>
              <Grid item xs={10} sx={{ paddingTop: '0px !important' }}>
                <Form.Field.Select
                  options={subjectOptions}
                  optValue="id"
                  optLabel="tagName"
                  variant="outlined"
                  placeholder="Select one"
                  name="subject"
                  fullWidth
                  onChange={() => setError('')}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'center'} sx={{ paddingTop: '0px !important' }}>
                <label>
                  <Typography variant="body2">Keywords*</Typography>
                </label>
              </Grid>
              <Grid item xs={10} sx={{ paddingTop: '0px !important' }}>
                <Form.Field.Select
                  multiple={true}
                  options={keywordOptions}
                  optValue="id"
                  optLabel="tagName"
                  variant="outlined"
                  placeholder="Keywords"
                  name="keywords"
                  fullWidth
                  freeSolo={true}
                  showNone={false}
                  onChange={(e) => {
                    // console.log('🚀 ~ Publish ~ e:', e);
                    // setKeywordsList(e.map((kw) => kw.name));
                  }}
                />
                <Stack direction="row" spacing={1}>
                  {keywordsList.map((kw, index) => (
                    <CustomButton key={index} startIcon={<TagIcon />} endIcon={<CloseIcon />}>
                      {kw}
                    </CustomButton>
                  ))}
                </Stack>
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'baseline'} sx={{ paddingTop: '30px !important' }}>
                <label>
                  <Typography variant="body2">Lesson Thumbnail</Typography>
                </label>
              </Grid>
              <Grid item xs={10} sx={{ paddingTop: '0px !important' }}>
                <Form.Field.File
                  size="small"
                  fullWidth
                  rows={4}
                  variant="outlined"
                  name="files"
                  maxSize={100}
                  setBackgroundImage={setBackgroundImage}
                  multiple={false}
                  initialFile={lessonThumbnailUrl}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'baseline'} sx={{ paddingTop: '30px !important' }}>
                <label>
                  <Typography variant="body2">Activity</Typography>
                </label>
              </Grid>
              <Grid item xs={10} sx={{ paddingTop: '0px !important' }}>
                <Form.Field.File
                  size="small"
                  fullWidth
                  rows={4}
                  variant="outlined"
                  name="files"
                  maxSize={100}
                  setBackgroundImage={setActivityImage}
                  multiple={false}
                  initialFile={activityImageUrl}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'baseline'} sx={{ paddingTop: '30px !important' }}>
                <label>
                  <Typography variant="body2">Lesson Plan</Typography>
                </label>
              </Grid>
              <Grid item xs={10} sx={{ paddingTop: '0px !important' }}>
                <Form.Field.File
                  size="small"
                  fullWidth
                  rows={4}
                  variant="outlined"
                  name="files"
                  maxSize={100}
                  setBackgroundImage={setLessonImage}
                  multiple={false}
                  initialFile={lessonImageUrl}
                />
              </Grid>
            </GridContainer>
            <Stack
              direction="row"
              sx={{
                // position: 'absolute',
                right: 0,
                bottom: 0,
                background: palette.background.paper,
                justifyContent: 'flex-end',
                width: '100%',
              }}
              spacing={2}
              padding={'1rem'}>
              <LoadingButton variant="text" onClick={onPublishModelClose} loading={loading}>
                Cancel
              </LoadingButton>
              {lessonId ? (
                <>
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                    // startIcon={<ModalPublishIcon />}
                    loading={loading}
                    endIcon={
                      <Stack direction="row" onMouseEnter={handlePublishToLifeWiseOpen}>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ mx: 1, height: '24px', background: '#E2E2E2' }}
                        />
                        <KeyboardArrowDownIcon />
                      </Stack>
                    }
                    onClick={() => handleUpdate(values)}>
                    Save Details
                  </LoadingButton>
                  <Popover
                    anchorEl={publishLifeWiseEl}
                    open={Boolean(publishLifeWiseEl)}
                    onClose={handlePublishToLifeWiseClose}
                    PaperProps={{
                      sx: {
                        // padding: '5px',
                        // marginLeft: '10px',
                        borderRadius: '10px',
                        marginTop: '40px',
                        background: '#009569',
                      },
                    }}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    disableRestoreFocus>
                    <LoadingButton
                      variant="contained"
                      color="secondary"
                      // type="submit"
                      sx={{ height: '50px' }}
                      // startIcon={<ModalPublishIcon />}
                      loading={loading}
                      onClick={() => handleUpdate(values)}>
                      Save details to LifeWise
                    </LoadingButton>
                  </Popover>
                </>
              ) : (
                <>
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                    startIcon={<ModalPublishIcon />}
                    loading={loading}
                    endIcon={
                      <Stack direction="row" onMouseEnter={handlePublishToLifeWiseOpen}>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ mx: 1, height: '24px', background: '#E2E2E2' }}
                        />
                        <KeyboardArrowDownIcon />
                      </Stack>
                    }
                    onClick={() => handleSubmit(values, 'Publish')}>
                    Publish
                  </LoadingButton>
                  <Popover
                    anchorEl={publishLifeWiseEl}
                    open={Boolean(publishLifeWiseEl)}
                    onClose={handlePublishToLifeWiseClose}
                    PaperProps={{
                      sx: {
                        // padding: '5px',
                        // marginLeft: '10px',
                        borderRadius: '10px',
                        marginTop: '40px',
                        background: '#009569',
                      },
                    }}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    disableRestoreFocus>
                    <LoadingButton
                      variant="contained"
                      color="secondary"
                      // type="submit"
                      sx={{ height: '50px' }}
                      startIcon={<ModalPublishIcon />}
                      loading={loading}
                      onClick={() => handleSubmit(values, 'Publish to LifeWise')}>
                      Publish to LifeWise
                    </LoadingButton>
                  </Popover>
                </>
              )}
            </Stack>
          </form>
        )}
      </Form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={error}
        // action={
        //   <React.Fragment>
        //     <Button color="inherit" onClick={handleCloseSnackbar}>
        //       Close
        //     </Button>
        //   </React.Fragment>
        // }
      />
    </PublishModalContainer>
  );
};

export default Publish;
