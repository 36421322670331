import images from 'config/images';
import React, { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { elementChanged, slideUpdatedIndex } from 'redux-layout/actions';
import { useStore } from 'Socket';

const Image = ({ el, isSlideShow = false, getElemStyle, index, slideIndex }) => {
    const dispatch = useDispatch();
    const isImageSent = useRef(false);
    const { emit, events, } = useStore();

    const imageSource = useMemo(() => {
        if (el.aiKey && el.aiKey === 'image' && isSlideShow) {
            if (isImageSent.current) {
                let image = events.find((event) =>
                    event?.event === 'pipeline:execute:success'
                    && JSON.parse(event?.payload)?.elemId === el.id);
                console.log('image', image);
                if (image) {
                    let updatedElement = {
                        ...el,
                        content: {
                            src: JSON.parse(image.payload).imageUrl,
                        },
                        aiKey: ''
                    };
                    dispatch(slideUpdatedIndex(slideIndex, updatedElement));
                }
            } else {
                try {
                    let payload = {
                        'image': el?.imageDescription || '',
                        'theme': el?.themeName || '',
                        'elemId': el?.id,
                    };
                    emit(
                        'pipeline:execute',
                        JSON.stringify({
                            slug: 'genrate-image',
                            payload,
                        })
                    );
                    isImageSent.current = true;
                } catch (error) {
                    console.log('error', error);
                }
            }
            return {
                src: images.studio.ai_image_loader.image,
                style: {
                    background: `url(${images.studio.ai_image_loader.placeholder})`,
                    backgroundSize: 'cover',
                    objectFit: 'cover',
                },
                text: 'Loading...'
            };
        } else if (el.aiKey && el.aiKey === 'image') {
            return {
                src: images.studio.ai_image_loader.image,
                style: {
                    background: `url(${images.studio.ai_image_loader.placeholder})`,
                    backgroundSize: 'cover',
                    objectFit: 'cover',
                },
                text: 'Loading...'
            };
        } else {
            return {
                src: el.content?.src,
                style: {},
                text: ''
            };
        }
    }, [el, events]);

    return (
        <img
            alt={el.name}
            onClick={() => dispatch(elementChanged(el, 'img'))}
            id={el.id}
            style={el?.content?.src ? {
                ...getElemStyle(el, index, 'indiv', false), pointerEvents: 'none',
                ...imageSource.style
            } : {
                ...getElemStyle(el, index, 'indiv', false), pointerEvents: 'none', borderRadius: '50%',
                ...imageSource.style
            }}
            src={imageSource.src}
        />
    );
};

export default Image;
