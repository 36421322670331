import React, { useContext, useRef, useEffect, forwardRef } from 'react';
import { SetBg, ToolbarContainer } from './Styles';
import { Box, Typography } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { AiIcon, MoveIcon } from 'config/svgs';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDispatch } from 'react-redux';
import { StudioContext } from 'pages/studio';
import { handleRemoveElement } from 'redux-layout/actions';

const TextToolbar = forwardRef(
  ({ isVisible, element, handleContextMenu, handleMouseDownDrag, handleMouseDownRotate }, ref) => {
    const dispatch = useDispatch();
    const { setElementType } = useContext(StudioContext);

    const handleRegenerateTextWithAI = (event) => {
      setElementType('TOOLBAR_RECREATEWITHAI');
    };

    return (
      <ToolbarContainer isVisible={isVisible}>
        <SetBg single={true} sx={{ cursor: 'rotate' }} onMouseDown={handleMouseDownRotate} className="text-rotate">
          <AutorenewIcon />
        </SetBg>
        <SetBg single={false}>
          <Box
            display="flex"
            alignItems={'center'}
            gap="10px"
            sx={{ cursor: 'pointer' }}
            onClick={handleRegenerateTextWithAI}>
            <AiIcon />
            <Typography variant="body2">Rewrite with AI</Typography>
          </Box>
          <Box sx={{ cursor: 'pointer' }} onClick={() => dispatch(handleRemoveElement(element))}>
            <DeleteOutlineIcon />
          </Box>
          <Box sx={{ cursor: 'pointer' }} onClick={(e) => handleContextMenu(e, element)}>
            <MoreHorizIcon />
          </Box>
        </SetBg>
        <SetBg single={true} sx={{ cursor: 'move' }} onMouseDown={handleMouseDownDrag} className="text-move">
          <MoveIcon className="text-move" />
        </SetBg>
      </ToolbarContainer>
    );
  }
);

TextToolbar.displayName = 'TextToolbar';

export default TextToolbar;
