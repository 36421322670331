import { useState, useEffect, useRef } from 'react';

const useResizeHeight = () => {
    const ref = useRef(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        const updateHeight = () => {
            if (ref.current) {
                const viewportHeight = window.innerHeight;
                const contentTopOffset = ref.current.getBoundingClientRect().top;
                setHeight(viewportHeight - contentTopOffset);
            }
        };

        const observer = new ResizeObserver(() => {
            requestAnimationFrame(updateHeight); // Deferring update to the next frame
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        window.addEventListener('resize', updateHeight);

        return () => {
            observer.disconnect();
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    return { ref, height };
};

export default useResizeHeight;
